<div class="main-container">
    <div class="layout-commandbar outside-layout-control">
        <div class="layout-gutter-left"></div>
        <div class="layout-content outside-layout-control-content">
            <div class="horizontal-container">
                
                <div class="root-container">
                    <div class="inline-command-bar command-bar" role="menubar" data-command-bar="true">

                        <div role="presentation" class="action-list-control-container">

                            <ng-template ngFor let-commandPage [ngForOf]="commandsPage">

                                <div class="horizontal-flex-container" #commandContainer>

                                    <ng-template ngFor let-commandGroup [ngForOf]="commandPage.commandGroups" let-i="index">

                                        <!-- Separator -->
                                        <div class="navigation-bar-row-separator only-desktop" *ngIf="i > 0">
                                        </div>
                                        <!-- Separator -->

                                        <ng-template ngFor let-command [ngForTrackBy]="trackById"
                                            [ngForOf]="commandGroup.commands">
                                            
                                            <div class="horizontal-flex-container-item-layout only-desktop"
                                                *ngIf="!command.onlyInMoreOptions && (command.isVisible$ | async)">
                                                <div class="command-bar-button-container">
                                                    
                                                    <nts-ribbon-button 
                                                        [title]="command.tooltip$ | async"
                                                        [tabIndex]="0"
                                                        [isLoading]="(command.loading$ | async)"
                                                        [commands]="command.children"
                                                        [label]="command.displayName$ | async"
                                                        [icon]="command.iconClass$ | async"
                                                        [isHightlighted]="command.isHighlighted(null) | async"
                                                        [isDisabled]="!(command.canExecute$ | async)"
                                                        (onClicked)="executeCommand(command, $event)"></nts-ribbon-button>

                                                </div>

                                            </div>
                                        </ng-template>

                                    </ng-template>

                                    <div class="sticky-area" *ngIf="defaultCommand || (mobileMenuCommand.isVisible$ | async) || (moreOptionsCommand.isVisible$ | async)">
                                        
                                        <!-- Begin Mobile Menu -->
                                        <div class="horizontal-flex-container-item-layout extended-layout only-mobile" *ngIf="mobileMenuCommand.isVisible$ | async">

                                            <div class="command-bar-button-container">
                                                <nts-ribbon-button 
                                                #mobileMenuButton
                                                [title]="mobileMenuCommand.tooltip$ | async"
                                                [label]="mobileMenuCommand.displayName$ | async"
                                                [isLoading]="mobileMenuCommand.loading$ | async"
                                                [icon]="mobileMenuCommand.iconClass$ | async"
                                                (onClicked)="mobileMenuClick(mobileMenu, $event, mobileMenuButton)"></nts-ribbon-button>
                                            </div>

                                            <p-slideMenu backLabel="Indietro" [menuWidth]="mobileMenuViewportWidth" styleClass="mobile-menu" #mobileMenu
                                                [viewportHeight]="mobileMenuViewportHeight" [model]="mobileMenuItemList" [popup]="true" appendTo="body">
                                            </p-slideMenu>
                                        </div>
                                        <!-- End Mobile Menu -->
                                        
                                        <!-- Separator for MoreOptions -->
                                        <div class="navigation-bar-row-separator only-desktop" *ngIf="moreOptionsCommand.isVisible$ | async">
                                        </div>
                                        <!-- End Separator for MoreOptions -->

                                        <!-- Begin More -->
                                        <div class="horizontal-flex-container-item-layout extended-layout only-desktop" *ngIf="moreOptionsCommand.isVisible$ | async">

                                            <div class="command-bar-button-container">
                                                <nts-ribbon-button 
                                                #moreOptionMenuButton
                                                [title]="moreOptionsCommand.tooltip$ | async"
                                                [label]="moreOptionsCommand.displayName$ | async"
                                                [icon]="moreOptionsCommand.iconClass$ | async"
                                                [isLoading]="moreOptionsCommand.loading$ | async"
                                                (onClicked)="moreOptionClick(moreOptionsMenu, $event, moreOptionMenuButton)"></nts-ribbon-button>
                                            </div>

                                            <p-slideMenu backLabel="Indietro" [menuWidth]="250" styleClass="more-options-menu" #moreOptionsMenu
                                                [viewportHeight]="moreOptionMenuViewportHeight" [model]="moreOptionsMenuItemList" [popup]="true" appendTo="body">
                                            </p-slideMenu>
                                        </div>
                                        <!-- Fine More -->
                                        <div class="default-actions-container" *ngIf="defaultCommand && (defaultCommand.isVisible$ | async)">

                                            <nts-filled-button 
                                                [title]="defaultCommand.tooltip$ | async"
                                                [label]="defaultCommand.displayName$ | async"
                                                [icon]="defaultCommand.iconClass$ | async"
                                                [isLoading]="defaultCommand.loading$ | async"
                                                [type]="defaultCommandType"
                                                [isDisabled]="!(defaultCommand.canExecute$ | async)"
                                                (onClicked)="executeCommand(defaultCommand, $event)"></nts-filled-button>

                                        </div>

                                    </div>
                                    
                                </div>

                            </ng-template>

                        </div>
                    </div>
                </div>
                <!-- <ng-template ngFor let-commandPage [ngForOf]="sidebarCommandsPage">

                    <div class="command-bar-button-container sidebars-action-control"
                        *ngIf="moreOptionsCommand.isVisible$ | async">
                        <button type="button"
                            [class.is-highlighted]="moreOptionsCommand.isHighlighted(moreOptionsCommand) | async"
                            (click)="moreOptionClick(moreOptionsMenu, $event)"
                            class="nts-Button nts-Button--icon command-bar-button fade-out-transition"
                            [attr.title]="moreOptionsCommand.tooltip" tabindex="0" data-is-focusable="true">
                            <div class="flex-container-center">
                                <i role="presentation" aria-hidden="false" data-icon-name="More options"
                                    [ngClass]="[moreOptionsCommand.iconClass]" class="button-icon"></i>
                            </div>
                        </button>
                    </div>
                    <p-slideMenu backLabel="Indietro" styleClass="more-options-menu" #moreOptionsMenu
                        [viewportHeight]="viewportHeight" [model]="moreOptionsItemList" [popup]="true" appendTo="body">
                    </p-slideMenu>

                    <ng-template ngFor let-commandGroup [ngForOf]="commandPage.commandGroups">

                        <div *ngFor="let command of commandGroup.commands"
                            class="command-bar-button-container sidebars-action-control">
                            <button type="button" *ngIf="command.isVisible$ | async" (click)="command.execute()"
                                [disabled]="!(command.canExecute$ | async)" [attr.title]="command.tooltip"
                                tabindex="0"
                                class="nts-Button nts-Button--icon command-bar-button fade-out-transition"
                                [attr.aria-label]="command.tooltip" data-is-focusable="true">
                                <div class="flex-container-center">
                                    <i role="presentation" aria-hidden="false" data-icon-name="Filter"
                                        [ngClass]="[command.iconClass]" class="button-icon"></i>
                                </div>
                            </button>
                        </div>

                    </ng-template>

                </ng-template> -->

            </div>
        </div>
        <div class="layout-gutter-right"></div>
    </div>

</div>