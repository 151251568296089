import { NgZone } from "@angular/core";
import { NgForage } from "ngforage";
import { EnvironmentConfiguration } from '@nts/std/environments';
import { Message, MessageType } from "./tab-socket";
import { TabSocket } from "./tab-socket/tab-socket";

export type SerializabledDataType = 
    Array<SerializabledDataType> |
    ArrayBuffer |
    Boolean |
    DataView |
    Date |
    string |
    number |
    Map<SerializabledDataType, SerializabledDataType> |
    Object |
    String |
    Number |
    RegExp |
    Set<SerializabledDataType>;
export class LocalstorageHelper {

    socket: TabSocket|null = null;
    socketListener: TabSocket|null = null;

    private static instance: LocalstorageHelper;
    private tenantId: number|null = null;
    private enterpriseId: number|null = null;
    private userId: number|null = null;

    constructor(
        private readonly zone: NgZone, 
        private readonly inIframe: boolean,
        private readonly ngf: NgForage,
        private readonly env: EnvironmentConfiguration,
        private readonly mockMode = false,
    ) {
        if (inIframe) {
            this.socket = new TabSocket(zone, window.parent, 'storage');
        }
    }

    static setCurrentTenantId(tenantId: number) {
        if (this.instance != null) { 
            this.instance.tenantId = tenantId;
        }
    }

    static setCurrentEnterpriseId(enterpriseId: number) {
        if (this.instance != null) { 
            this.instance.enterpriseId = enterpriseId;
        }
    }

    static setCurrentUserId(userId: number) {
        if (this.instance != null) { 
            this.instance.userId = userId;
        }
    }

    static init(zone: NgZone, inIframe: boolean, ngf: NgForage, env: EnvironmentConfiguration, mockMode: boolean = false) {
        if (this.instance == null) {
            this.instance = new LocalstorageHelper(zone, inIframe, ngf, env, mockMode);
        }
    }

    private static async writeDomainStorage(
        domain: string, 
        store: SerializabledDataType, 
        tenantBarrier: boolean, 
        enterpriseBarrier: boolean, 
        userBarrier: boolean,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<boolean> {

        let key: string = this.getDomainStoreKey(domain);

        const tenantId = (
            overrideBarrierValues?.tenantBarrierValue != null &&
            overrideBarrierValues?.tenantBarrierValue > 0
        ) ? overrideBarrierValues?.tenantBarrierValue : this.instance?.tenantId;
        const enterpriseId = (
            overrideBarrierValues?.enterpriseBarrierValue != null &&
            overrideBarrierValues?.enterpriseBarrierValue > 0
        ) ? overrideBarrierValues?.enterpriseBarrierValue : this.instance?.enterpriseId;
        const userId = (
            overrideBarrierValues?.userBarrierValue != null &&
            overrideBarrierValues?.userBarrierValue > 0
        ) ? overrideBarrierValues?.userBarrierValue : this.instance?.userId;

        if (
            tenantBarrier && 
            tenantId && 
            tenantId > 0
        ) {
            key = `${key}_T${tenantId}`;
        }

        if (
            tenantBarrier && 
            enterpriseBarrier && 
            this.instance.env.isEnterpriseBarrierRequired && 
            enterpriseId && 
            enterpriseId > 0
        ) {
            key = `${key}_E${enterpriseId}`;
        }

        if (
            userBarrier && 
            userId && 
            userId > 0
        ) {
            key = `${key}_U${userId}`;
        }
        let result = true;
        await this.instance.ngf.setItem(key, store).catch((err) => result = false);
        return result;
    }

    private static async readDomainStorage(
        domain: string, 
        tenantBarrier: boolean, 
        enterpriseBarrier: boolean,
        userBarrier: boolean,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<SerializabledDataType> {

        let key: string = this.getDomainStoreKey(domain);

        const tenantId = (
            overrideBarrierValues?.tenantBarrierValue != null &&
            overrideBarrierValues?.tenantBarrierValue > 0
        ) ? overrideBarrierValues?.tenantBarrierValue : this.instance?.tenantId;
        
        const enterpriseId = (
            overrideBarrierValues?.enterpriseBarrierValue != null &&
            overrideBarrierValues?.enterpriseBarrierValue > 0
        ) ? overrideBarrierValues?.enterpriseBarrierValue : this.instance?.enterpriseId;
        
        const userId = (
            overrideBarrierValues?.userBarrierValue != null &&
            overrideBarrierValues?.userBarrierValue > 0 
        ) ? overrideBarrierValues?.userBarrierValue : this.instance?.userId;

        if (
            tenantBarrier && 
            tenantId && 
            tenantId > 0
        ) {
            key = `${key}_T${tenantId}`;
        }

        if (
            tenantBarrier && 
            enterpriseBarrier && 
            this.instance.env.isEnterpriseBarrierRequired && 
            enterpriseId &&
            enterpriseId > 0
        ) {
            key = `${key}_E${enterpriseId}`;
        }

        if (
            userBarrier && 
            userId && 
            userId > 0
        ) {
            key = `${key}_U${userId}`;
        }

        const store: SerializabledDataType|null = await this.instance.ngf.getItem(key);
        return store ?? {};
    }

    private static getDomainStoreKey(domain: string) {
        return `crossDomainStore::${ domain }`;
    }

    static async clearStorage(
        domain: string|null = null
    ) {
        if (this.instance?.inIframe) {
            // TODO
        } else if (domain != null) {
            // TODO
        } else  { 
            await this.instance.ngf.clear();
        }
    }

    static async setStorageItem(
        key: string, 
        value: SerializabledDataType,
        domain: string|null = null,
        tenantBarrier: boolean = true, 
        enterpriseBarrier: boolean = true,
        userBarrier: boolean = false,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<boolean> {

        if (this.instance?.inIframe) {
            if (!domain) {
                domain = window.location.hostname
            }
            const message: Message<boolean | undefined> | undefined = 
                await this.instance?.socket?.sendWithConfirmAsync<
                    {
                        key: string, value: SerializabledDataType, 
                        domain: string, 
                        tenantBarrier: boolean, 
                        enterpriseBarrier: boolean, 
                        userBarrier: boolean, 
                        overrideBarrierValues: {
                            tenantBarrierValue?: number,
                            enterpriseBarrierValue?: number,
                            userBarrierValue?: number,
                        }
                    }, boolean
                >({key, value, domain, tenantBarrier, enterpriseBarrier, userBarrier, overrideBarrierValues}, MessageType.CrossSiteStorageSet
                ).catch((err) => {
                    return new Message(false);
                });
            return message?.data ?? false;

        } else if (domain != null) {
            let store: SerializabledDataType = await this.readDomainStorage(domain, tenantBarrier, enterpriseBarrier, userBarrier, overrideBarrierValues);
            (store as any)[key] = value;
            return await this.writeDomainStorage(domain, store, tenantBarrier, enterpriseBarrier, userBarrier, overrideBarrierValues);
        } else {

            const tenantId = (
                overrideBarrierValues?.tenantBarrierValue &&
                overrideBarrierValues?.tenantBarrierValue > 0
            ) ? overrideBarrierValues?.tenantBarrierValue : this.instance?.tenantId;

            const enterpriseId = (
                overrideBarrierValues?.enterpriseBarrierValue &&
                overrideBarrierValues?.enterpriseBarrierValue > 0
            ) ? overrideBarrierValues?.enterpriseBarrierValue : this.instance?.enterpriseId;
            
            const userId = (
                overrideBarrierValues?.userBarrierValue &&
                overrideBarrierValues?.userBarrierValue > 0
            ) ? overrideBarrierValues?.userBarrierValue : this.instance?.userId;

            if (
                tenantBarrier && 
                tenantId &&
                tenantId > 0
            ) {
                let result = true;

                let calculatedKey = `${key}_T${tenantId}`;

                if (
                    enterpriseBarrier && 
                    this.instance?.env.isEnterpriseBarrierRequired && 
                    enterpriseId &&
                    enterpriseId > 0
                ) {
                    calculatedKey = `${calculatedKey}_E${enterpriseId}`;
                }

                if (
                    userBarrier && 
                    userId &&
                    userId > 0
                ) {
                    calculatedKey = `${calculatedKey}_U${userId}`;
                }
                
                if (this.instance.mockMode) {
                    window.localStorage.setItem(calculatedKey, JSON.stringify(value));
                } else {
                    await this.instance.ngf.setItem(calculatedKey, value).catch((err) => result = false);
                }

                return result;
            } else {
                let result = true;

                if (
                    userBarrier && 
                    userId &&
                    userId > 0
                ) {
                    key = `${key}_U${userId}`;
                }

                if (this.instance.mockMode) {
                    window.localStorage.setItem(key, JSON.stringify(value));
                } else {
                    await this.instance.ngf.setItem(key, value).catch((err) => result = false);
                }                  
                return result;
            }            
        }
    }

    static async getStorageItem(
        key: string, 
        domain: string|null = null, 
        tenantBarrier: boolean = true,
        enterpriseBarrier: boolean = true,
        userBarrier: boolean = false,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<SerializabledDataType|null> {
        if (this.instance?.inIframe) {
            if (!domain) {
                domain = window.location.hostname
            }
            const message: Message<SerializabledDataType | null>|undefined = await this.instance?.socket?.sendWithConfirmAsync<{key: string, domain: string, tenantBarrier: boolean, enterpriseBarrier: boolean, userBarrier: boolean, overrideBarrierValues: {
                tenantBarrierValue?: number,
                enterpriseBarrierValue?: number,
                userBarrierValue?: number,
            }}, SerializabledDataType>(
                {key, domain, tenantBarrier, enterpriseBarrier, userBarrier, overrideBarrierValues}, MessageType.CrossSiteStorageGet
            ).catch((err) => {
                return new Message(null);
            });
            return message?.data ?? false;
        } else if (domain != null) {
            let store: SerializabledDataType = await this.readDomainStorage(domain, tenantBarrier, enterpriseBarrier, userBarrier, overrideBarrierValues);
            return (store as any)[key] ?? null;
        } else {

            const tenantId = (
                overrideBarrierValues?.tenantBarrierValue &&
                overrideBarrierValues?.tenantBarrierValue > 0
            ) ? overrideBarrierValues?.tenantBarrierValue : this.instance?.tenantId;
            
            const enterpriseId = (
                overrideBarrierValues?.enterpriseBarrierValue &&
                overrideBarrierValues?.enterpriseBarrierValue > 0
            ) ? overrideBarrierValues?.enterpriseBarrierValue : this.instance?.enterpriseId;
            
            const userId = (
                overrideBarrierValues?.userBarrierValue &&
                overrideBarrierValues?.userBarrierValue > 0
            ) ? overrideBarrierValues?.userBarrierValue : this.instance?.userId;

            if (
                tenantBarrier && 
                tenantId &&
                tenantId > 0
            ) {
                let calculatedKey = `${key}_T${tenantId}`;

                if (
                    enterpriseBarrier && 
                    this.instance?.env.isEnterpriseBarrierRequired && 
                    enterpriseId &&
                    enterpriseId > 0
                ) {
                    calculatedKey = `${calculatedKey}_E${enterpriseId}`;
                }

                if (
                    userBarrier && 
                    userId &&
                    userId > 0
                ) {
                    calculatedKey = `${calculatedKey}_U${userId}`;
                }

                if (this.instance.mockMode) {
                    const data: string|null = window.localStorage.getItem(calculatedKey);
                    return data ? JSON.parse(data) : null;
                } else {
                    return await this.instance?.ngf?.getItem(calculatedKey);
                }                
            } else {

                if (
                    userBarrier && 
                    userId && 
                    userId > 0
                ) {
                    key = `${key}_U${userId}`;
                }

                if (this.instance.mockMode) {
                    const data = window.localStorage.getItem(key);
                    return data ? JSON.parse(data) : null;
                } else {
                    return await this.instance.ngf.getItem(key);
                }
            }
            
        }
    }

    static async removeStorageItem(
        key: string, 
        domain: string|null = null, 
        tenantBarrier: boolean = true,
        enterpriseBarrier: boolean = true,
        userBarrier: boolean = false,
        overrideBarrierValues: {
            tenantBarrierValue?: number,
            enterpriseBarrierValue?: number,
            userBarrierValue?: number,
        } = {
            tenantBarrierValue: undefined,
            enterpriseBarrierValue: undefined,
            userBarrierValue: undefined
        }
    ): Promise<boolean> {
        if (this.instance?.inIframe) {
            if (!domain) {
                domain = window.location.hostname
            }

            const message: Message<boolean|null>|undefined = await this.instance?.socket?.sendWithConfirmAsync<{key: string, domain: string, tenantBarrier: boolean, enterpriseBarrier: boolean, userBarrier: boolean, overrideBarrierValues: {
                tenantBarrierValue?: number,
                enterpriseBarrierValue?: number,
                userBarrierValue?: number,
            }}, boolean>(
                {key, domain, tenantBarrier, enterpriseBarrier, userBarrier, overrideBarrierValues}, MessageType.CrossSiteStorageRemove
            ).catch((err) => {
                return new Message(false);
            });
            return message?.data ?? false;
        
        } else if (domain != null) {
            let store: SerializabledDataType = await this.readDomainStorage(domain, tenantBarrier, enterpriseBarrier, userBarrier, overrideBarrierValues);
            if ((store as any)[key] ) {
                delete (store as any)[key];
            }            
            return await this.writeDomainStorage(domain, store, tenantBarrier, enterpriseBarrier, userBarrier, overrideBarrierValues);
        } else {

            const tenantId = (
                overrideBarrierValues?.tenantBarrierValue &&
                overrideBarrierValues?.tenantBarrierValue > 0
            ) ? overrideBarrierValues?.tenantBarrierValue : this.instance?.tenantId;
            
            const enterpriseId = (
                overrideBarrierValues?.enterpriseBarrierValue &&
                overrideBarrierValues?.enterpriseBarrierValue > 0
            ) ? overrideBarrierValues?.enterpriseBarrierValue : this.instance?.enterpriseId;
            
            const userId = (
                overrideBarrierValues?.userBarrierValue &&
                overrideBarrierValues?.userBarrierValue > 0
            ) ? overrideBarrierValues?.userBarrierValue : this.instance?.userId;

            if (
                tenantBarrier && 
                tenantId && 
                tenantId > 0
            ) {
                let calculatedKey = `${key}_T${tenantId}`;

                if (
                    enterpriseBarrier && 
                    this.instance?.env.isEnterpriseBarrierRequired && 
                    enterpriseId &&
                    enterpriseId > 0
                ) {
                    calculatedKey = `${calculatedKey}_E${enterpriseId}`;
                }

                if (
                    userBarrier && 
                    userId &&
                    userId > 0
                ) {
                    calculatedKey = `${calculatedKey}_U${userId}`;
                }

                let result = true;

                if (this.instance.mockMode) {
                    window.localStorage.removeItem(calculatedKey);
                } else {
                    await this.instance.ngf.removeItem(calculatedKey).catch((err) => result = false)
                }
                
                return result;
            } else {
                let result = true;

                if (
                    userBarrier && 
                    userId &&
                    userId > 0
                ) {
                    key = `${key}_U${userId}`;
                }
                
                if (this.instance.mockMode) {
                    window.localStorage.removeItem(key);
                } else {
                    await this.instance.ngf.removeItem(key).catch((err) => result = false)
                }
                return result;
            }            
        }
    }

    // avvia listener per iframe interni
    static startListener(targetWindow: Window) {
        
        // TODO errore se prima non si ha fatto l'init

        if (this.instance.socketListener) {
            this.instance.socketListener.destroy();
        }

        this.instance.socketListener = new TabSocket(this.instance.zone, targetWindow, 'storage');
        this.instance.socketListener.onClose.pipe().subscribe(() => {
            this.instance?.socketListener?.destroy();
        });

        this.instance.socketListener.listen<any>(async (message) => {
            const commandMessageAction = (data: any, messageType = message.type) => {
                this.instance?.socketListener?.send(data, messageType, message.uuid);
            };
      
            switch (message.type) {
                case MessageType.CrossSiteStorageGet:
                    const crossSiteStorageGetRequest = message.data as {
                        key: string, 
                        domain: string,
                        tenantBarrier: boolean, 
                        enterpriseBarrier: boolean, 
                        userBarrier: boolean,
                        overrideBarrierValues: {
                            tenantBarrierValue?: number,
                            enterpriseBarrierValue?: number,
                            userBarrierValue?: number,
                        }
                    };
                    const getResult = await this.getStorageItem(
                        crossSiteStorageGetRequest.key, 
                        crossSiteStorageGetRequest.domain,
                        crossSiteStorageGetRequest.tenantBarrier,
                        crossSiteStorageGetRequest.enterpriseBarrier,
                        crossSiteStorageGetRequest.userBarrier,
                        crossSiteStorageGetRequest.overrideBarrierValues
                    )
                    commandMessageAction(getResult, MessageType.CrossSiteStorageGet);
                    break;
                
                case MessageType.CrossSiteStorageSet:
                    const crossSiteStorageSetRequest = message.data as {
                        key: string,
                        value: string, 
                        domain: string
                        tenantBarrier: boolean,
                        enterpriseBarrier:boolean,
                        userBarrier: boolean,
                        overrideBarrierValues: {
                            tenantBarrierValue?: number,
                            enterpriseBarrierValue?: number,
                            userBarrierValue?: number,
                        }
                    };
                    const setResult = await this.setStorageItem(
                        crossSiteStorageSetRequest.key,
                        crossSiteStorageSetRequest.value,
                        crossSiteStorageSetRequest.domain,
                        crossSiteStorageSetRequest.tenantBarrier,
                        crossSiteStorageSetRequest.enterpriseBarrier,
                        crossSiteStorageSetRequest.userBarrier,
                        crossSiteStorageSetRequest.overrideBarrierValues
                    )
                    commandMessageAction(setResult, MessageType.CrossSiteStorageSet);
                    break;
                
                case MessageType.CrossSiteStorageRemove:
                    const crossSiteStorageRemoveRequest = message.data as {
                        key: string, 
                        domain: string
                        tenantBarrier:boolean, 
                        enterpriseBarrier:boolean,
                        userBarrier: boolean,
                        overrideBarrierValues: {
                            tenantBarrierValue?: number,
                            enterpriseBarrierValue?: number,
                            userBarrierValue?: number,
                        }
                    };
                    const removeResult = await this.removeStorageItem(
                        crossSiteStorageRemoveRequest.key, 
                        crossSiteStorageRemoveRequest.domain,
                        crossSiteStorageRemoveRequest.tenantBarrier,
                        crossSiteStorageRemoveRequest.enterpriseBarrier,
                        crossSiteStorageRemoveRequest.userBarrier,
                        crossSiteStorageRemoveRequest.overrideBarrierValues
                    )
                    commandMessageAction(removeResult, MessageType.CrossSiteStorageRemove);
                    break;
            }
          });
    }
}
