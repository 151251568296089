import { UICommandInterface } from './commands/ui-command.interface';
import { ModifiedSubscriberInterface } from '../../lib/view-models/modified-subscriber.interface';
import { ModelInterface } from '../domain-models/model.interface';
import { BasePropertyViewModel } from './base-property-view-model';
import { PropertyViewModelInitializationInfo } from './property-view-model-initialization-info';
import { PropertyViewModelInterface } from './property-view-model.interface';
import { PropertyViewModelPropertyChangedEventArgs } from './property-view-property-changed-event-args';
import { ViewModelEventDispatcher } from './view-model-event-dispatcher';
import { DomainModelMetaData, MetaDataUtils, PropertyMetaData } from '../meta-data';
import { BaseError } from '../messages/base-error';
import { SourceMessage, MessageContainer } from './message-container';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClassInformationType, ClassInformationUtility, LogService } from '@nts/std/utility';
import { AccessMode } from '../meta-data/access-mode.enum';
import { takeUntil } from 'rxjs/operators';
import { BaseViewModelInterface } from './base-view-model.interface';
import { CollectionViewModelInterface } from './collection-view-model.interface';
import { InternalViewModelInterface } from './internal-view-model.interface';
import { ExternalViewModelInterface } from './external-view-model.interface';
import { ValidationErrorCodes } from '../resources/validation-error-codes';
import { RequiredValidator } from '../domain-models/decorators/validations/required-validation';


// TODO Pensare nuova implementazione dei propertyViewModel
export abstract class PropertyViewModel<T> extends BasePropertyViewModel implements PropertyViewModelInterface {
    
    abstract setDefaultValueFromLayoutMetaData(defaultValue: string): void;

    classType = ClassInformationType.PropertyViewModel;

    // ATTENZIONE: non abilitare, è necessario solo in casistiche estreme
    instantModelChange = false;

    // Utilizzata nelle external list pvm per aver la possibilità di evitare di notificare i cambiamenti
    canNotifyModified = true;

    customGetterValueChanged = new Subject<T>();
    customCommandList = new Map<string, UICommandInterface>();
    customCommandsName = [];
    decodePendingUpdated: Subject<boolean>;
    autocompleteMinSearchLength = 3;
    propertyMetaData: PropertyMetaData;
    faultBackValue: T;
    domainModelMetadata: DomainModelMetaData;
    isInternalKey: boolean;
    
    /**
     * utilizzata nei template dei componenti per evitare la detect changes
     */
    formattedValue$ =  new BehaviorSubject<string>('');
    value$: BehaviorSubject<T> = new BehaviorSubject<T>(null);
    
    securityAccess: AccessMode | null = null;
    //darebbe da creare un external property view model con tutte le property dedicate all'external
    setIdentityOnSet = true;
    protected internalDefaultColor: string = null;
    protected internalActiveColor: string = null;
    protected internalHoverColor: string = null;

    additionalCustomValidation?: () => BaseError[];

    customSetter: (x: T) => Promise<void>;
    customGetter: () => T;

    get defaultColor() {
        return this.internalDefaultColor;
    }
    set defaultColor(value: string) {
        if (this.internalDefaultColor !== value) {
            this.internalDefaultColor = value;
            this.onPropertyChanged('defaultColor');
        }
    }

    get activeColor() {
        return this.internalActiveColor;
    }
    set activeColor(value: string) {
        if (this.internalActiveColor !== value) {
            this.internalActiveColor = value;
            this.onPropertyChanged('activeColor');
        }
    }

    get hoverColor() {
        return this.internalHoverColor;
    }
    set hoverColor(value: string) {
        if (this.internalHoverColor !== value) {
            this.internalHoverColor = value;
            this.onPropertyChanged('hoverColor');
        }
    }

    override get isRequired() {
        return this.internalIsRequired;
    }
    override set isRequired(value: boolean) {
        if (this.internalIsRequired !== value) {
            this.internalIsRequired = value;
            this.validate();
            this.onPropertyChanged('isRequired');
        }
    }

    override get isEnabled(): boolean {
        // TODO
        // if (this.isInternalKey) {
        //   // (this.parent.parent as EntityViewModelInterface).getProperty => this.parent.parent is EntityViewModelInterface
        //   if (this.behavior.isExternal && this.parent.parent && (this.parent.parent as EntityViewModelInterface).getProperty) {
        //     return this._isEnabled && (<EntityViewModelInterface>this.parent.parent).currentState === ViewModelStates.New;
        //   } else if (this.behavior.wrappedEntity != null) {
        //     return this._isEnabled && this.behavior.wrappedEntity.currentState === State.New;
        //   }
        //   return this._isEnabled;
        // } else {
        return this._isEnabled;
    }
    override set isEnabled(value: boolean) {
        if (this._isEnabled !== value) {
            this._isEnabled = value;
            this.onPropertyChanged('isEnabled');
            this.isEnabled$.next(value);
        }
    }

    override isEnabled$ = new BehaviorSubject<boolean>(true);

    override get propertyPath(): string {
        return this._propertyPath;
    }

    get isCustom() {
        return this._isCustom;
    }

    get decodePending(): boolean {
        return this._decodePending;
    }
    set decodePending(value: boolean) {
        this._decodePending = value;
        this.decodePendingUpdated.next(value);
    }

    get value(): T {
        // Casistica in cui abbiamo il decoratore custom property view model impostato
        if (this._isCustom && this.customGetter == null) {
            throw new Error('You must implement value getter');
        }
        // Casistica in cui viene ridefinito il custom getter
        // Di default viene definito in tutti gli external view model
        return this.getValue();
    }
    set value(updatedValue: T) {
        this.setValueAsync(updatedValue)
    }

    get defaultFaultBackValue(): T {
        return this._defaultFaultBackValue;
    }

    get layoutDefaultValue(): T {
        return this._layoutDefaultValue;
    }

    get formattedValue(): string {
        return this.getFormattedValue(this.value);
    }
    
    protected abstract getFormattedValue(value: T): string;
    protected _defaultFaultBackValue: T;
    protected _layoutDefaultValue: T = undefined;
    protected eventDispatcher: ViewModelEventDispatcher;
    protected _isCustom = false;
    private _modifiedSubscriber: ModifiedSubscriberInterface;
    protected model: ModelInterface;

    private _decodePending = false;
    private _propertyPath: string;
    private _valueIsNullable: boolean;
    private _customGetterValue = null;

    constructor(initInfo: PropertyViewModelInitializationInfo, valueIsNullable: boolean) {
        super(initInfo.propertyName);

        this.customGetter = initInfo.customGetter;
        this.customSetter = initInfo.customSetter;

        if (initInfo?.propertyMetaData?.descriptions) {
            this.metadataDescription = initInfo.useMessageResourceKey ?
                MessageResourceManager.Current.getMessage(initInfo.propertyMetaData.descriptions.descriptionKey) :
                initInfo.propertyMetaData.descriptions.description;
        }
        
        if (initInfo?.propertyMetaData?.descriptions) {
            this.metadataShortDescription = initInfo.useMessageResourceKey ?
                MessageResourceManager.Current.getMessage(initInfo.propertyMetaData.descriptions.displayNameKey) :
                initInfo.propertyMetaData.descriptions.displayName;
        }

        this.domainModelMetadata = initInfo.domainModelMetadata;
        this.propertyMetaData = initInfo.propertyMetaData;
        this._valueIsNullable = valueIsNullable;
        this._modifiedSubscriber = initInfo.modifiedSubscriber;
        this.eventDispatcher = initInfo.eventDispatcher;
        this.model = initInfo.model;
        if (this.model != null) {
            this.model.propertyChanged.pipe(takeUntil(this.destroySubscribers$), takeUntil(this.modelChanged)).subscribe((propertyName) => {
                this.modelPropertyChanged(propertyName);
            });
        }
        this.decodePendingUpdated = new Subject<boolean>();
        this._isCustom = initInfo.isCustom;
        this.internalIsRequired = initInfo.propertyMetaData?.isRequired || false;
        this.isInternalKey = this.checkIsInternalKey(initInfo);
        this.parent = initInfo.parent;
        this._propertyPath = initInfo.path;

        // TODO Tommy
        // gestire fieldInfo
    }

    get propertyPathWithIndex(): string {
        return this.recursiveFindPropertyPathWithIndex(this.parent).join('.');
    }

    private recursiveFindPropertyPathWithIndex(viewmodel: BaseViewModelInterface, path = []): string[] {
        if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.RootViewModel)) {
            return path;
        }
        if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.PropertyViewModel)) {
            path.unshift((viewmodel as PropertyViewModelInterface).propertyName);
        }
        if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.ItemViewModel)) {
            path.unshift((viewmodel.parent as CollectionViewModelInterface<any>).indexOf(viewmodel));
        } else if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.CollectionViewModel)) {
            path.unshift(MetaDataUtils.toCamelCase((viewmodel as CollectionViewModelInterface<any>).collectionMetaData.principalPropertyName));
        } else if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.InternalViewModel)) {
            path.unshift(MetaDataUtils.toCamelCase((viewmodel as InternalViewModelInterface).relationMetaData.principalPropertyName));
        } else if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.ExternalViewModel)) {
            path.unshift(MetaDataUtils.toCamelCase((viewmodel as ExternalViewModelInterface).externalMetaData.principalPropertyName));
        } else if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.MasterViewModel)) {
            return path;
        }

        return this.recursiveFindPropertyPathWithIndex(viewmodel.parent, path);
    }

    override clearErrors() {
        if (this.hasErrors) {
            this.removeAllMessages();
            this.propertyViewModelChanged.emit();
        }
    }

    override onPropertyChanged(propertyName: string = null) {
        const args = new PropertyViewModelPropertyChangedEventArgs();
        args.propertyName = propertyName;
        if (propertyName === this.bindedValuePropertyName) {
            
            if (
                this._modifiedSubscriber != null && this.canNotifyModified && 

                // Se sono in un modello mockato non devo notificare il modifiedSubscriber
                (this?.model?.isMock == null || this?.model?.isMock == false)
            ) {
                this._modifiedSubscriber.notifyModified();
            }
            args.value = this.value;
            this.formattedValue$.next(this.formattedValue);
            this.value$.next(this.value);
        }
        this.propertyChanged.emit(args);
    }

    onModelChanged() {
        this.modelChanged.emit();
    }

    validateCustomPropertyViewModel(baseErrors: Array<BaseError>): boolean {
        return true;
    }

    applyRequiredLogic(baseErrors: BaseError[]) {
        // rimuovo gli errori del required aggiunti
        baseErrors = baseErrors.filter((e)=> e.code !== ValidationErrorCodes.IsRequired)

        if (this.isRequired == true) {
            const validator = new RequiredValidator(this.metadataShortDescription);
            if (!validator.validate(this.value)) {
                const e = new BaseError();
                e.description =  validator.errorMessage;
                e.code =  validator.messageCode;
                e.propertyName = this.propertyName;
                baseErrors.push(e);
                return baseErrors;
            }
        }
        return baseErrors;
    }

    getModelErrors(propertyName: string) {
        let baseErrors: BaseError[] = [];
        this.model.validateProperty(propertyName, baseErrors);

        baseErrors = this.applyRequiredLogic(baseErrors);

        if (this.additionalCustomValidation) {
            const additionalCustomValidationErrors = this.additionalCustomValidation();
            if (additionalCustomValidationErrors?.length > 0) {
                return [...baseErrors, ...additionalCustomValidationErrors];
            }
        }

        return baseErrors;
    }

    override validate() {
        if (this.skipValidation || this.model?.isMock === true) {
            return;
        }

        const value = this.value;
        const validationMessages = this.messageContainerCollection;
        let previousError = false;
        const itemsToRemove = validationMessages.filter(x => x.sourceMessage === SourceMessage.Validation || x.sourceMessage === SourceMessage.Api);

        itemsToRemove.forEach((itemToRemove) => {
            validationMessages.splice(validationMessages.indexOf(itemToRemove), 1);
            previousError = true;
        });

        if (this.model != null) {
            // valido il campo
            const baseErrors = this.getModelErrors(this.propertyName);

            if (baseErrors.length > 0) {

                baseErrors.forEach(e => {
                    this.addErrorMessageIfNotExist(e, SourceMessage.Validation, false);
                });

                if (this['eventDispatcher']) {
                    const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                    ed.onAddMessageInViewModel.next({ viewModel: this, messages: baseErrors });
                }
                // this.raiseErrorsChanged(this.propertyViewModel.bindedValuePropertyName);

            } else if (previousError) { // && !validationErrors.ContainsKey(this.PropertyViewModel.BindedValuePropertyName)

                if (this['eventDispatcher']) {
                    const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                    ed.onClearMessagesInViewModel.next(this);
                }

                // this.raiseErrorsChanged(this.propertyViewModel.bindedValuePropertyName);
            }
            this.updateCurrentErrors();
            this.onErrorStatusChanged.next();
        } else {
            let baseErrors = this.applyRequiredLogic([]);

            if (this.isCustom) {
                this.validateCustomPropertyViewModel(baseErrors);

                if (baseErrors.length > 0) {

                    baseErrors.forEach(e => {
                        this.addErrorMessageIfNotExist(e, SourceMessage.Validation, false);
                    });
    
                    if (this['eventDispatcher']) {
                        const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                        ed.onAddMessageInViewModel.next({ viewModel: this, messages: baseErrors });
                    }
                    // this.raiseErrorsChanged(this.propertyViewModel.bindedValuePropertyName);
    
                }
            } else if (previousError) { // && !validationErrors.ContainsKey(this.PropertyViewModel.BindedValuePropertyName)

                if (this['eventDispatcher']) {
                    const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                    ed.onClearMessagesInViewModel.next(this);
                }

                // this.raiseErrorsChanged(this.propertyViewModel.bindedValuePropertyName);
            }
            this.updateCurrentErrors();
            this.onErrorStatusChanged.next();
        }
    }

    modelPropertyChanged(propertyName: string) {
        if (propertyName === this.propertyName) {
            this.validate();
            this.onPropertyChanged(this.bindedValuePropertyName);
        }
    }

    setCodeOnExternal(isCode: boolean) {
        // TODO Tommy
        // (<ExternalPropertyViewModelBehavior<T>>this.behavior).isCodeProperty = isCode;
    }

    setDefaultFaultbackValue(defaultFaultbackValue: T) {
        this._defaultFaultBackValue = defaultFaultbackValue;
    }

    setModel(model: ModelInterface) {
        this.faultBackValue = this._defaultFaultBackValue;
        this.model = model;
        this.onModelChanged();
        if (this.model != null) {
            this.model.propertyChanged.pipe(takeUntil(this.destroySubscribers$), takeUntil(this.modelChanged)).subscribe((propertyName) => {
                this.modelPropertyChanged(propertyName);
            });
        }
        // this.onPropertyChanged(this.bindedValuePropertyName);
    }

    getModel() {
        return this.model;
    }

    async resetValue(useDefaultValue: boolean): Promise<void> {
        if (useDefaultValue) {
            if (typeof this.value === 'string') {
                await this.setValueAsync('' as any);
            } else if (typeof this.value === 'number') {
                await this.setValueAsync(0 as any);
            } else if (typeof this.value === 'boolean') {
                await this.setValueAsync(false as any);
            } else {
                await this.setValueAsync(undefined);
                LogService.warn('Default Value Not Implemented for property: ' + this.propertyName);
            }
        } else {
            await this.setValueAsync(this._defaultFaultBackValue);
        }
    }

    // TODO Tommy
    checkIsInternalKey(initInfo: PropertyViewModelInitializationInfo): boolean {
        return false;
        // let res = false;
        // if (initInfo.entityMetadata != null && initInfo.entityMetadata.identityProperties.length) {

        //   TODO: ATTENZIONE toPascalCase non è simmetrico a CamelCase
        //   res = initInfo.entityMetadata.identityProperties.indexOf(MetaDataUtils.toPascalCase(initInfo.propertyName)) !== -1;
        //   if (!res) {
        //     // proprietà di navigazione che usa una parte della identity
        //     TODO: ATTENZIONE toPascalCase non è simmetrico a CamelCase
        //     const ext = initInfo.entityMetadata.externalRelations.find(x => x.propertyName === MetaDataUtils.toPascalCase(initInfo.propertyName));
        //     if (ext != null) {
        //       res = (initInfo.entityMetadata.identityProperties.find(x => ext.principalAssociationProerties.indexOf(x) !== -1) != null);
        //     }
        //   }
        // }
        // return res;
    }

    syncModel(value: T) {
        if (this.model != null) {
            this.model.setPropertyValue(this.propertyName, value);
        } else if (this.faultBackValue !== value) {
            this.faultBackValue = value;
        }
    }

    async setValueAsync(updatedValue: T) {
        if (this._isCustom && this.customSetter == null) {
            throw new Error('You must implement value setter');
        }
        // Check undefined and null
        if (this.value != updatedValue) {
            if (this.customSetter != null) {
                await this.customSetter(updatedValue);

                // Se il custom setter ha impostato updatedValue in this.value faccio scattare la property changed e la validate altrimenti non faccio niente
                if (this.value == updatedValue) {
                    this.validate();                
                    this.onPropertyChanged(this.bindedValuePropertyName);
                } else {
                    this.onPropertyChanged(this.bindedValuePropertyName);
                }   

            } else {
                // quano faccio il sync model scatta onPropertyChanged partendo dal modello
                this.syncModel(updatedValue);
                this.validate();
            }
            this.formattedValue$.next(this.formattedValue);
            this.value$.next(this.value);
        }        
    }

    // Da usare solo nei custom getter
    internalGetValue(): T {
        return this.model != null ?
            this.model.getPropertyValue(this.propertyName) : this.faultBackValue as T;
    }    

    getValue(): T {
        if (this.customGetter != null) {
            const value = this.customGetter();
            if (this._customGetterValue != value) {
                this._customGetterValue = value;
                setTimeout(() =>this.customGetterValueChanged.next(this._customGetterValue));
            }

            // this.onPropertyChanged(this.bindedValuePropertyName);
            return this._customGetterValue;
        } else {
            return this.internalGetValue();
        }
        // return this.customGetter != null ? this.customGetter() : this.internalGetValue();
    }

    setValue(value: any) {
        this.value = value as T;
    }

    async setCurrentValueWithDefaultValueFromLayoutMetaData(): Promise<void> {
        if (this.layoutDefaultValue !== undefined) {
            const oldValue = this.canNotifyModified;
            this.canNotifyModified = false;
            await this.setValueAsync(this.layoutDefaultValue);
            this.canNotifyModified = oldValue;
        }
    }

    setErrors(sourceMessage: SourceMessage, errorList: Array<string>) {
        if (errorList.length > 0) {
            this.removeMessagesFromSource(sourceMessage);
            const errors = errorList.map(item => {
                const error = new BaseError();
                error.description = item;
                error.propertyName = this.propertyName;
                this.messageContainerCollection.splice(
                    0, 0, MessageContainer.fromBaseAndSourceMessage(error, sourceMessage, this.uniqueId));
                return error;
            });
            if (this['eventDispatcher']) {
                const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                ed.onAddMessageInViewModel.next({ viewModel: this, messages: errors });
            }
        } else if (this.messageContainerCollection.findIndex(x => x.sourceMessage === sourceMessage) > -1) {
            // rimuovi tutti gli elementi del tipo sourceMessage
            this.removeMessagesFromSource(sourceMessage);
            if (this['eventDispatcher']) {
                const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                ed.onRemovedMessageInViewModel.next(this);
            }
        }
        // this.propertyViewModelChanged.emit();
        this.updateCurrentErrors();
        this.onErrorStatusChanged.next();
    }
}
