import extend from 'lodash-es/extend';
import { forkJoin, from, identity, Observable, of } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageResourceManager } from './message-resource-manager';
import { LocalstorageHelper, LogService, OnlineService, SerializabledDataType, TIMEOUT_CACHE } from '@nts/std/utility';

export enum TranslationLoaderFileType {
    StdDescriptions = 'stdDescriptions',
    StdMessages = 'stdMessages',
    StdCustomResource = 'stdCustomResource',
    Descriptions = 'descriptions',
    Messages = 'messages',
    CustomResource = 'customResource'
}

export const TRANSLATION_LOADER_STORAGE_KEY = 'translations'

@Injectable({
    providedIn: 'root',
})
export class TranslationLoader {

    constructor(
        private http: HttpClient,
        private onlineService: OnlineService
    ) { }

    getTranslation(lang: string): Observable<any> {

        return from(LocalstorageHelper.getStorageItem(TRANSLATION_LOADER_STORAGE_KEY, undefined, false, false, false)).pipe(
            switchMap((storedTranslations) => {

                let obs: Observable<SerializabledDataType> = null;

                if(this.onlineService.isOnline === false) {
                    if (storedTranslations) {
                        // Caso offline e cache presente, ritorna subito l'oggetto storedJsonConfiguration
                        obs = of(storedTranslations)
                    } else {
                        // Caso offline e cache non presente, ritorna oggetto vuoto
                        obs = of({})
                    }
                } else {
                    obs = forkJoin({
                        // Vengono generate da NTS Framework
                        [TranslationLoaderFileType.StdDescriptions]: this.http.get('assets/i18n/std/' + lang + '/descriptions.json').pipe(
                            catchError(e => {
                                LogService.warn('missing std descriptions translations!', e);
                                return of({});
                            })
                        ),
                        // Vengono generate da NTS Framework
                        [TranslationLoaderFileType.StdMessages]: this.http.get('assets/i18n/std/' + lang + '/messages.json').pipe(
                            catchError(e => {
                                LogService.warn('missing std messages translations!', e);
                                return of({});
                            })
                        ),
                        // Le custom resource vengono prese direttamente dalla libreria projects std e non auto generate
                        [TranslationLoaderFileType.StdCustomResource]: this.http.get('assets/std/i18n/' + lang + '/custom-resource.json').pipe(
                            catchError(e => {
                                LogService.warn('missing std custom translations!', e);
                                return of({});
                            })
                        ),
                        [TranslationLoaderFileType.Descriptions]: this.http.get('assets/i18n/' + lang + '/descriptions.json').pipe(
                            catchError(e => {
                                LogService.warn('missing app descriptions translations!', e);
                                return of({});
                            })
                        ),
                        [TranslationLoaderFileType.Messages]: this.http.get('assets/i18n/' + lang + '/messages.json').pipe(
                            catchError(e => {
                                LogService.warn('missing app messages translations!', e);
                                return of({});
                            })
                        ),
                        [TranslationLoaderFileType.CustomResource]: this.http.get('assets/i18n/' + lang + '/custom-resource.json').pipe(
                            catchError(e => {
                                LogService.warn('missing app custom translations!', e);
                                return of({});
                            })
                        )
                        .pipe(
                            storedTranslations ?
                                // Caso online e cache presente, ritorna l'oggetto storedJsonConfiguration solot dopo un timeout di TIMEOUT_CACHE
                                timeout({ 
                                    first: TIMEOUT_CACHE,
                                    with: () => {
                                        return of(storedTranslations);
                                    }
                                }) :
                                // Caso online e cache non presente, ritorna identity
                                identity
                        )
                    })
                }

                return obs.pipe(
                    map(async (translations) => {
                        if (
                            translations[TranslationLoaderFileType.StdDescriptions] || 
                            translations[TranslationLoaderFileType.StdMessages] || 
                            translations[TranslationLoaderFileType.StdCustomResource] ||
                            translations[TranslationLoaderFileType.Descriptions] || 
                            translations[TranslationLoaderFileType.Messages] || 
                            translations[TranslationLoaderFileType.CustomResource]
                        ) {
                            await LocalstorageHelper.setStorageItem(TRANSLATION_LOADER_STORAGE_KEY, translations, undefined, false, false);
                        }                
                        TranslationLoader.mapTranslations(translations);
                    })
                );
            })
        )
    }

    static mapTranslations(res: SerializabledDataType) {
        const r = extend(
            res[TranslationLoaderFileType.StdDescriptions], 
            res[TranslationLoaderFileType.StdMessages], 
            res[TranslationLoaderFileType.StdCustomResource],
        );
        Object.keys(r).forEach(k => {
            const nk = 'std_' + k;
            r[nk] = r[k];
            delete r[k];
        });
        return MessageResourceManager.Current.translations = extend(
            res[TranslationLoaderFileType.StdDescriptions], 
            res[TranslationLoaderFileType.StdMessages], 
            res[TranslationLoaderFileType.StdCustomResource],
            res[TranslationLoaderFileType.Descriptions], 
            res[TranslationLoaderFileType.Messages], 
            res[TranslationLoaderFileType.CustomResource]
        );
    }
}