import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter} from '@angular/core';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import { Subject, merge, Observable, firstValueFrom, fromEvent } from 'rxjs';
import { OrchestratorViewModelInterface } from '../../../view-models/orchestrator-view-model.interface';
import { UICommandInterface } from '../../../view-models/commands/ui-command.interface';
import { MenuItem } from 'primeng/api';
import { CommandsPage } from '../../../view-models/commands/commands-page';
import { ViewModelStates } from '../../../view-models/states';
import { LongOpOrchestratorViewModelInterface } from '../../../view-models/long-op/long-op-orchestrator-view-model.interface';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { UpdateServiceData, UpdateServiceStatusType } from '@nts/std/utility';
import { TextButtonComponent } from '../../shared/buttons/text-button/text-button.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

@UntilDestroy()
@Component({
  selector: 'nts-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgxPopperjsModule,
    TextButtonComponent,
    TabMenuModule,
    AsyncPipe,
    NgClass,
    SvgIconComponent,
    NgIf
  ]
})
export class PageHeaderComponent implements OnInit, AfterViewInit {

  @ViewChild('itemListContainer') itemListContainer: ElementRef;
  @Input() orchestratorViewModel: OrchestratorViewModelInterface | LongOpOrchestratorViewModelInterface;
  @Input() updateStatus: UpdateServiceData;
  @Output() onUpdateStatusClicked: EventEmitter<UpdateServiceData> = new EventEmitter();
  relatedItemList$: BehaviorSubject<MenuItem[]> = new BehaviorSubject([]);
  updateServiceStatusType = UpdateServiceStatusType;

  relatedCommandsPage: Array<CommandsPage>;
  activeRelatedItem: MenuItem;

  showTitle = false;
  showCurrentState = false;
  currentStateDescription: string;
  currentState: ViewModelStates;
  errorBarIsCollapsed = true;
  showRelatedItemsScrollArea = false;

  ngxPopperjsTriggers = NgxPopperjsTriggers;
  ngxPopperjsPlacements = NgxPopperjsPlacements;

  currentStateIconMapping = {
    [ViewModelStates.New]: 'empty-page',
    [ViewModelStates.Modified]: 'edit-pencil',
    [ViewModelStates.NewModified]: 'page-edit',
    [ViewModelStates.Removed]: 'eye-empty',
    [ViewModelStates.Research]: 'eye-empty',
    [ViewModelStates.Unchanged]: 'eye-empty',
    [ViewModelStates.Versioned]: 'history'
  }

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(protected cd: ChangeDetectorRef) {

  }
  ngAfterViewInit(): void {

    fromEvent(window, 'resize')
    .pipe(untilDestroyed(this), debounceTime(200)
    )
    .subscribe(() => {
      this.updateRelatedItemsScrollAreaVisibility(this.itemListContainer)
    });    
  }

  async ngOnInit() {

    await firstValueFrom(
      this.orchestratorViewModel.toolBarViewModel.loadedCompleted.pipe(
        untilDestroyed(this), 
        filter((completed) => completed)
      ),
      {defaultValue: null}
    );

    this.relatedCommandsPage = this.orchestratorViewModel.toolBarViewModel.relatedCommandsPage;

    merge(
      this.orchestratorViewModel.currentStateChanged,
      this.orchestratorViewModel.rootViewModelChanged,
      this.orchestratorViewModel.rootViewModelModified
    ).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.currentStateDescription = this.orchestratorViewModel.currentStateDescription;
      this.currentState = this.orchestratorViewModel.currentState.value;
      this.showCurrentState = this.orchestratorViewModel.showCurrentState;
      this.cd.detectChanges();
    });

    this.orchestratorViewModel.askPendingChangesCanceled.pipe(untilDestroyed(this)).subscribe(() => {
      const oldActiveRelatedItem = this.activeRelatedItem;
      this.activeRelatedItem = null;
      this.cd.detectChanges();
      this.activeRelatedItem = oldActiveRelatedItem;
      this.cd.detectChanges();
    })

    this.orchestratorViewModel.currentStateChanged.pipe(
      untilDestroyed(this),
      // Usando switchmap sio riescono a gestire le funzioni con asyn/await
      // https://medium.com/@benlesh/rxjs-observable-interop-with-promises-and-async-await-bebb05306875
      switchMap(_ => this.getMenuItems())
    ).subscribe({
      complete: () => this.relatedItemList$.complete(),
      next: (items) => this.relatedItemList$.next(items),
    })

    this.showCurrentState = this.orchestratorViewModel.showCurrentState;
    this.currentStateDescription = this.orchestratorViewModel.currentStateDescription;    

    this.cd.markForCheck();
  }

  private async getMenuItems(): Promise<MenuItem[]> {

    const relatedItemList: MenuItem[] = [];
    if (this.relatedCommandsPage?.length > 0) {
      for (const relatedCommandPage of this.relatedCommandsPage) {
        for (const commandGroup of relatedCommandPage.commandGroups) {
          for (const command of commandGroup.commands) {
            const isHighlighted = await firstValueFrom(
              command.isHighlighted(null).pipe(untilDestroyed(this)),
              {defaultValue: false}
            );
  
            relatedItemList.push({
              label: command.displayName,
              //title: command.displayName, //rimuovo per evitare il tooltip del browser, utilizzo il custom popper
              icon: command.iconClass,
              visible: command.isVisible$.value,
              styleClass: isHighlighted ? 'is-highlighted' : '',
              disabled: !command.canExecute$.value,
              command: async (event) => {
                const sourceItem  = this.activeRelatedItem;
                await command.execute(event);              
                if ((event.originalEvent as any).ctrlKey) {
                  //con primeng non posso annullare il comnando, quindi verrebbe selezionato il tab in cui mi sto spostando
                  //per evitare imposto l'item corrente a null, aggiorno la grafica, reimposto item corrente con quello precedente e riaggiorno la grafica
                  this.activeRelatedItem = null;
                  this.cd.detectChanges();
                  this.activeRelatedItem = sourceItem;
                  this.cd.detectChanges();
                }
              }
            });
            if (isHighlighted) {
              this.activeRelatedItem = relatedItemList[relatedItemList.length - 1];
            }
          }
        }
      }
    }

    if (relatedItemList.length === 0) {
      this.showTitle = true;
    }
    setTimeout(() => this.updateRelatedItemsScrollAreaVisibility(this.itemListContainer), 250);
    this.cd.detectChanges();
    return relatedItemList;
  }

  updateRelatedItemsScrollAreaVisibility(element) {
    this.showRelatedItemsScrollArea = element.nativeElement.offsetHeight < element.nativeElement.scrollHeight ||
      element.nativeElement.offsetWidth < element.nativeElement.scrollWidth;
    this.cd.detectChanges();  
  }

  getCssClass(cmd: UICommandInterface) {
    return cmd.name + '-button';
  }

  scrollLeft(){
    this.itemListContainer.nativeElement.scrollLeft -= 150;
  }

  scrollRight(){
    this.itemListContainer.nativeElement.scrollLeft += 150;
  }

  execute(cmd: UICommandInterface) {
    // setTimeout(() => {
    cmd.execute();
    // }, 100);
  }
}
