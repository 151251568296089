import { LongOpToolBarViewModel } from './long-op-tool-bar-view-model';
import { BaseScheduleLongOpOrchestratorViewModelInterface } from './base-schedule-long-op-orchestrator-view-model.interface';
import { ToolBarViewModelInterface } from '../tool-bar-view-model.interface';
import { UICommandInterface } from '../commands/ui-command.interface';
import { CommandFactory } from '../commands/command-factory';
import { CommandTypes } from '../commands/command-types';

export class BaseScheduleLongOpToolBarViewModel extends LongOpToolBarViewModel implements ToolBarViewModelInterface {

    override get orchestrator(): BaseScheduleLongOpOrchestratorViewModelInterface {
        return this._orchestrator as BaseScheduleLongOpOrchestratorViewModelInterface;
    }

    protected override addHomeCommands(): Array<UICommandInterface> {
        const items = super.addHomeCommands();

        items.push(
            CommandFactory.createUICommandWithType(
                CommandTypes.Schedule,
                async (x) => await this.orchestrator.schedule(),
                () => this.orchestrator.canSchedule(),
                null,
                () => this.orchestrator.isVisibleSchedule()
            )
        );

        return items;
    }
}
