import { Observable } from 'rxjs';
import { CommandTypes } from './command-types';
import { UICommandSettingsManager } from './ui-command-settings-manager';
import { UICommand } from './ui-command';
import { UICommandInterface } from './ui-command.interface';
import { UIResultCommand } from './ui-result-command';
import { UIResultCommandInterface } from './ui-result-command.interface';

export class CommandFactory {

    static createUICommandWithType(
        type: CommandTypes, 
        execute: (x: any) => Promise<void>, 
        canExecuteMethod: () => Observable<boolean> = null, 
        isHighlightMethod: (x: any) => Observable<boolean> = null, 
        isVisibleMethod: () => Observable<boolean> = null
    ): UICommandInterface {

        const manager = new UICommandSettingsManager();
        const cmd = manager.setUICommand(type, CommandFactory.createUICommand(execute, canExecuteMethod, isHighlightMethod, isVisibleMethod));
        return cmd;
    }

    static createUICommand(
        execute: (x: any, y?: any) => Promise<void>, 
        canExecuteMethod: () => Observable<boolean> = null, 
        isHighlightMethod: (x: any) => Observable<boolean> = null, 
        isVisibleMethod: () => Observable<boolean> = null): UICommandInterface 
    {
        const command = canExecuteMethod != null ? 
            new UICommand(() => canExecuteMethod(), execute, isHighlightMethod, isVisibleMethod) : 
            new UICommand(null, execute, isHighlightMethod, isVisibleMethod);
        return command;
    }

    static createResultUICommand<TResult>(execute: (x: any) => Promise<TResult>, canExecuteMethod: () => Observable<boolean> = null): UIResultCommandInterface<TResult> {
        const command = canExecuteMethod != null ? new UIResultCommand<TResult>(() => canExecuteMethod(), execute) : new UIResultCommand<TResult>(null, execute);
        return command;
    }
}

