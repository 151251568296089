import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, Renderer2, NgZone } from '@angular/core';
import { Subject, fromEvent, Observable, Subscription, of, merge, firstValueFrom, empty, interval, Observer, BehaviorSubject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';

import { CommandsPage } from '../../../view-models/commands/commands-page';
import { OrchestratorViewModelInterface } from '../../../view-models/orchestrator-view-model.interface';
import { MasterDetailOrchestratorViewModelInterface } from '../../../view-models/master-detail-orchestrator-view-model.interface';
import { MenuItem } from 'primeng/api';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { OperationState } from '../../../domain-models/spool/operation-state';
import { UICommandInterface } from '../../../view-models';
import { SlideMenu, SlideMenuModule } from 'primeng/slidemenu';
import { FilledButtonComponent, FilledButtonType } from '../../shared/buttons/filled-button/filled-button.component';
import { LongOpOrchestratorViewModelInterface } from '../../../view-models/long-op/long-op-orchestrator-view-model.interface';
import { TelemetryService } from '@nts/std/telemetry';
import { RibbonButtonComponent } from '../../shared/buttons/ribbon-button/ribbon-button.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';


@Component({
  selector: 'nts-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    RibbonButtonComponent,
    AsyncPipe,
    SlideMenuModule,
    FilledButtonComponent,
    NgFor
  ]
})

export class ToolBarComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() orchestratorViewModel: OrchestratorViewModelInterface | LongOpOrchestratorViewModelInterface;
  @ViewChild('commandContainer', { static: false }) commandContainer: ElementRef;
  @ViewChild('moreOptionsMenu', { static: false }) moreOptionsMenu: SlideMenu;
  @ViewChild('mobileMenu', { static: false }) mobileMenu: SlideMenu;

  commandsPage: Array<CommandsPage>;
  additionalCommandsPage: Array<CommandsPage>;
  defaultCommand: UICommandInterface;
  defaultCommandType: FilledButtonType;
  sidebarCommandsPage: Array<CommandsPage>;
  moreOptionsMenuItemList: MenuItem[] = [];
  mobileMenuItemList: MenuItem[] = [];
  moreOptionMenuViewportHeight = 0;
  mobileMenuViewportHeight = 0;
  mobileMenuViewportWidth = 0;
  operationState = OperationState;
  menuDocumentClickListener: any;

  moreOptionsCommand: UICommandInterface;
  mobileMenuCommand: UICommandInterface;
  currentStateDescription: string;
  errorBarIsCollapsed = true;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected cd: ChangeDetectorRef,
    protected environment: EnvironmentConfiguration,
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    private readonly telemetryService: TelemetryService
  ) {}

  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  ngAfterViewInit(): void {
    this.checkMoreOptionsMenu();
    this.checkMobileMenu();
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.pipe(debounceTime(100)).subscribe(evt => {
      this.checkMoreOptionsMenu();
      this.checkMobileMenu();
    })
  }

  getElementSum(): number {
    let sum = 0;
    for (const element of this.commandContainer.nativeElement.children) {
      sum = sum + this.getWidth(element, 'full');
    }
    return sum;
  }

  async executeCommand(command: UICommandInterface, e: any){
    this.closeAllMenu(); 
   
    // E' stato aggiunto un timeout di 100 ms per dare tempo ai menù di chiudersi e quindi non lasciarli aperti se si cambia pagina, #3887
    setTimeout(() => {
      command.execute(command, e);
      this.telemetryService.trackEvent({
        name: `Command ${command.displayName} executed`,
      }, {command})
    }, 100)    
  }

  closeAllMenu() {
    if (this.moreOptionsMenu) {
      this.moreOptionsMenu.hide();
    }
    if (this.mobileMenu) {
      this.mobileMenu.hide();
    }
  }

  async checkMoreOptionsMenu() {
    if (this.moreOptionsMenuItemList?.length > 0) {
      const maxViewportHeight = window.innerHeight - 140;
      const height = 48 * (this.moreOptionsMenuItemList?.length + 2);
      this.moreOptionMenuViewportHeight = height > maxViewportHeight ? maxViewportHeight : height;
    } else {
      this.moreOptionMenuViewportHeight = 0;
    }

    this.cd.detectChanges();
  }

  async checkMobileMenu() {
    if (this.mobileMenuItemList?.length > 0) {
      const maxViewportHeight = window.innerHeight - 140;
      const height = maxViewportHeight;
      this.mobileMenuViewportHeight = height > maxViewportHeight ? maxViewportHeight : height;
      
      const maxViewportWidth = 560;
      const width = window.innerWidth - 15;
      this.mobileMenuViewportWidth = width > maxViewportWidth ? maxViewportWidth : width;
      // this.mobileMenu.container.nativeElement.focus()
    } else {
      this.mobileMenuViewportHeight = 0;
      this.mobileMenuViewportWidth = 0;
    }

    this.cd.detectChanges();
  }

  async moreOptionClick(moreOptionsMenu: SlideMenu, event: any, component: RibbonButtonComponent) {
    if (!moreOptionsMenu.visible) {
      this.moreOptionsMenuItemList = [
        ...await this.orchestratorViewModel.toolBarViewModel.getMoreOptionsMenuItems(this.moreOptionsMenu)
      ];
      this.checkMoreOptionsMenu();
      setTimeout(() => this.bindMenuDocumentClickListener(this.moreOptionsMenu?.containerViewChild?.nativeElement, component.element.nativeElement), 250);
      this.telemetryService.trackEvent({name: 'MoreOptions Menu Opened'});
    } else {
      this.unbindMenuDocumentClickListener();
      this.telemetryService.trackEvent({name: 'MoreOptions Menu Closed'});
    }
    moreOptionsMenu.toggle(event);
  }

  async mobileMenuClick(mobileMenu: SlideMenu, event: any, component: RibbonButtonComponent) {
      if (this.moreOptionsMenu) {
        this.moreOptionsMenu.hide();
      }
      if (!mobileMenu.visible) {
        this.mobileMenuItemList = [
          ...await this.orchestratorViewModel.toolBarViewModel.getMobileMenuItems(this.mobileMenu)
        ];
        this.checkMobileMenu();
        setTimeout(() => this.bindMenuDocumentClickListener(this.mobileMenu.containerViewChild.nativeElement, component.element.nativeElement), 250);
        this.telemetryService.trackEvent({name: 'Mobile Menu Opened'});
      } else {
        this.unbindMenuDocumentClickListener();
        this.telemetryService.trackEvent({name: 'Mobile Menu Closed'});
      }
      mobileMenu.toggle(event);
  }

  private bindMenuDocumentClickListener(nativeElement, buttonNativeElement) {
    if (!nativeElement) {
      return;
    }
    this.unbindMenuDocumentClickListener();
    if (!this.menuDocumentClickListener) {
        this.zone.runOutsideAngular(() => {
            const documentTarget: any = nativeElement.ownerDocument;

            this.menuDocumentClickListener = this.renderer.listen(documentTarget, 'mousedown', (event) => {
                if (this.isOutsideClicked(event, nativeElement) && this.isOutsideClicked(event, buttonNativeElement)) {
                    this.unbindMenuDocumentClickListener();
                    this.zone.run(() => {
                        this.cd.markForCheck();
                        this.moreOptionsMenu?.hide();
                        this.mobileMenu?.hide();                        
                    });
                }

            });
        });
    }
  }

  private unbindMenuDocumentClickListener() {
    if (this.menuDocumentClickListener) {
        this.menuDocumentClickListener();
        this.menuDocumentClickListener = null;
    }
  }

  private isOutsideClicked(event: Event, nativeElement) {
    return !(nativeElement.isSameNode(event.target) || nativeElement.contains(event.target));
  }  

  async ngOnInit() {
    await firstValueFrom(this.orchestratorViewModel.toolBarViewModel.loadedCompleted.pipe(filter((completed) => completed)));
    this.moreOptionsCommand = this.orchestratorViewModel.toolBarViewModel.getMoreOptionsCommand();
    this.mobileMenuCommand = this.orchestratorViewModel.toolBarViewModel.getMobileMenuCommand();
    if (!this.orchestratorViewModel) { throw new Error('Missing orchestratorViewModel!'); }
    this.commandsPage = this.orchestratorViewModel.toolBarViewModel.commandsPage;
    this.sidebarCommandsPage = this.orchestratorViewModel.toolBarViewModel.sidebarCommandsPage;
    this.additionalCommandsPage = this.orchestratorViewModel.toolBarViewModel.additionalCommandsPage;
    this.defaultCommand = this.orchestratorViewModel.toolBarViewModel.defaultCommand;
    this.defaultCommandType = this.orchestratorViewModel.toolBarViewModel.defaultCommandType;

    merge(
      this.orchestratorViewModel.currentStateChanged,
      this.orchestratorViewModel.spoolProcessCompleted,
      this.orchestratorViewModel.spoolProcessStarted,
      this.orchestratorViewModel.spoolProcessError,
      this.orchestratorViewModel.rootViewModelChanged,
      this.orchestratorViewModel.toolBarViewModel.menuItemChanged,
    ).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.currentStateDescription = this.orchestratorViewModel.currentStateDescription;
      this.cd.detectChanges();
    });

    this.orchestratorViewModel.moreOptionsMenuItemUpdated.pipe(takeUntil(this.destroy$)).subscribe(async () => {
      this.moreOptionsMenuItemList = [
        ...await this.orchestratorViewModel.toolBarViewModel.getMoreOptionsMenuItems(this.moreOptionsMenu)
      ];
    });

    this.moreOptionsMenuItemList = [
      ...await this.orchestratorViewModel.toolBarViewModel.getMoreOptionsMenuItems(this.moreOptionsMenu)
    ];

    this.orchestratorViewModel.mobileMenuItemUpdated.pipe(takeUntil(this.destroy$)).subscribe(async () => {
      this.mobileMenuItemList = [
        ...await this.orchestratorViewModel.toolBarViewModel.getMobileMenuItems(this.mobileMenu)
      ];
    });

    this.mobileMenuItemList = [
      ...await this.orchestratorViewModel.toolBarViewModel.getMobileMenuItems(this.mobileMenu)
    ];

    this.cd.detectChanges();
  }

  public trackById(index: number, command: UICommandInterface) {
    return command.uid;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  toggleLeftSidebar() {
    const ovm = this.orchestratorViewModel as MasterDetailOrchestratorViewModelInterface;
    ovm.eventDispatcher.onNavigationPanelCollapsed.next(!ovm.navigationPanelCollapsed);
  }

  toggleRightSidebar() {
    this.orchestratorViewModel.wingViewModel.isCollapsed = !this.orchestratorViewModel.wingViewModel.isCollapsed;
  }

  getWidth(el, type) {
    if (type === 'inner') {  // .innerWidth()
      return el.clientWidth;
    } else if (type === 'outer') {  // .outerWidth()
      return el.offsetWidth;
    }
    let s = window.getComputedStyle(el, null);
    if (type === 'width')  // .width()
      return el.clientWidth - parseInt(s.getPropertyValue('padding-left')) - parseInt(s.getPropertyValue('padding-right'));
    else if (type === 'full')  // .outerWidth(includeMargins = true)
      return el.offsetWidth + parseInt(s.getPropertyValue('margin-left')) + parseInt(s.getPropertyValue('margin-right'));
    return null;
  }
}
