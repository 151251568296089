import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../../routing/routing.service';
import { RouteChangeParams } from '../../../routing/route-change-params';
import { TokenService } from '../../../auth/token.service';
import { MessageType, OnlineService, StatusMessageInterface, TabSocket, UUIDHelper, UpdateService } from '@nts/std/utility';
import { ToastMessageService } from '../toast-message/toast-message.service';
import { ToastMessageType } from '../toast-message/toast-message';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, OnInit, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogService } from '@nts/std/utility';
import { UIStarter } from '../../../starter/ui-starter';
import { AuthService } from '../../../auth/auth.service';
import { BaseError } from '../../../messages/base-error';
import { UnauthorizedRequestComponent } from '../../errors/unauthorized-request/unauthorized-request.component';
import { ForbiddenComponent } from '../../errors/forbidden/forbidden.component';
import { InternalServerErrorComponent } from '../../errors/internal-server-error/internal-server-error.component';
import { NotFoundComponent } from '../../errors/not-found/not-found.component';
import { OfflineModeComponent } from '../../errors/offline-mode/offline-mode.component';
import { CompanyNotFoundComponent } from '../../errors/company-not-found/company-not-found.component';
import { EnterpriseNotFoundComponent } from '../../errors/enterprise-not-found/enterprise-not-found.component';
import { EnterpriseListNotFoundComponent } from '../../errors/enterprise-list-not-found/enterprise-list-not-found.component';
import { ClassConstructor } from '@nts/std/serialization';
import { ComponentErrorInterface } from '../../errors/component-error.interface';
import { TelemetryService } from '@nts/std/telemetry';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { CurrentRouteService } from '../../../routing/current-route.service';

export interface PendingChangesAwareInterface {
  handlePendingChanges(): Promise<boolean>;
}
@Component({
  template: '',
  standalone: true
})
export abstract class BaseContainerComponent implements AfterViewInit, OnInit, PendingChangesAwareInterface {

  isLoading$ = new BehaviorSubject<boolean>(true);
  inIframe = false;
  
  /**
   * utilizza il document placeholder al posto del router outlet, nel caso in cui è necessario renderizzare template custom ad esempio errori
   */
  usePlaceHolder = true;
  isOnline$: BehaviorSubject<boolean>;
  
  isDetached$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  /**
   * Sono presenti errori?
   */
  hasErrors = false;

  /**
   * placeholder utilizzato per renderizzare template custom
   */
  abstract documentPlaceHolder: ViewContainerRef;

  protected unloadHandlerEnabled = true;
  protected tabMessagesSocket: TabSocket = null;

  constructor(
    protected readonly routingService: RoutingService,
    protected readonly activatedRoute: ActivatedRoute,
    // Necessario per avviarlo e gesitre i token in tutte le maschere
    protected readonly tokenService: TokenService,
    protected readonly toastMessageService: ToastMessageService,
    protected readonly updateService: UpdateService,
    protected readonly onlineService: OnlineService,
    protected readonly authService: AuthService,
    protected readonly telemetryService: TelemetryService,
    protected readonly environmentConfiguration: EnvironmentConfiguration,
    protected readonly cd: ChangeDetectorRef,
    protected readonly componentFactoryResolver: ComponentFactoryResolver,
    protected readonly currentRouteService: CurrentRouteService
  ) {
    // Codice comune per tutti i container

    this.isOnline$ = onlineService.isOnline$;
  }

  ngOnAttach() {
    this.isDetached$.next(false);
  }

  ngOnDetach() {
    this.isDetached$.next(true);
  }

  ngOnInit(): void {

    // Codice comune per tutti i container
    this.inIframe = this.routingService.inIframe;

    if (this.inIframe) {
      this.tabMessagesSocket = new TabSocket(UIStarter.zone, window.opener || window.parent);
      this.handleTabMessageCommunication(this.tabMessagesSocket);
    }

    const isExternalReturn = window.location.href.includes('external-return=');
    if (isExternalReturn) {
      if (!this.tabMessagesSocket) {
        this.tabMessagesSocket = new TabSocket(UIStarter.zone, window.opener || window.parent);
        this.handleTabMessageCommunication(this.tabMessagesSocket);
      }
    }

    this.routingService.routeChangeRequested.pipe(this.untilDestroyed()).subscribe((routeChangeParams: RouteChangeParams) => {
      if (this.inIframe) {
        this.handleNavigationInsideIframe(routeChangeParams);
      } else {
        this.handleNavigationOutsideIframe(routeChangeParams);
      }
    });

    this.updateService.updateNotification.pipe(this.untilDestroyed()).subscribe((evt) => {
      this.handleUpdateNotification(evt);
    });

    this.routingService.onTabMessageReceived.pipe(this.untilDestroyed()).subscribe(async (payload: any) => {
      this.handleTabMessageReceived(payload);
    })
  }

  async checkToken(): Promise<boolean> {
    return await this.handleTokenIdParam();
  }

  async ngAfterViewInit(): Promise<void> {
    if (!await this.checkToken()) {
      return;
    }
    this.hasErrors = await this.checkEnterpriseError();
    this.usePlaceHolder = this.hasErrors;
    this.cd.detectChanges();
  }

  async checkEnterpriseError(): Promise<boolean> {
    if (this.currentRouteService.isAuthenticatedRoute) {
      const enterpriseDataError = await this.authService.getEnterpriseDataError();
      this.isLoading$.next(false);
      if (enterpriseDataError) {
        this.renderErrorComponentFromErrors([enterpriseDataError]);
        return true;
      }
    }
    return false;
  }

  async handleTokenIdParam(): Promise<boolean> {

    const tenantId = this.activatedRoute.parent.snapshot.queryParams[AuthService.TENANT_ID_QUERY_KEY];
    if (tenantId != null) {
      const accessToken = await this.authService.getAccessToken();
      const decoded = this.authService.decodeToken(accessToken);
      if (decoded.Tnt != tenantId) {
        await this.authService.changeTenant(tenantId)
        return false;
      }
    }
    return true;
  }

  track(error: BaseError) {
    const err = new Error(error.description);
    err.stack = error?.stackTrace;

    if (
      this.telemetryService.initialized && error.uuid && (
        error.code === 'DefaultError' || error.code === 'InternalServerError'
      )
    ) {
      this.telemetryService.trackException({
        id: error.uuid,
        exception: err,
        properties: {
          uuid: error.uuid
        }
      });
      if (this.environmentConfiguration.production) {
        LogService.error(err.toString());
      }
      LogService.error('Il tuo errore è stato tracciato, questo è il codice univoco generato per questo errore: ' + error?.uuid)
    } else {
      // TODO tracciare altre pagine di errore??
    }
  }

  getComponentErrorMapping() {
    return {
      UnauthorizedRequest: UnauthorizedRequestComponent,
      Forbidden: ForbiddenComponent,
      InternalServerError: InternalServerErrorComponent,
      NotFound: NotFoundComponent,
      DefaultError: InternalServerErrorComponent,
      OfflineMode: OfflineModeComponent,
      CompanyNotFound: CompanyNotFoundComponent,
      EnterpriseNotFound: EnterpriseNotFoundComponent,
      EnterpriseListNotFound: EnterpriseListNotFoundComponent,
    }
  }

  renderErrorComponentFromErrors(errors: BaseError[]) {

    if (this.documentPlaceHolder?.clear) {
      this.documentPlaceHolder?.clear();


      const defaultError: BaseError = new BaseError();
      defaultError.code = 'DefaultError';
      defaultError.description = 'Errore imprevisto';

      const computedErrors: BaseError[] = errors?.length > 0 ? errors : [defaultError];

      const componentErrorMapping = this.getComponentErrorMapping();

      let componentError: ClassConstructor<ComponentErrorInterface>;

      const forbiddenErrors = computedErrors.filter((e) =>
        e.code === 'Unauthorized' || e.description === 'Forbidden'
      );

      if (forbiddenErrors?.length > 0) {
        componentError = ForbiddenComponent;
      } else {
        // se non esiste il componente per gestire il codice di errore
        if (componentErrorMapping[computedErrors[0].code] == null) {
          // aggiungo il codice di default
          computedErrors.unshift(defaultError);
        }
        componentError = componentErrorMapping[computedErrors[0].code];
      }

      const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(componentError);
      const componentRef: ComponentRef<any> = this.documentPlaceHolder.createComponent(componentFactory);
      const componentInstance = componentRef.instance as ComponentErrorInterface;

      computedErrors[0].uuid = UUIDHelper.generateUUID();
      componentInstance.errors = computedErrors;
      this.track(computedErrors[0]);

      this.cd.detectChanges();

    }
    LogService.debug('renderErrorComponentFromErrors', errors)

  }

  // renderErrorComponentFromErrors(errors: BaseError[]) {

  //   this.documentPlaceHolder.clear();

  //   const componentErrorMapping = {
  //     UnauthorizedRequest: UnauthorizedRequestComponent,
  //     Forbidden: ForbiddenComponent,
  //     InternalServerError: InternalServerErrorComponent,
  //     NotFound: NotFoundComponent,
  //     DefaultError: InternalServerErrorComponent,
  //     OfflineMode: OfflineModeComponent,
  //     CompanyNotFound: CompanyNotFoundComponent,
  //     EnterpriseNotFound: EnterpriseNotFoundComponent,
  //     EnterpriseListNotFound: EnterpriseListNotFoundComponent,
  //   }

  //   const defaultError: BaseError = new BaseError();
  //   defaultError.code = 'DefaultError';
  //   defaultError.description = 'Errore imprevisto';

  //   let componentError: ClassConstructor<ComponentErrorInterface>;
  //   let componentFactory: ComponentFactory<any>;
  //   let componentTypeExists = componentErrorMapping[error.code] != null;
  //   if (!componentTypeExists) {
  //     // Try to find Forbidden error in description
  //     if (error?.description === 'Forbidden') {
  //       componentTypeExists = true;
  //       componentError = ForbiddenComponent;
  //     }
  //     componentError = componentErrorMapping[defaultError.code];
  //   } else {
  //     componentError = componentErrorMapping[error.code]
  //   }

  //   componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentError);

  //   const componentRef: ComponentRef<any> = this.documentPlaceHolder.createComponent(componentFactory);
  //   const componentInstance: ComponentErrorInterface = componentRef.instance as ComponentErrorInterface;
  //   error.uuid = UUIDHelper.generateUUID();
  //   this.track(error);
  //   componentInstance.error = error;

  //   LogService.debug('renderErrorComponentFromError', errors)

  //   this.cd.detectChanges();
  // }

  protected async handleTabMessageReceived(payload) {
    switch (payload.message?.type) {
      case MessageType.AskPendingChanges:

        // Disabilito l'unload handler
        this.unloadHandlerEnabled = false;
        const hasChanges = await this.handlePendingChanges();
        payload.action(hasChanges, MessageType.AnswerPendingChanges);
        break;
      case MessageType.AskStatus:

        // Disabilito l'unload handler
        this.unloadHandlerEnabled = false;
        const status = await this.checkStatus();
        payload.action(status, MessageType.AnswerStatus);
        break;
      default:
        break;
    }
  }

  protected handleTabMessageCommunication(socket: TabSocket) {
    socket.listen<string>((message) => {
      const commandMessageAction = (data: any, messageType = message.type) => {
        socket.send(data, messageType, message.uuid);
      };

      this.routingService.onTabMessageReceived.next({ message, action: commandMessageAction });
    });
  }

  protected async handleNavigationInsideIframe(routeChangeParams: RouteChangeParams) {
    // se sono in un iframe non esiste windows opener ma windows parent
    const socket = new TabSocket(UIStarter.zone, window.opener || window.parent, 'navigator');
    socket.send(routeChangeParams);
  }

  protected async handleNavigationOutsideIframe(routeChangeParams: RouteChangeParams) {
    // Potrebbe essere richiamato al posto della router navigate
  }

  protected async handleUpdateNotification(evt) {
    switch (evt.type) {
      case 'VERSION_DETECTED':
        LogService.debug(`Downloading new app version: ${evt.version.hash}`);
        break;
      case 'VERSION_READY':
        LogService.debug(`Current app version: ${evt.currentVersion.hash}`);
        LogService.debug(`New app version ready for use: ${evt.latestVersion.hash}`);
        setTimeout(() => {
          this.toastMessageService.showUpdateConfirmToast({
            title: 'Aggiornamento disponibile',
            message: 'E\' disponibile una nuova versione. Clicca sul pulsante Aggiorna per aggiornare la pagina.',
            type: ToastMessageType.info
          }, 'Aggiorna', 'refresh').pipe(this.untilDestroyed()).subscribe(_ => window.location.reload());
        });
        break;
      case 'VERSION_INSTALLATION_FAILED':
        LogService.debug(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
        break;
    }
  }

  protected abstract untilDestroyed(): <U>(source: Observable<U>) => Observable<U>;

  async handlePendingChanges(): Promise<boolean> {
    return false;
  }

  async checkStatus(): Promise<StatusMessageInterface> {
    return {
      hasModalOpen: false,
      pendingChanges: false,
      version: 1
    }
  }
}
