export const DateComponent = {
    propertyName: 'dateComponent',
    cellRenderer: false
};
export const HeaderComponent = {
    propertyName: 'headerComponent',
    cellRenderer: false
};
export const HeaderGroupComponent = {
    propertyName: 'headerGroupComponent',
    cellRenderer: false
};
export const CellRendererComponent = {
    propertyName: 'cellRenderer',
    cellRenderer: true
};
export const CellEditorComponent = {
    propertyName: 'cellEditor',
    cellRenderer: false
};
export const InnerRendererComponent = {
    propertyName: 'innerRenderer',
    cellRenderer: true
};
export const LoadingOverlayComponent = {
    propertyName: 'loadingOverlayComponent',
    cellRenderer: false
};
export const NoRowsOverlayComponent = {
    propertyName: 'noRowsOverlayComponent',
    cellRenderer: false
};
export const TooltipComponent = {
    propertyName: 'tooltipComponent',
    cellRenderer: false
};
export const FilterComponent = {
    propertyName: 'filter',
    cellRenderer: false
};
export const FloatingFilterComponent = {
    propertyName: 'floatingFilterComponent',
    cellRenderer: false
};
export const ToolPanelComponent = {
    propertyName: 'toolPanel',
    cellRenderer: false
};
export const StatusPanelComponent = {
    propertyName: 'statusPanel',
    cellRenderer: false
};
export const FullWidth = {
    propertyName: 'fullWidthCellRenderer',
    cellRenderer: true
};
export const FullWidthLoading = {
    propertyName: 'loadingCellRenderer',
    cellRenderer: true
};
export const FullWidthGroup = {
    propertyName: 'groupRowRenderer',
    cellRenderer: true
};
export const FullWidthDetail = {
    propertyName: 'detailCellRenderer',
    cellRenderer: true
};
export const MenuItemComponent = {
    propertyName: 'menuItem',
    cellRenderer: false
};
