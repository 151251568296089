import { CoreModel } from '../../domain-models/core-model';
import { BaseIdentity } from '../../domain-models/base-identity';
import { BaseLongOpModel } from '../../domain-models/base-long-op-model';
import { LongOpApiClient } from './long-op-api-client';
import { WebApiServiceAgent } from '../web-api-service-agent';
import { Observable } from 'rxjs';
import { GenericServiceResponse } from '../../responses/generic-service-response';
import { DraftScheduleInfoDto } from '../../schedule/dto/draft-schedule-info.dto';
import { BaseScheduleLongOpHttpCommunication } from './base-schedule-long-op-http-communication';
import { OnlineService } from '@nts/std/utility';

export class BaseScheduleLongOpApiClient<TBaseLongOpModel extends BaseLongOpModel<TIdentity, TParams, TResult>,
    TIdentity extends BaseIdentity,
    TParams extends CoreModel<TIdentity>,
    TResult extends CoreModel<TIdentity>> extends LongOpApiClient<TBaseLongOpModel, TIdentity, TParams, TResult> {

        constructor(
            _agent: WebApiServiceAgent,
            _onlineService: OnlineService
        ) {
    
            super(_agent, _onlineService);
        }

        createDraftSchedule(model: TBaseLongOpModel): Observable<GenericServiceResponse<DraftScheduleInfoDto>> {
            const communication = this.createBaseScheduleLongOpHttpCommunication();
            return communication.createDraftSchedule(model);
        }
    
        protected createBaseScheduleLongOpHttpCommunication(): BaseScheduleLongOpHttpCommunication<TBaseLongOpModel, TIdentity, TParams, TResult> {
            return new BaseScheduleLongOpHttpCommunication<TBaseLongOpModel, TIdentity, TParams, TResult>(
                this.agent, this.longOpModelType, this.longOpResultType);
        }
}

