import { ToastMessageService } from './../components/layout/toast-message/toast-message.service';
import { ReplaySubject, Observable, Subject, interval, of, firstValueFrom, BehaviorSubject } from 'rxjs';
import { BaseIdentity } from '../domain-models/base-identity';
import { MetaDataResponse } from '../responses/meta-data-response';
import { ServiceResponse } from '../responses/service-response';
import { BaseViewModel } from './base-view-model';
import { BaseViewModelInterface } from './base-view-model.interface';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { RootViewModel } from './root-view-model';
import { UnchangedViewModelState } from './states/unchanged-view-model-state';
import { ViewModelState } from './states/view-model-state';
import { ViewModelStates } from './states/view-model-states';
import { ViewModelEventDispatcher, MessageInViewModelInterface, ActionInProgressInterface } from './view-model-event-dispatcher';
import { ViewModelFactory } from './view-model-factory';
import { CoreToolBarViewModelInterface } from './core-tool-bar-view-model.interface';
import { CommandTypes } from './commands/command-types';
import { BaseError } from '../messages/base-error';
import { Information } from '../messages/information';
import { SourceMessage, MessageContainer } from './message-container';
import { BaseMessage } from '../messages/base-message';
import { ModelInterface } from '../domain-models/model.interface';
import { GetByIdentityRequest } from '../requests/get-by-identity-request';
import { FindValuesResponse } from '../responses/find-values-response';
import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { DomainModelMetaData, InternalRelationMetaData, InternalCollectionMetaData, ExternalMetaData } from '../meta-data/domain-model-meta-data';
import { ColumnInfoCollection } from './column-info-collection';
import { ColumnsUtils } from './columns_utils';
import { ExternalColumnMapInfo } from './external_column_map_info';
import { ColumnInfo } from './column-info';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { MessageCodes } from '../resources/message-codes';
import { CodeValueMessageArg } from '../resources/code-value-message-arg';
import { MessageButton } from './modal/message-button';
import { ModalService } from './modal/modal.service';
import { MessageResult } from './modal/message-result';
import { ModalResult } from './modal/modal-result';
import { AuthService } from '../auth/auth.service';
import { CoreModel } from '../domain-models/core-model';
import { ExternalReaderApiClient } from '../api-clients/external-reader-api-client';
import { RootViewModelTypeInspector } from '../decorators/root-view-model-type.decorator';
import { IdentityTypeInspector } from '../decorators/identity-type.decorator';
import { PresentationCache } from '../cache/presentation-cache';
import { UIStarter } from '../starter/ui-starter';
import { ViewModelLocator } from './view-model-locator';
import { RootModelTypeNameInspector } from '../api-clients/decorators/root-model-type-name.decorator';
import { WingViewModelInterface } from './wing-view-model.interface';
import { AutoCompleteExternalOptions } from '../domain-models/autocomplete/auto-complete-external-options';
import { getMetadataStorage, MetadataStorage, registerDecorator, ValidationTypes } from 'class-validator';
import { StringValidator, StringDecoratorInterface } from '../domain-models/decorators/string.decorator';
import { BaseValidator } from '../domain-models/decorators/commons/base-validator';
import { StringMetaData } from '../meta-data/string-meta-data';
import { NumericMetaData } from '../meta-data/numeric-meta-data';
import { NumberDecoratorInterface, NumberValidator } from '../domain-models/decorators/number.decorator';
import { BoolMetaData } from '../meta-data/bool-meta-data';
import { AttachmentIdentity, BoolDecoratorInterface, BoolValidator, DateDecoratorInterface, DateValidator, EnumDecorator, EnumDecoratorInterface, EnumValidator, ExternalDecoratorInterface, ExternalValidator, IgnoreAutomaticDecoratorWarningInspector, InternalDecoratorInterface, InternalValidator, ModelTypeInspector, OperationIdentity, OperationState, OperationStateResultDto, PrintOutDemandDto, ReportInfoDto, RequiredValidation, SpoolProcess, TimeSpanDecoratorInterface, TimeSpanValidator, UIInfo } from '../domain-models';
import { EnumMetaData } from '../meta-data/enum-meta-data';
import { GenericsPropertiesTypeInspector, GenericsPropertiesTypeInterface } from '../decorators/generics-properties-type.decorator';
import { DateTimeMetaData } from '../meta-data/date-time-meta-data';
import { ClassInformationInterface, ClassInformationType, ClassInformationUtility, LocalstorageHelper, LogService, OnlineService, StatusMessageInterface } from '@nts/std/utility';
import { TypeMetadata } from '@nts/std/serialization';
import { defaultMetadataStorage } from '@nts/std/serialization';
import { MetaDataUtils } from '../meta-data/meta-data-utils';
import { ClassConstructor } from '@nts/std/serialization';
import { classToPlain, plainToClass, TypeHelpOptions } from '@nts/std/serialization';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { catchError, filter, map, share, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { UICommandInterface } from './commands/ui-command.interface';
import { ToastMessageType } from '../components/layout/toast-message/toast-message';
import { LayoutMetaData } from '../layout-meta-data/layout-meta-data';
import { GenericServiceResponse } from '../responses';
import { AvailableLayoutsInfoDto } from '../domain-models/layout/available-layouts-info.dto';
import { AvailableLayoutDto } from '../domain-models/layout/available-layout-dto';
import { UICommandSettingsManager } from './commands/ui-command-settings-manager';
import { CommandFactory } from './commands/command-factory';
import { LayoutDefinitionIdentity } from '../domain-models/layout/layout-definition.identity';
import { UserLayoutMetaData } from '../layout-meta-data';
import { RootViewModelInterface } from './root-view-model.interface';
import { GridUserLayoutDataDto } from '../domain-models/layout/grid-user-layout-data.dto';
import { GridUserLayoutIdentityDto } from '../domain-models/dto/gird-user-layout.dto';
import { BaseLayoutDataDto } from '../domain-models/layout/base-layout-data.dto';
import { Params } from '@angular/router';
import { FindValuesOptions } from '../domain-models/find-options/find-values-options';
import { TimeSpanMetaData } from '../meta-data/time-span-meta-data';
import { ViewModelInterface } from './view-model.interface';
import { PropertyViewModel } from './property-view-model';
import { CollectionViewModel } from './collection-view-model';
import { PanelUserLayoutDataDto } from '../domain-models/layout/panel-user-layout-data.dto';
import { ValidationMetadata } from 'class-validator/cjs/metadata/ValidationMetadata';
import { CustomIconInterface } from './custom-icons.interface';
import { TelemetryService } from '@nts/std/telemetry';
import { VERSION } from '@angular/core';

export enum MsgClearMode {
    ClearAllMessages,
    ClearOnlyTemporaryMessage,
    NoClear
}

export abstract class CoreOrchestratorViewModel<
    TViewModel extends RootViewModel<TModel, TIdentity>,
    TApiClient extends ExternalReaderApiClient<TModel, TIdentity>,
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity> extends BaseViewModel implements CoreOrchestratorViewModelInterface, ClassInformationInterface {

    showPageHeader: boolean = true;
    showToolBar: boolean = true;

    classType: ClassInformationType = ClassInformationType.OrchestratorViewModel;

    /**
     * Gestisce la visualizzazione dello stato corrente
     */
    showCurrentState: boolean = true;

    /**
     * Ogni volta che il root view model viene modificato viene lanciato questo evento
     */
    rootViewModelModified: ReplaySubject<void> = new ReplaySubject();

    /**
     * Ogni volta che il root view model viene modificato dal momento della sottoscrizione viene lanciato questo evento
     */
    rootViewModelModifiedFromNow: Subject<void> = new Subject();

    /**
     * Ogni volta che il root view model viene sostituito viene lanciato questo evento
     */
    rootViewModelChanged: ReplaySubject<void> = new ReplaySubject();

    /**
     * Ogni volta che il root view model viene sostituito dal momento della sottoscrizione viene lanciato questo evento
     */
    rootViewModelChangedFromNow: Subject<void> = new Subject();

    /**
     * Ogni volta che lo stato corrente cambia, viene lanciato questo evento
     */
    currentStateChanged: ReplaySubject<void> = new ReplaySubject();

    /**
     * Emette un evento per richiedere il cambio del layout
     */
    changeLayoutRequested: Subject<LayoutDefinitionIdentity> = new Subject<LayoutDefinitionIdentity>();

    /**
     * Emette un evento per richiedere il refresh della pagina dopo il cambio della security
     */
    refreshPageAfterSecurityChange: Subject<void> = new Subject<void>();

    /**
     * Emette un evento per richiedere il reset del layout
     */
    resetLayoutRequested: Subject<LayoutDefinitionIdentity> = new Subject<LayoutDefinitionIdentity>();

    /**
     * Lista dei query sring passati alla maschera
     */
    queryParams: Params;

    /**
     * Lista classi custom aggiunte nel container principale della maschera
     */
    customClasses: string[] = [];

    /**
     * meta data di layout generato partendo dal template
     */
    templateLayoutMetaData: LayoutMetaData = new LayoutMetaData();

    /**
     * Verificare questa variabile per capire se in questo momento ci sono rebuild in corso del rootViewModel
     */
    rebuildRootViewModelInProgress$ = new BehaviorSubject<boolean>(false);

    domainModel: TModel;
    documentEnabled: boolean = true;
    allViewModelErrorsBuildedList: MessageContainer[] = [];
    allViewModelInformationsBuildedList: MessageContainer[] = [];
    spoolProcessList = [];
    spoolProcessCompleted: Subject<SpoolProcess<any>> = new Subject<SpoolProcess<any>>();
    spoolProcessStarted: Subject<SpoolProcess<any>> = new Subject<SpoolProcess<any>>();
    spoolProcessError: Subject<SpoolProcess<any>> = new Subject<SpoolProcess<any>>();
    moreOptionsMenuItemUpdated: Subject<void> = new Subject();
    mobileMenuItemUpdated: Subject<void> = new Subject();
    openSpoolProcessRequested: Subject<OperationIdentity> = new Subject<OperationIdentity>();
    openLongOpReportRequested: Subject<ReportInfoDto> = new Subject<ReportInfoDto>();
    apiClient: TApiClient;

    isDetached$: BehaviorSubject<boolean>;
    askPendingChangesCanceled: Subject<void> = new Subject();
    pendingChangesEnded: Subject<void> = new Subject();
    pendingChangesStarted: Subject<void> = new Subject();
    pendingAutocompleteEnded: Subject<void> = new Subject();
    pendingAutocompleteStarted: Subject<void> = new Subject();
    customIcon$: Subject<CustomIconInterface> = new Subject();

    isModalOpen: boolean = false;

    override get hasErrors(): boolean {
        return this.rootViewModel != null ? this.rootViewModel.hasErrors : false;
    }

    get title(): string {
        return this.getTitle();
    }

    get navigationPanelCollapsed(): boolean {
        return this._navigationPanelCollapsed;
    }

    /**
     * meta data di layout specifica per utente, è definita solo se siamo nel template standard
     */
    get userLayoutMetaData(): UserLayoutMetaData {
        return this._userLayoutMetaData;
    }

    get isDeactivable(): boolean {
        return this._isDeactivable;
    }

    get externalReturnMode(): boolean {
        return this._externalReturnMode;
    }

    // Ritorna il layoutmetadata corrente indipendentemente se è selezionato uno personalizzato o standard
    get currentLayoutMetaData(): LayoutMetaData {
        return this.layoutMetaData ? this.layoutMetaData : this.templateLayoutMetaData;
    }

    /**
     * Layout corrente indipententemente se è standard o personalizzato
     */
    get currentLayout(): AvailableLayoutDto {
        return this._currentLayout;
    }

    get layoutDefaultValueList(): Map<string, string> {
        return this._layoutDefaultValueList;
    }

    get useMessageResourceKey(): boolean {
        return this._useMessageResourceKey;
    }

    get startedAsRelatedClient(): boolean {
        return this._startedAsRelatedClient;
    }

    get toolBarViewModel(): CoreToolBarViewModelInterface {
        return this._toolBarViewModel;
    }

    get wingViewModel(): WingViewModelInterface {
        return this._wingViewModel;
    }

    get viewModel(): BaseViewModelInterface {
        return this._rootViewModel;
    }

    get currentStateDescription(): string {
        return MessageResourceManager.Current.getMessage('std_' + ViewModelStates[this.currentState.value]);
    }

    get customGridColumns(): Map<string, ColumnInfoCollection> {
        return this._customGridColumns;
    }

    get gridColumns(): Map<string, ColumnInfoCollection> {
        return this._gridColumns;
    }

    get actionInProgress(): boolean {
        return this._actionInProgress;
    }

    get rootViewModelType(): ClassConstructor<TViewModel> {
        return this._rootViewModelType;
    }

    get identityType(): ClassConstructor<TIdentity> {
        return this._identityType;
    }

    get rootViewModel(): TViewModel {
        return this._rootViewModel;
    }

    get typedRootViewModel(): TViewModel {
        return this._rootViewModel;
    }

    get metadata(): AggregateMetaData {
        return this._metadata;
    }

    get layoutMetaData(): LayoutMetaData {
        return this._layoutMetaData;
    }

    get layoutList(): AvailableLayoutDto[] {
        return this._layoutList;
    }

    get eventDispatcher(): ViewModelEventDispatcher {
        return this._eventDispatcher;
    }
    set eventDispatcher(value: ViewModelEventDispatcher) {
        if (this._eventDispatcher != null) {
            // TODO Tommy: unsubscribe dei vecchi observable (verificare)
            this._eventDispatcher.onActionInProgress.complete();
        }
        this._eventDispatcher = value;
    }

    get currentState(): ViewModelState {
        return this._currentState;
    }
    set currentState(newViewModelState: ViewModelState) {
        const currentState = this._currentState;
        this._currentState = newViewModelState;
        if (!currentState || currentState.value !== newViewModelState.value) {
            this.currentStateChanged.next();
            if (this.rootViewModel?.currentStateChanged) {
                this.rootViewModel?.currentStateChanged.next();
            }
        }
    }

    protected _metadata: AggregateMetaData;

    /**
     * Metadati per la personalizzazione con pannelli
     */
    protected _layoutMetaData: LayoutMetaData;

    private _actionInProgress: boolean;
    private _wingViewModel: WingViewModelInterface;
    private _navigationPanelCollapsed: boolean;
    private _userLayoutMetaData: UserLayoutMetaData;
    private _allViewModelMessagesMap = new Map<string, BaseViewModelInterface>();
    private _toolBarViewModel: CoreToolBarViewModelInterface;
    private _isDeactivable: boolean = undefined;
    private _externalReturnMode: boolean = false;
    private _layoutDefaultValueList: Map<string, string> = new Map<string, string>();
    private _useMessageResourceKey: boolean = true;
    private _startedAsRelatedClient = false;
    private _identityType: ClassConstructor<TIdentity>;
    private _rootViewModelType: ClassConstructor<TViewModel>;
    private _rootViewModel: TViewModel;
    private _currentState: ViewModelState;
    private _selectedLayoutCode: string = null;
    private _eventDispatcher = new ViewModelEventDispatcher();
    private _layoutList: AvailableLayoutDto[];
    private _currentLayout: AvailableLayoutDto;
    private _pendingChangesSubscription;
    private _pendingChanges = false;
    private _gridColumns: Map<string, ColumnInfoCollection>;
    private _customGridColumns: Map<string, ColumnInfoCollection>;
    private _pendingAutocompleteSubscription;
    private _pendingAutocomplete = false;

    constructor(
        apiClient: TApiClient,
        public modalService: ModalService,
        public env: EnvironmentConfiguration,
        public authService: AuthService,
        public toastMessageService: ToastMessageService,
        public onlineService: OnlineService,
    ) {
        super();

        modalService.ovm = this as CoreOrchestratorViewModelInterface;

        this._rootViewModelType = RootViewModelTypeInspector.getValue(this);

        if (this._rootViewModelType === undefined) {
            throw new Error(
                `MetaData ${RootViewModelTypeInspector.META_DATA_KEY} not defined. You must use ${RootViewModelTypeInspector.DECORATOR_NAME}} in ${this.constructor.name}.`
            );
        }

        this._identityType = IdentityTypeInspector.getValue(this);

        if (this._identityType === undefined) {
            throw new Error(
                `MetaData ${IdentityTypeInspector.META_DATA_KEY} not defined. You must use ${IdentityTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this.apiClient = apiClient;

        const rootModelName: string = RootModelTypeNameInspector.getValue(this.apiClient);
        const orchestratorViewModelType: any = ViewModelLocator.getOrchestratorViewModelType(rootModelName);
        const wingViewModelType: any = ViewModelLocator.getWingVieModelType(orchestratorViewModelType);

        if (wingViewModelType !== undefined) {
            this._wingViewModel = new wingViewModelType(this);
        }

        this.currentState = new UnchangedViewModelState(this);

        this._eventDispatcher.onActionInProgress.subscribe((actionInProgress: boolean | ActionInProgressInterface) => {
            if ((actionInProgress as ActionInProgressInterface).inProgress === true || (actionInProgress as ActionInProgressInterface).inProgress === false) {
                this._actionInProgress = (actionInProgress as ActionInProgressInterface).inProgress;
            } else {
                this._actionInProgress = (actionInProgress as boolean);
            }
        });

        this._eventDispatcher.onAddMessageInViewModel.subscribe((onMessageInViewModel: MessageInViewModelInterface) => {
            this._allViewModelMessagesMap.set(
                onMessageInViewModel.viewModel.uniqueId,
                onMessageInViewModel.viewModel);
            this.rebuildMessageList();
        });

        this._eventDispatcher.onClearMessagesInViewModel.subscribe((viewModel: BaseViewModel) => {
            this._allViewModelMessagesMap.delete(viewModel.uniqueId);
            this.rebuildMessageList();
        });

        this._eventDispatcher.onRemovedMessageInViewModel.subscribe(() => {
            this.rebuildMessageList();
        });

        this._eventDispatcher.onClearAllMessages.subscribe(() => {
            this._allViewModelMessagesMap.clear();
            this.rebuildMessageList();
        });

        this._eventDispatcher.externalModalExecuted.subscribe(() => {
            this.documentEnabled = false;
            this._eventDispatcher.onActionInProgress.next(true);
        });

        this._eventDispatcher.externalModalReturned.subscribe(() => {

            window.focus();
            window.blur();

            this.documentEnabled = true;
            this._eventDispatcher.onActionInProgress.next(false);
        });

        this._eventDispatcher.externalModalReturned.subscribe(() => {

            window.focus();
            window.blur();

            this.documentEnabled = true;
            this._eventDispatcher.onActionInProgress.next(false);
        });

        this._toolBarViewModel = this.getToolBarMenu();
    }

    abstract getToolBarMenu(): CoreToolBarViewModelInterface;

    override clearErrors() {
        this.removeAllMessages();
    }

    setExternalReturnMode(isExternalReturnMode: boolean): void {
        this._externalReturnMode = isExternalReturnMode;
    }

    getExternalColumns(entityTypeName: string, externalColumnsMapInfo: ExternalColumnMapInfo[]): Array<ColumnInfo> {
        return ColumnsUtils.getExternalColumns(this.metadata, entityTypeName, externalColumnsMapInfo);
    }

    setStartedAsRelatedClient() {
        this._startedAsRelatedClient = true;
    }

    getCurrentIdentity(): string {
        if (this.domainModel !== undefined) {
            const plain: Record<string, any> = classToPlain(this.domainModel.currentIdentity, { strategy: 'excludeAll' });
            return JSON.stringify(plain);
        } else {
            return undefined;
        }
    }

    async openGridSettings(path: string, name: string, dto: GridUserLayoutDataDto): Promise<UserLayoutMetaData> {

        if (this.actionInProgress == false) {
            this.eventDispatcher.onActionInProgress.next(true);
        }
        let jsonIdentity: string = '';
        let url: string = '';
        let result = null;

        // TODO fino a quando la setGridUserLayoutData non ritorna l'url sono obbligato a chiamare anche la presentation cache
        const userLayoutDataRootFullName = 'LayoutManager.UserLayoutDataObjects.Models.UserLayoutData';
        await PresentationCache.addIfNotExist(userLayoutDataRootFullName);
        url = PresentationCache.get(userLayoutDataRootFullName);

        if (dto) {
            dto.layoutIdentity =
                this.layoutMetaData ? // Se ho un selezionato un template personalizzato
                    this.currentLayout.identity :    // Gli passo l'identity del layout
                    null            // Sono nello standard

            const response = await firstValueFrom(this.apiClient.setGridUserLayoutDataAsync(dto));

            if (!response.operationSuccedeed) {
                this.eventDispatcher.onActionInProgress.next(false);
                this.showFromResponse(response, MsgClearMode.ClearAllMessages);
                return result;
            }
        }

        const identity = new GridUserLayoutIdentityDto();
        identity.serviceFullName = this.rootViewModel.aggregateMetaData.rootFullName;
        identity.gridFullPathName = path?.length > 0 ? path + '.' + name : name;

        // Layout custom, devo passare anche il layout identity
        if (this.layoutMetaData) {
            identity.layoutIdentity = this.currentLayout.identity;
        }

        const plainIdentity = classToPlain(identity, { strategy: 'excludeAll' });
        jsonIdentity = JSON.stringify(plainIdentity);
        let modalResponse = new ModalResult<{ operationSuccedeed: boolean, forcePageRefresh: boolean }>({
            operationSuccedeed: false,
            forcePageRefresh: false
        }, true);

        if (url?.length > 0 && jsonIdentity?.length > 0) {
            this.eventDispatcher.externalModalExecuted.emit();
            modalResponse = await this.showExternalModalWithResultAsync<{ operationSuccedeed: boolean, forcePageRefresh: false }>(
                url.toLowerCase(),
                jsonIdentity,
                null,
                MessageResourceManager.Current.getMessage('std_GridSettingsWindow_DisplayName')
            );
        } else {
            LogService.warn('url o jsonIdentity non validi!')
        }

        if (modalResponse.result?.forcePageRefresh === true) {
            window.location.reload();
            return null;
        }

        const baseLayoutData = new BaseLayoutDataDto();
        if (this.layoutMetaData) {
            baseLayoutData.layoutIdentity = this.currentLayout.identity;
        }

        if (modalResponse.result?.operationSuccedeed === true) {
            const newUserLayoutResponse = await firstValueFrom(this.apiClient.getUserLayoutMetaDataAsync(baseLayoutData));
            if (newUserLayoutResponse.operationSuccedeed && newUserLayoutResponse.result) {
                result = newUserLayoutResponse.result;
            }
        }

        if (url?.length > 0 && jsonIdentity?.length > 0) {
            this.eventDispatcher.externalModalReturned.emit();
        }
        this.eventDispatcher.onActionInProgress.next(false);

        return result;
    }

    async resetLayout(identity: LayoutDefinitionIdentity): Promise<void> {
        this.resetLayoutRequested.next(identity);
    }

    async customizeSecurity(): Promise<void> {
        if (this.actionInProgress == false) {
            this.eventDispatcher.onActionInProgress.next(true);
        }

        const securityRootFullName = 'SecurityManager.SecurityDataObjects.Models.SecurityData';
        await PresentationCache.addIfNotExist(securityRootFullName);
        const url = PresentationCache.get(securityRootFullName);
        if (url?.length > 0) {

            this.eventDispatcher.externalModalExecuted.emit();
            const additionalQueryParams = new URLSearchParams();
            additionalQueryParams.append('modelFullName', this.metadata.rootFullName)
            const result = await this.showExternalModalWithResultAsync<string>(
                url.toLowerCase(),
                null,
                additionalQueryParams,
                MessageResourceManager.Current.getMessage('std_SecurityWindow_DisplayName'),
                false
            );

            if (result.cancel) {
                this.eventDispatcher.externalModalReturned.emit();
            } else {
                this.refreshPageAfterSecurityChange.next();
            }
        }

        this.eventDispatcher.onActionInProgress.next(false);

    }

    async customizeLayouts(): Promise<void> {

        if (this.actionInProgress == false) {
            this.eventDispatcher.onActionInProgress.next(true);
        }
        let jsonIdentity: string = '';
        let url: string = '';

        // Passa il layout corrente

        if (!this.layoutMetaData) {
            // Se ho selezionato lo standard, devo creare un draft
            const responseDraftLayout = await firstValueFrom(this.apiClient.createDraftLayout(this.templateLayoutMetaData))

            if (responseDraftLayout.operationSuccedeed && responseDraftLayout.result?.identity && responseDraftLayout.result?.uiInfo?.fullAddress?.length > 0) {
                const plainIdentity = classToPlain(responseDraftLayout.result?.identity, { strategy: 'excludeAll' });
                jsonIdentity = JSON.stringify(plainIdentity);
                url = responseDraftLayout.result.uiInfo.fullAddress;
            } else {
                this.showFromResponse(responseDraftLayout, MsgClearMode.ClearAllMessages);
            }

        } else {

            const layoutDefinitionRootFullName = 'LayoutManager.LayoutDefinitionObjects.Models.LayoutDefinition';
            await PresentationCache.addIfNotExist(layoutDefinitionRootFullName);
            url = PresentationCache.get(layoutDefinitionRootFullName);
            if (url?.length > 0) {
                const plainIdentity = classToPlain(this.currentLayout.identity, { strategy: 'excludeAll' });
                jsonIdentity = JSON.stringify(plainIdentity);
            }
        }

        if (url?.length > 0 && jsonIdentity?.length > 0) {
            this.eventDispatcher.externalModalExecuted.emit();
            const result: ModalResult<string> = await this.showExternalModalWithResultAsync<string>(
                url.toLowerCase(),
                jsonIdentity,
                null,
                MessageResourceManager.Current.getMessage('std_CustomizeLayoutsWindow_DisplayName')
            );

            if (result.cancel) {
                this.eventDispatcher.externalModalReturned.emit();
            } else {
                const jsonObject = result.result;
                const identity = plainToClass<LayoutDefinitionIdentity, Object>(
                    LayoutDefinitionIdentity, JSON.parse(jsonObject) as Object);

                this.changeLayoutRequested.next(identity);
            }
        } else {
            LogService.warn('url o jsonIdentity non validi!')
        }

        this.eventDispatcher.onActionInProgress.next(false);
    }

    async changeLayout(identity: LayoutDefinitionIdentity): Promise<void> {
        this.changeLayoutRequested.next(identity);
    }

    async checkIfPendingChanges(): Promise<boolean> {
        return this.currentState.value === ViewModelStates.Modified ||
            this.currentState.value === ViewModelStates.NewModified || this.isModalOpen;
    }

    async checkStatus(): Promise<StatusMessageInterface> {
        return {
            hasModalOpen: this.isModalOpen,
            pendingChanges: await this.checkIfPendingChanges(),
            version: 1
        }
    }

    checkIfCanUnload(): boolean {
        return this.currentState.value !== ViewModelStates.Modified &&
            this.currentState.value !== ViewModelStates.NewModified;
    }

    async confirmLosingUnsavedDataAsync(): Promise<boolean> {
        if (await this.checkIfPendingChanges()) {
            if (this.isModalOpen) {
                return false;
            }

            const args: Array<CodeValueMessageArg> = [];
            const arg: CodeValueMessageArg = new CodeValueMessageArg();
            arg.code = 'NEWLINE';
            arg.value = '<br>';
            args.push(arg);
            const confirmModalMessage = MessageResourceManager.Current.getMessageWithArgs(MessageCodes.UnsavedData, args);

            const warningMessage = MessageResourceManager.Current.getMessage(MessageCodes.Warning);
            const result = await this.modalService.showMessageAsync(warningMessage, confirmModalMessage, MessageButton.YesNo);

            if (result !== MessageResult.Yes) {
                this.askPendingChangesCanceled.next()
            }
            return result === MessageResult.Yes;
        } else {
            return true;
        }
    }

    getApiClient(): TApiClient {
        return this.apiClient;
    }

    notifyModified() {
        this.rootViewModelModified.next();
        this.rootViewModelModifiedFromNow.next();
        this.currentState.modify();
    }

    async showExternalModalWithResultAsync<TResult>(
        url: string,
        jsonIdentity: string,
        additionalQueryParams = new URLSearchParams(),
        modalTitle?: string,
        externalReturn = true,
        
        /**
         * Supporto dello stato dei pending changes remoto
         */
        supportRemoteClosingCheck = false,
        customExternalModalViewModel = null,
        
        /**
         * Supporto dello stato della maschera remoto:
         * - pending changes
         * - has modal open
         */
        supportRemoteStatus = false,
    ): Promise<ModalResult<TResult>> {
        return this.modalService.showExternalModal(
            url,
            jsonIdentity,
            additionalQueryParams,
            modalTitle,
            externalReturn,
            supportRemoteClosingCheck,
            customExternalModalViewModel,
            supportRemoteStatus
        );
    }

    showFromErrors(errors: Array<BaseError>) {
        this.refreshMessages(errors, null, MsgClearMode.NoClear);
    }

    showFromResponse(response: ServiceResponse, clearMode: MsgClearMode) {
        this.refreshMessagesFromResponse(response, clearMode);
    }

    showFromStrings(messages: Array<string>, clearMode: MsgClearMode) {
        this.refreshMessagesFromStrings(messages, clearMode);
    }

    showFromMessages(messages: Array<BaseMessage>, clearMode: MsgClearMode) {
        this.refreshMessagesFromBaseMessages(messages, clearMode);
    }

    removeMessageInViewModel(message: MessageContainer) {
        if (message.uniqueId) {
            const { messageContainerCollection } = this._allViewModelMessagesMap.get(message.uniqueId);
            messageContainerCollection.splice(messageContainerCollection.indexOf(message), 1);
            this.eventDispatcher.onRemovedMessageInViewModel.next(null);
        } else {
            LogService.warn(`Impossibile rimuovere il messaggio, manca l'informazione dell'uniqueId`, message, this._allViewModelMessagesMap)
        }
    }

    focusMessageInViewModel(message: MessageContainer) {
        if (message.uniqueId) {
            const vm = this._allViewModelMessagesMap.get(message.uniqueId) as ViewModelInterface;

            if (vm instanceof PropertyViewModel) {
                const pathWithIndex = vm.propertyPathWithIndex;

                // Se ho delle collection parent, devo preselezionare la riga dove ho l'errore
                this.selectParentCollectionsByPropertyPath(pathWithIndex);
                setTimeout(() => {
                    if (vm.isVisible) {
                        vm.onFocusRequested.next();
                    }
                }, 250)
            } else {
                vm.onFocusRequested.next();
            }
        } else {
            LogService.warn(`Impossibile effettuare il focus sul messaggio, manca l'informazione dell'uniqueId`, message, this._allViewModelMessagesMap);
        }
    }

    protected selectParentCollectionsByPropertyPath(pathWithIndex: string) {

        if (pathWithIndex?.length > 0) {
            const splittedCalculatedPath = pathWithIndex.split('.');

            let baseViewModel = this.rootViewModel;

            for (const key in splittedCalculatedPath) {

                baseViewModel = baseViewModel[splittedCalculatedPath[key]];

                if (baseViewModel.parent instanceof CollectionViewModel) {
                    baseViewModel.parent.selection = [baseViewModel];
                }
            }
        }
    }

    /**
     * Imposta il layout di partenza
     * Viene impostato solo se passato nel query string
     *
     * @param layoutCode     codice del layout di partenza
     */
    setStartingLayoutCode(layoutCode: string) {
        this._selectedLayoutCode = layoutCode;
    }

    createMockDomainModel<TEntity extends ModelInterface>(entityClass: ClassConstructor<TEntity>, metadata: AggregateMetaData): TEntity {
        const newEntity = new entityClass() as ModelInterface;
        this.recursiveMockDomainModel(metadata, metadata.rootMetaData, newEntity);
        return newEntity as TEntity;
    }

    async initialize(): Promise<void> {
        this.eventDispatcher.onInitializing.next();
        this.eventDispatcher.onNavigationPanelCollapsed.pipe(takeUntil(this.destroySubscribers$)).subscribe((isCollpased) => {
            this._navigationPanelCollapsed = isCollpased;
        })
        await this.initValidators();
    }

    async openLongOpReport(reportInfo: ReportInfoDto): Promise<void> {
        this.openLongOpReportRequested.next(reportInfo);
    }

    async initValidators(): Promise<void> {
        const rootDomainModelType = this.apiClient.rootModelType;
        this.recursiveProcessValidators(this.metadata.rootMetaData, rootDomainModelType, [], null);
    }

    async postInitMetaData(metaData: MetaDataResponse): Promise<MetaDataResponse> {
        return metaData;
    }

    async initMetaData(): Promise<MetaDataResponse> {
        const useMessageResourceKey = true;
        let response = new MetaDataResponse();
        if (!this._metadata) {
            response = await firstValueFrom(this.apiClient.getMetaDataAsync(!useMessageResourceKey).pipe(share()));
            this._metadata = response.result;
        } else {
            response.result = this._metadata;
        }
        this._isDeactivable = this._metadata?.isDeactivable;
        this._useMessageResourceKey = useMessageResourceKey;

        if (response.result) {
            response.result.useMessageResourceKey = useMessageResourceKey;
        }

        this.eventDispatcher.onMetaDataLoaded.next(true);
        return response;
    }

    canSupportOfflineMode(): Observable<boolean> {
        return of(false);
    }

    /**
     * Inizializza il layout meta data per l'utente corrente
     */
    async initUserLayoutMetaData(rootFullName: string): Promise<void> {

        // Verifico se l'utente ha i permessi per avere il modulo del user layout
        const canAccessToUserLayout = await firstValueFrom(this.canAccessToUserLayout());

        if (canAccessToUserLayout) {
            // Recupero le informazioni dal ms user layout
            const baseLayoutData = new BaseLayoutDataDto();
            let response = await firstValueFrom(this.apiClient.getUserLayoutMetaDataAsync(baseLayoutData));
            if (response.operationSuccedeed && response.result) {
                this._userLayoutMetaData = response.result;
            }
        } else {
            // Recupero le informazioni dal local storage
            this._userLayoutMetaData = await this.readUserLayoutFromLocalStorage(rootFullName);
        }
    }

    private async readUserLayoutFromLocalStorage(rootFullName: string): Promise<UserLayoutMetaData> {
        const userLayout = await LocalstorageHelper.getStorageItem(await this.getUserLayoutLocalStorageKey());
        if (userLayout) {
            if (userLayout[rootFullName]) {
                return plainToClass<UserLayoutMetaData, Object>(
                    UserLayoutMetaData, userLayout[rootFullName] as Object)
            }
        }
        return new UserLayoutMetaData();
    }

    private async updateUserLayoutFromLocalStorage(userLayoutMetaData: UserLayoutMetaData): Promise<void> {
        const userLayout = await LocalstorageHelper.getStorageItem(await this.getUserLayoutLocalStorageKey());
        if (userLayout) {
            userLayout[this.metadata.rootFullName] = classToPlain(userLayoutMetaData, { strategy: 'excludeAll' });
            await LocalstorageHelper.setStorageItem(await this.getUserLayoutLocalStorageKey(), userLayout);
        } else {
            await LocalstorageHelper.setStorageItem(await this.getUserLayoutLocalStorageKey(),
                { [this.metadata.rootFullName]: classToPlain(userLayoutMetaData, { strategy: 'excludeAll' }) }
            );
        }
    }

    private async getUserLayoutLocalStorageKey(): Promise<string> {
        return 'userLayout_' + await this.authService.getUserId() + '_' + await this.authService.getTenantId();
    }

    async setPanelUserLayoutData(dto: PanelUserLayoutDataDto): Promise<void> {

        // Verifico se l'utente ha i permessi per avere il modulo del user layout
        const canAccessToUserLayout: boolean = await firstValueFrom(this.canAccessToUserLayout());

        if (canAccessToUserLayout) {
            let response = await firstValueFrom(this.apiClient.setPanelUserLayoutDataAsync(dto));
            if (!response.operationSuccedeed) {
                LogService.warn('setPanelUserLayoutDataAsync error', response)
            }
        } else {

            // Aggiorno lo userLayoutMetaData
            if (this.userLayoutMetaData) {

                const panelIndex = this.userLayoutMetaData.panels.findIndex((p) => p.panelId === dto.panelMetaData.panelId)
                if (panelIndex > -1) {
                    this.userLayoutMetaData.panels[panelIndex] = dto.panelMetaData;
                } else {
                    this.userLayoutMetaData.panels.push(dto.panelMetaData);
                }

                // Aggiorno le informazione nel local storage
                await this.updateUserLayoutFromLocalStorage(this.userLayoutMetaData);
            }
        }
    }

    async setGridUserLayoutDataAsync(dto: GridUserLayoutDataDto): Promise<void> {

        // Verifico se l'utente ha i permessi per avere il modulo del user layout
        const canAccessToUserLayout = await firstValueFrom(this.canAccessToUserLayout());

        if (canAccessToUserLayout) {
            let response = await firstValueFrom(this.apiClient.setGridUserLayoutDataAsync(dto));
            if (!response.operationSuccedeed) {
                LogService.warn('setGridUserLayoutDataAsync error', response)
            }
        } else {

            // Aggiorno lo userLayoutMetaData
            if (this.userLayoutMetaData) {

                const gridIndex = this.userLayoutMetaData.grids.findIndex((g) => g.fullPathName === dto.gridMetaData.fullPathName)
                if (gridIndex > -1) {
                    this.userLayoutMetaData.grids[gridIndex] = dto.gridMetaData;
                } else {
                    this.userLayoutMetaData.grids.push(dto.gridMetaData);
                }

                // Aggiorno le informazione nel local storage
                this.updateUserLayoutFromLocalStorage(this.userLayoutMetaData);
            }
        }
    }

    /**
     * Resituisce il layout meta data con i pannelli
     * @returns
     */
    async initLayoutsMetaData(): Promise<GenericServiceResponse<AvailableLayoutsInfoDto>> {

        let identity: LayoutDefinitionIdentity = null;

        if (this._selectedLayoutCode?.length > 0 && this._selectedLayoutCode != 'standard') {
            identity = new LayoutDefinitionIdentity();
            identity.code = this._selectedLayoutCode;
        }

        let response = await firstValueFrom(this.apiClient.getAvailableLayoutsAsync(identity));

        const standardLayoutDto = new AvailableLayoutDto();
        standardLayoutDto.displayName = "Standard";
        standardLayoutDto.isPreferred = false;
        standardLayoutDto.identity.code = 'standard'
        this._layoutList = [standardLayoutDto];

        const layoutList = response.operationSuccedeed && response.result?.availableLayouts?.length > 0 ?
            [standardLayoutDto, ...response.result?.availableLayouts] : [standardLayoutDto]

        this.initLayoutLogic(layoutList);

        return response;
    }

    initLayoutLogic(availableLayoutDto: AvailableLayoutDto[]) {

        const preferredLayout = availableLayoutDto.filter((l) => l.isPreferred).pop();
        this._layoutList = availableLayoutDto;
        // Imposto il layout corrente
        if (this._selectedLayoutCode) { // Se è stato preselezionato un layout
            if (this._selectedLayoutCode !== 'standard') {
                this._currentLayout = availableLayoutDto.filter((l) => l.identity.code === this._selectedLayoutCode).pop();
                this._layoutMetaData = this.currentLayout?.metaData;
            } else {
                this._currentLayout = availableLayoutDto[0];
            }
        } else { // Se non è stato preselezionato un layout
            this._currentLayout = availableLayoutDto.length === 1 ? availableLayoutDto[0] : preferredLayout;

            this._layoutMetaData = availableLayoutDto.length === 1 ?
                null :        // Layout standard
                preferredLayout?.metaData;  //Seleziono il layout con preferred true altrimenti lo standard
        }

        if (!this._layoutMetaData) {
            // Forzo useMessageResourceKey se il layout selezionato è standard
            this._useMessageResourceKey = false;

        } else {

            // Se uso un layout custom creo layoutDefaultValueList (necessario per i pvm per impostare un valore di default)
            this._layoutDefaultValueList = new Map<string, string>();
            this._layoutMetaData.panels.forEach((panel) => {
                panel.simpleFields.forEach((sf) => {
                    if (sf.defaultValue) {
                        const camelCaseFullPathName = sf.fullPathName.split('.').map((p) => MetaDataUtils.toCamelCase(p)).filter((w) => w !== 'selectedItem').join('.');
                        this._layoutDefaultValueList.set(camelCaseFullPathName, sf.defaultValue);
                    }
                })
                panel.grids.forEach((gm) => {
                    gm.gridColumns.gridFields.forEach((gc) => {
                        if (gc.defaultValue) {
                            const camelCaseFullPathName = gc.fullPathName.split('.').map((p) => MetaDataUtils.toCamelCase(p)).filter((w) => w !== 'selectedItem').join('.');
                            this._layoutDefaultValueList.set(camelCaseFullPathName, gc.defaultValue);
                        }
                    });
                    gm.gridColumns.externals.forEach((external) => {
                        external.externalFields.forEach((externalField) => {
                            if (externalField.defaultValue) {
                                const camelCaseFullPathName = external.fullPathName.split('.').map((p) => MetaDataUtils.toCamelCase(p)).filter((w) => w !== 'selectedItem').join('.') + '.' + MetaDataUtils.toCamelCase(externalField.name);
                                this._layoutDefaultValueList.set(camelCaseFullPathName, externalField.defaultValue);
                            }
                        })
                    });
                })
                panel.externals.forEach((ex) => {
                    const externalPath = ex.path?.length > 0 ? ex.path + '.' : '';
                    const externalName = ex.name;
                    ex.externalFields.forEach((ef) => {
                        if (ef.defaultValue) {
                            const externalFieldPath = ef.path?.length > 0 ? ef.path + '.' : '';
                            const externalFieldName = ef.name;
                            const camelCaseFullPathName = (externalPath + externalName + '.' + externalFieldPath + externalFieldName).split('.').map((p) => MetaDataUtils.toCamelCase(p)).filter((w) => w !== 'selectedItem').join('.');
                            this._layoutDefaultValueList.set(camelCaseFullPathName, ef.defaultValue);
                        }
                    })
                })
                // TODO mancano gli external sia dalle griglie che dalla root, che dagli internal
            })
        }

        const manager = new UICommandSettingsManager();
        for (const layout of this.layoutList) {
            const layoutCommand = manager.setUICommand(CommandTypes.ChangeLayout,
                CommandFactory.createUICommand(
                    async (x) => { await this.changeLayout(layout.identity) },
                    () => of(true),
                    (x) => {
                        return of(
                            // layout standard
                            (layout?.identity?.code === 'standard' && this._layoutMetaData == null) ||

                            // layout selezionato
                            (this._selectedLayoutCode === layout?.identity?.code) ||

                            // layout preferito e non è stato selezionato alcun layout
                            (layout?.identity?.code === preferredLayout?.identity?.code && !this._selectedLayoutCode)
                        );
                    },
                )
            );
            layoutCommand.displayName = layout.displayName;
            layoutCommand.tooltip = layout.displayName;
            this.toolBarViewModel.customizeFieldsCommandGroup.addCommand(layoutCommand);
        }
    }

    async initPresentationMetaData(): Promise<MetaDataResponse> {
        const useMessageResourceKey = false;

        let response = new MetaDataResponse();
        if (!this._metadata) {
            response = await firstValueFrom(this.apiClient.getPresentationMetaDataAsync(this.currentLayout.identity));
            this._metadata = response.result;
        } else {
            response.result = this._metadata;
        }

        this._isDeactivable = this._metadata?.isDeactivable;
        this._useMessageResourceKey = useMessageResourceKey;

        if (response.result) {
            response.result.useMessageResourceKey = false;
        }

        this.eventDispatcher.onMetaDataLoaded.next(true);
        return response;
    }

    async getExternal<TExternalDomainModel extends CoreModel<TExternalIdentity>, TExternalIdentity extends BaseIdentity>(
        identity: TExternalIdentity, externalDomainModelTypeName: string, externalDomainModelType: ClassConstructor<TExternalDomainModel>): Promise<TExternalDomainModel> {
        const request = new GetByIdentityRequest<TExternalIdentity>();
        request.identity = identity;
        const response = await firstValueFrom(this.apiClient.getExternal<TExternalDomainModel, TExternalIdentity>(
            request, externalDomainModelTypeName, externalDomainModelType));
        // TODO: Gestione Errore ==> response.operationSuccedeed e catch

        this.toastMessageService.showToastsFromResponse(response);

        return response.result;
    }

    getExternalAutoCompleteValues(options: AutoCompleteExternalOptions): Observable<FindValuesResponse> {
        return this.apiClient.autoComplete(options);
    }

    waitForPendingAutocompleteAsync(): Promise<void> {

        return new Promise((resolve) => {
            if (this._pendingAutocomplete) {
                this._pendingAutocompleteSubscription = this.pendingAutocompleteEnded.subscribe(() => {
                    this._pendingAutocompleteSubscription.unsubscribe();
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    waitForPendingChangesAsync(): Promise<void> {

        return new Promise((resolve) => {
            if (this._pendingChanges) {
                this._pendingChangesSubscription = this.pendingChangesEnded.subscribe(() => {
                    this._pendingChangesSubscription.unsubscribe();
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    notifyPendingChangesStarting() {
        this.pendingChangesStarted.next();
        this._pendingChanges = true;
    }

    notifyPendingChangesEnded() {
        this.pendingChangesEnded.next();
        this._pendingChanges = false;
    }

    async about() {
        const response = await firstValueFrom(this.apiClient.getVersionData());
        if (response.operationSuccedeed) {

            const msVersion = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_MSVersion', response.result.msVersion);
            const msInformativeVersion = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_MSInformativeVersion', response.result.msInformativeVersion);
            const rootDomainModelName = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_RootDomainModelName', this.typedRootViewModel.domainModelFullName);
            const msAppUrl = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_MSAppUrl', this.env.baseAppUrl);

            const jsVersion = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_JsVersion', response.result.jsVersion);
            const jsInformativeVersion = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_JsInformativeVersion', response.result.jsInformativeVersion);

            const backendVersion = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_BackendVersion', response.result.version);
            const backendInformativeVersion = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_BackendInformativeVersion', response.result.informativeVersion);

            const frameworkJsVersion = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_FrameworkJsVersion', response.result.frameworkJsVersion);
            const frameworkJsInformativeVersion = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_FrameworkJsInformativeVersion', response.result.frameworkJsInformativeVersion);

            const frameworkVersion = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_FrameworkVersion', response.result.frameworkVersion);
            const frameworkInformativeVersion = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_FrameworkInformativeVersion', response.result.frameworkInformativeVersion);

            const browserLanguage = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_BrowserLanguage', navigator.language);
            const angularVersion = `Angular: ${VERSION.full}` // MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_AngularVersion', VERSION.full);

            let workerVersions = '';
            if ('caches' in window) {
                const workerVersionList = (await caches.keys()).filter(key => key.indexOf('assets:app:cache') > -1);
                if (workerVersionList?.length > 0) {
                    workerVersions = MessageResourceManager.Current.getMessageWithStrings('std_AboutModal_WorkerVersions', workerVersionList.join(' - '));
                }
            }

            const message =
                msVersion + '<br>' +
                msInformativeVersion + '<br>' +
                rootDomainModelName + '<br>' +
                msAppUrl + '<br>' +

                jsVersion + '<br>' +
                jsInformativeVersion + '<br>' +

                backendVersion + '<br>' +
                backendInformativeVersion + '<br>' +

                frameworkJsVersion + '<br>' +
                frameworkJsInformativeVersion + '<br>' +

                frameworkVersion + '<br>' +
                frameworkInformativeVersion + '<br>' +

                ((workerVersions?.length > 0) ? (workerVersions + '<br>') : '') +

                angularVersion + '<br>' +
                browserLanguage

            await this.modalService.showMessageAsync(MessageResourceManager.Current.getMessage(MessageCodes.AboutWindowDisplayName), message, MessageButton.Ok);
        } else {
            this.toastMessageService.showToastsFromResponse(response);
        }
    }

    notifyPendingAutocompleteStarting() {
        this.pendingAutocompleteStarted.next();
        this._pendingAutocomplete = true;
    }

    notifyPendingAutocompleteEnded() {
        this.pendingAutocompleteEnded.next();
        this._pendingAutocomplete = false;
    }

    async startRelatedClient(
        relatedDomainModelFullName: string,
        identity: TIdentity,
        additionalQueryParams: URLSearchParams,
        isRoot: boolean,
        blank = false
    ): Promise<void> {
        // await this.waitForPendingChangesAsync();
        if (!(await firstValueFrom(this.canStartRelatedClient()))) {
            return;
        }
        await PresentationCache.registerRelated(relatedDomainModelFullName);

        const resultBaseUrl = PresentationCache.get(relatedDomainModelFullName);

        if (resultBaseUrl != null && resultBaseUrl !== '') {

            // Non è necessario disabilitare il documento se sto aprendo un nuovo tab
            this.documentEnabled = blank !== false;

            const plainIdentity = classToPlain(identity, { strategy: 'excludeAll' });
            const jsonIdentity = JSON.stringify(plainIdentity);

            UIStarter.startRelatedClient(
                relatedDomainModelFullName,
                resultBaseUrl,
                jsonIdentity,
                additionalQueryParams,
                isRoot,
                blank
            );

        } else {

            // TODO: Notificare che non esiste la gestione del related

        }
    }

    convertJsonIdentity(sourceJsonIdentity: string) {

        const identity = new this.identityType();

        const plainJson = JSON.parse(sourceJsonIdentity);

        Object.keys(plainJson).forEach(k => {
            const fieldName = MetaDataUtils.toCamelCase(k);
            identity[fieldName] = plainJson[k];
        });

        return identity;

    }

    canStartRelatedClient(): Observable<boolean> {
        return this.currentStateChanged.pipe(switchMap(() => {
            return this.currentState.canStartRelatedClient();
        }))
    }

    canGenerateReport(): Observable<boolean> {
        return this.currentStateChanged.pipe(switchMap(() => {
            return this.currentState.canStartRelatedClient();
        }))
    }

    private _canAccessToSnapShot = null;

    canAccessToSnapShot(): Observable<boolean> {
        if (this._canAccessToSnapShot) {
            return of(this._canAccessToSnapShot);
        }
        return this.apiClient.userCanAccessToServices([
            'SnapShotService.SnapShotFrameObjects.Models.SnapShotFrame',
            'SnapShotService.SnapShotFrameObjects.Models.SnapShotFrameTenant'
        ]).pipe(
            map((res) => res.operationSuccedeed && res.result.every((v) => v.canAccess === true)),
            catchError(err => {
                LogService.warn(err);
                return of(false);
            }),
            tap((res) => {
                this._canAccessToSnapShot = res;
            })
        )
    }

    private _canAccessToLayout = null;

    canAccessToLayout(): Observable<boolean> {
        if (this._canAccessToLayout) {
            return of(this._canAccessToLayout);
        }
        return this.apiClient.userCanAccessToService('LayoutManager.UserLayoutDataObjects.Models.UserLayoutData').pipe(
            map((res) => res.operationSuccedeed && res.userCanAccessToService),
            catchError(err => {
                LogService.warn(err);
                return of(false);
            }),
            tap((res) => {
                this._canAccessToLayout = res;
            })
        )
    }

    private _canAccessToSecurity = null;

    canAccessToSecurity(): Observable<boolean> {
        if (this._canAccessToSecurity) {
            return of(this._canAccessToSecurity);
        }
        return this.apiClient.userCanAccessToService('SecurityManager.SecurityDataObjects.Models.SecurityData').pipe(
            map((res) => res.operationSuccedeed && res.userCanAccessToService),
            catchError(err => {
                LogService.warn(err);
                return of(false);
            }),
            tap((res) => {
                this._canAccessToSecurity = res;
            })
        )
    }

    private _canAccessToUserLayout = null;

    canAccessToUserLayout(): Observable<boolean> {
        if (this._canAccessToUserLayout) {
            return of(this._canAccessToUserLayout);
        }
        return this.apiClient.userCanAccessToService('LayoutManager.UserLayoutDataObjects.Models.UserLayoutData').pipe(
            map((res) => res.operationSuccedeed && res.userCanAccessToService),
            catchError(err => {
                LogService.warn(err);
                return of(false);
            }),
            tap((res) => {
                this._canAccessToUserLayout = res;
            })
        )
    }

    async startCrudClient<TIdentity extends BaseIdentity>(
        relatedDomainModelFullName: string,
        identity?: TIdentity,
        additionalQueryParams = new URLSearchParams(),
        addHistoryBackButton = false,
        blank = false
    ): Promise<void> {

        await PresentationCache.registerCrudQ(relatedDomainModelFullName);

        const resultBaseUrl = PresentationCache.get(relatedDomainModelFullName);

        if (resultBaseUrl != null && resultBaseUrl !== '') {

            // Non è necessario disabilitare il documento se sto aprendo un nuovo tab
            this.documentEnabled = blank !== false;

            let jsonIdentity = null;

            if (identity) {
                const plainIdentity = classToPlain(identity, { strategy: 'excludeAll' });
                jsonIdentity = JSON.stringify(plainIdentity);
            }

            UIStarter.startCrudClient(
                relatedDomainModelFullName,
                resultBaseUrl,
                jsonIdentity,
                additionalQueryParams,
                addHistoryBackButton,
                blank);

        } else {

            // TODO: Notificare che non esiste la gestione del related

        }
    }

    async startLongOpClient<TObject extends any>(
        domainModelFullName: string,
        object?: TObject,
        additionalQueryParams = new URLSearchParams(),
        addHistoryBackButton = false,
        blank = false
    ): Promise<void> {

        await PresentationCache.registerLongOp(domainModelFullName);

        const resultBaseUrl = PresentationCache.get(domainModelFullName);

        if (resultBaseUrl != null && resultBaseUrl !== '') {

            // Non è necessario disabilitare il documento se sto aprendo un nuovo tab
            this.documentEnabled = blank !== false;

            let jsonObject = null;

            if (object) {
                const plainObject = classToPlain(object, { strategy: 'excludeAll' });
                jsonObject = JSON.stringify(plainObject);
            }

            UIStarter.startLongOpClient(
                domainModelFullName,
                resultBaseUrl,
                jsonObject,
                additionalQueryParams,
                addHistoryBackButton,
                blank
            );

            this.documentEnabled = true;

        } else {

            // TODO: Notificare che non esiste la gestione del related

        }
    }

    addSpoolProcess<TOperationResult>(process: SpoolProcess<TOperationResult>) {
        this.spoolProcessList.push(process);
        let stop = false;
        let counter = 0;
        const maxRetry = 50;
        const intervalMs = 5000;
        interval(intervalMs).pipe(takeUntil(this.rootViewModelChangedFromNow), takeWhile((_) => !stop), switchMap(() =>
            process.CheckProcess$.pipe(
                map((data: { operationState: OperationState, processResult: TOperationResult, attachments: AttachmentIdentity[] }) => {
                    process.processResult = data.processResult;
                    process.attachments = data.attachments;
                    counter++;

                    // Stop after maxRetry * intervalMs Millisecond
                    stop = data.operationState !== OperationState.InProgress && data.operationState !== OperationState.WaitingToStart || counter >= maxRetry;
                    if (stop && (data.operationState === OperationState.EndedWithError || data.operationState === OperationState.EndedWithSuccess)) {
                        this.spoolProcessCompleted.next(process);
                    } else if (stop) {

                        // Lo spool non è terminato nel tempo limite
                        this.spoolProcessError.next(process);
                    }
                })
            )
        )).subscribe();
    }

    async openSpoolProcess(operationIdentity: OperationIdentity) {
        this.openSpoolProcessRequested.next(operationIdentity);
    }

    async generateReport<TParams, TRequestDto extends PrintOutDemandDto<TParams>>(
        reportInfo: ReportInfoDto,
        dtoType: ClassConstructor<TRequestDto>,
        paramsGetter: () => TParams,
        controllerAddress: string,
        command: UICommandInterface<any, any, OperationIdentity>
    ): Promise<void> {
        await this.generateReportWithCommand<TParams, TRequestDto>(command, reportInfo, paramsGetter, controllerAddress, dtoType);
    }

    findValues(
        findOptions: FindValuesOptions,
        entityToLookUp = '',
        entityToLookUpFullName = ''
    ): Observable<FindValuesResponse> {
        return this.apiClient.findValues(
            entityToLookUp?.length > 0 ? entityToLookUp : this.rootViewModel.domainModelName,
            findOptions,
            entityToLookUpFullName?.length > 0 ? entityToLookUp : this.rootViewModel.domainModelFullName,
            this.rootViewModel.domainModelName,
        );
    }

    protected getTitle(): string {
        if (!this.rootViewModel) {
            return '';
        }

        const domainModel = this.rootViewModel.getDomainModel();
        const identityFieldName = MetaDataUtils.toCamelCase(this.metadata.rootMetaData.identityNames[0]);
        const identiyValue = domainModel.currentIdentity.getPropertyValue(identityFieldName);

        let result = '';

        // la identity è un guid non devo visualizzarla
        if (this.metadata.rootMetaData.guids?.length > 0) {
            result = ''
        } else {
            // Visualizzo l'identity nel titolo se è valorizzata
            if (
                (typeof identiyValue === 'string' && identiyValue.length > 0) ||    // E' una stringa o
                (typeof identiyValue === 'number' && identiyValue > 0)              // E' un numero
            ) {
                const pvm = this.rootViewModel.getProperty(identityFieldName);
                result = pvm.formattedValue;
            }
        }

        return `${this.rootViewModel.metadataShortDescription}${result?.length > 0 ? ' - ' + result : ''}`;
    }

    protected clearMessages(clearMode: MsgClearMode) {
        if (clearMode === MsgClearMode.ClearAllMessages) {
            this.removeAllMessages();
        } else if (clearMode === MsgClearMode.ClearOnlyTemporaryMessage) {
            this.removeApiAndViewModelMessages();
        }
    }

    viewModelValidate(clearAllPreviousMessages: boolean): boolean {
        let isValid = true;

        if (this.currentState.canValidate()) {


            // Cancello anzitutto gli errori dalla lista dei messaggi. Il ViewModel.Validate aggiungerà poi tutti
            // gli errori che finiranno direttamente nella lista dei messaggi
            if (clearAllPreviousMessages) {
                this.clearMessages(MsgClearMode.ClearAllMessages);
            }

            this.rootViewModel.validate();

            isValid = this.allViewModelErrorsBuildedList.length === 0;
            this.currentState.validate();
        } else {
            this.notAllowedAction(CommandTypes.Validate);
            isValid = false;
        }

        return isValid;
    }

    protected addInformationMessageIfNotExist(info: Information) {
        // verifica che non ci sia già una information con lo stesso codice e propertyname
        if (!this.messageContainerCollection.find(x => x.message === info.description)) {
            this.messageContainerCollection.splice(0, 0, MessageContainer.fromBaseMessage(info, this.uniqueId));
            this.eventDispatcher.onAddMessageInViewModel.next({ viewModel: this, messages: [info] });
        }
    }

    protected addErrorMessageIfNotExist(err: BaseError) {
        // verifica che non ci sia già un errore con lo stesso codice e propertyname

        if (err?.propertyName?.length > 0) {
            let pvm: BaseViewModelInterface = this.rootViewModel.getProperty(MetaDataUtils.toCamelCase(err?.propertyName))

            if (err?.objectName?.length > 0) {
                const vm = this.findViewModelByDomainModelFullName(err?.objectName);
                if (vm) {
                    if (ClassInformationUtility.checkClassType(vm, ClassInformationType.CollectionViewModel)) {
                        // Se sono in una collection aggiungo gli errori nella sua messageContainerCollection visto che non so in quale riga aggiungerli
                        pvm = vm;
                    } else if (vm.getProperty != null) {
                        pvm = vm.getProperty(MetaDataUtils.toCamelCase(err?.propertyName));
                    }
                }
            }

            if (pvm) {
                if (!pvm.messageContainerCollection.find(x => x.contains(err))) {
                    pvm.messageContainerCollection.splice(0, 0, MessageContainer.fromBaseMessage(err, pvm.uniqueId));
                    this.eventDispatcher.onAddMessageInViewModel.next({ viewModel: pvm, messages: [err] });
                    pvm.onErrorStatusChanged.next();
                }
            } else {
                // verifica che non ci sia già un errore con lo stesso codice e propertyname
                if (!this.messageContainerCollection.find(x => x.contains(err))) {
                    this.messageContainerCollection.splice(0, 0, MessageContainer.fromBaseMessage(err, this.uniqueId));
                    this.eventDispatcher.onAddMessageInViewModel.next({ viewModel: this, messages: [err] });
                }
            }
        } else {
            // verifica che non ci sia già un errore con lo stesso codice e propertyname
            if (!this.messageContainerCollection.find(x => x.contains(err))) {
                this.messageContainerCollection.splice(0, 0, MessageContainer.fromBaseMessage(err, this.uniqueId));
                this.eventDispatcher.onAddMessageInViewModel.next({ viewModel: this, messages: [err] });
            }
        }
    }

    protected findViewModelByDomainModelFullName(objectName: string): ViewModelInterface {

        const path = this.recursiveFindDomainModelFullName(this.rootViewModel.domainModelMetaData, objectName)
        if (path) {
            return path.split('.').reduce((previousValue, currentValue: string) => {
                if (currentValue?.length > 0) {
                    return previousValue.relationViewModels.get(currentValue) as ViewModelInterface;
                } else {
                    return previousValue;
                }
            }, this.rootViewModel as ViewModelInterface)
        }
        return null;
    }

    protected recursiveFindDomainModelFullName(sourceDomainModelMetaData: DomainModelMetaData, domainModelFullName: string, path = ''): string {
        if (sourceDomainModelMetaData.fullName == domainModelFullName) {
            return path;
        }
        for (const collection of sourceDomainModelMetaData.internalCollections) {
            const foundPath = this.recursiveFindDomainModelFullName(
                collection.dependentMetaData,
                domainModelFullName,
                path?.length > 0 ?
                    (path + '.' + MetaDataUtils.toCamelCase(collection.principalPropertyName)) :
                    MetaDataUtils.toCamelCase(collection.principalPropertyName)
            )
            if (foundPath?.length > 0) {
                return foundPath;
            }
        }

        for (const relation of sourceDomainModelMetaData.internalRelations) {
            const foundPath = this.recursiveFindDomainModelFullName(
                relation.dependentMetaData,
                domainModelFullName,
                path?.length > 0 ?
                    (path + '.' + MetaDataUtils.toCamelCase(relation.principalPropertyName)) :
                    MetaDataUtils.toCamelCase(relation.principalPropertyName)
            )
            if (foundPath?.length > 0) {
                return foundPath;
            }
        }

        for (const external of sourceDomainModelMetaData.externals) {
            const foundPath = this.recursiveFindDomainModelFullName(
                external.dependentAggregateMetaData.rootMetaData,
                domainModelFullName,
                path?.length > 0 ?
                    (path + '.' + MetaDataUtils.toCamelCase(external.principalPropertyName)) :
                    MetaDataUtils.toCamelCase(external.principalPropertyName)
            )
            if (foundPath?.length > 0) {
                return foundPath;
            }
        }
        return null;
    }

    protected refreshMessagesFromStrings(msgs: Array<string>, clearMode: MsgClearMode) {
        const msgList = new Array<BaseMessage>();
        msgs.forEach(msg => {
            const basemsg = new Information();
            basemsg.description = msg;
            msgList.push(basemsg);
        });
        this.refreshMessagesFromBaseMessages(msgList, clearMode);
    }

    protected refreshMessagesFromBaseMessages(msgs: Array<BaseMessage>, clearMode: MsgClearMode) {
        const errors: BaseError[] = [];
        const infos: Information[] = [];

        msgs.forEach(x => {
            if (x instanceof BaseError) {
                errors.push(x);
            } else if (x instanceof Information) {
                infos.push(x);
            }
        });

        this.refreshMessages(errors, infos, clearMode);
    }

    protected refreshMessagesFromResponse(response: ServiceResponse, clearMode: MsgClearMode) {
        this.refreshMessages(
            response.errors,
            response.informations,
            clearMode
        );
        if (response.errors?.length > 0) {
            this.eventDispatcher.onValidationBarCollapsed.next(false);
        }
        if (response.informations?.length > 0) {
            this.eventDispatcher.onNotificationBarCollapsed.next(false);
        }
    }

    protected refreshMessages(
        errors: Array<BaseError>,
        informations: Array<Information>,
        clearMode: MsgClearMode
    ) {
        if (clearMode === MsgClearMode.ClearAllMessages) {
            this.removeAllMessages();
        } else if (clearMode === MsgClearMode.ClearOnlyTemporaryMessage) {
            this.removeApiAndViewModelMessages();
        }

        if (informations != null) {
            informations.forEach(info => {
                this.addInformationMessageIfNotExist(info);
            });
        }
        if (errors != null) {
            errors.forEach(err => {
                this.addErrorMessageIfNotExist(err);
            });
        }
    }

    protected removeApiAndViewModelMessages() {
        this.removeMessagesFromCondition(x => {
            return x.sourceMessage === SourceMessage.Api || x.sourceMessage === SourceMessage.ViewModel;
        });
    }

    protected notAllowedAction(cmd: CommandTypes) {
        const message = MessageResourceManager.Current.getMessageWithStrings(MessageCodes.CommandNotAllowed,
            MessageResourceManager.Current.getMessage('std_CMD_' + CommandTypes[cmd]));
        const messages = [message];
        this.refreshMessagesFromStrings(messages, MsgClearMode.ClearOnlyTemporaryMessage);
    }

    protected async tryRebuildViewModelAsync(
        response: ServiceResponse,
        domainModel: TModel,
        fromCreate: boolean,
        clearAllPreviousMessages: boolean) {

        // Se è indicato di cancellare tutti i messaggi li cancello subito tutti, perchè poi i 2 metodi LoadAllExternalList e
        // RefreshMessages chiamati nel seguito non devono cancellare dato che si pesterebbero a vicenda (LoadAllExternalList
        // parte in asincrono
        if (clearAllPreviousMessages) {
            this.removeAllMessages();
        }

        if (response.operationSuccedeed && domainModel != null) {
            await this.tryRebuildViewModelAsyncWithoutResponse(domainModel, fromCreate);
        }

        // aggiorna la lista messaggi dalla response
        this.refreshMessagesFromResponse(response, MsgClearMode.NoClear);
    }

    protected setEntity(domainModel: TModel): void {
        this.domainModel = domainModel;
    }

    protected async buildGridColumns(viewModel: RootViewModelInterface): Promise<void> {
        if (this._gridColumns === undefined) {
            this._gridColumns = new Map<string, ColumnInfoCollection>();
            this.buildGridColumnsForDomainModelMetaData(this.metadata.rootMetaData, this.metadata, []);
        }
        if (this._customGridColumns === undefined) {
            this._customGridColumns = new Map<string, ColumnInfoCollection>();
            await viewModel.initCustomGridColumns();
        }
    }

    async getParentIdentityByPathName(path: string): Promise<null | any> {
        await firstValueFrom(this.rebuildRootViewModelInProgress$.pipe(filter(_ => this.rootViewModel != null && this.rebuildRootViewModelInProgress$.value === false)))

        const result = path.split('.').reduce((o, j) => {
            if (o != null && j != null && o[MetaDataUtils.toCamelCase(j)] != null) {
                return o[MetaDataUtils.toCamelCase(j)];
            }
            return null;
        }, this.rootViewModel);
        return result;
    }

    protected async tryRebuildViewModelAsyncWithoutResponse(
        domainModel: TModel, fromCreate: boolean, skipPostInit = false
    ) {
        this.rebuildRootViewModelInProgress$.next(true);
        if (domainModel != null) {
            this.setEntity(domainModel);

            // TODO Tommy: map externalist
            // this.externalListMap = new Map<string, ExternalListPVMManagerInterface>();

            const viewModel: TViewModel = await ViewModelFactory.createRootViewModel<TViewModel, TModel, TIdentity>(
                this._rootViewModelType, this.domainModel, this.metadata, this, skipPostInit, this.apiClient.rootModelType
            ) as TViewModel;

            await this.buildGridColumns(viewModel);

            // destroy old viewmodel (viene effettuato ricorsivamente prima sui suoi childviewmodel)
            if (this.rootViewModel) {
                this.rootViewModel.onDestroy();
            }
            this.setRootViewModel(viewModel as TViewModel);

            // imposta il title della window
            // TODO Tommy: recuperare dalle risorse il nome dell'applicazione
            window.document.title = `${this.title} - ${this.env.appTitle}`;

            await this.executeDefaultTrackPageView();

            // TODO Tommy: loadAllExternalList
            // this.loadAllExternalList(fromCreate);
        }
        this.rebuildRootViewModelInProgress$.next(false);
    }

    async localReplicaAutocomplete<T>(model: T): Promise<T> {
        return null;
    }

    protected async executeDefaultTrackPageView(): Promise<void> {
        TelemetryService.trackPageView();
    }

    private rebuildMessageList() {
        this.allViewModelErrorsBuildedList = [];
        this._allViewModelMessagesMap.forEach(({ messageContainerCollection: value }) => {
            this.allViewModelErrorsBuildedList.push(...value.filter((v) => v.isErrorMessage));
        });

        this.allViewModelInformationsBuildedList = [];
        this._allViewModelMessagesMap.forEach(({ messageContainerCollection: value }) => {
            this.allViewModelInformationsBuildedList.push(...value.filter((v) => !v.isErrorMessage));
        });
    }

    setRootViewModel(value: TViewModel) {
        // Pulisce il dictionary da tutti messaggi (errori e informazioni) in tutti i vm;
        this._eventDispatcher.onClearAllMessages.next();
        this._rootViewModel = value;
        // TODO Tommy gestire isMock/isVirtual
        // if (this.entity.isMock === false) {
        this.rootViewModelChanged.next();
        this.rootViewModelChangedFromNow.next();
        // }
    }

    private recursiveProcessValidators(
        currentDomainModelMetaData: DomainModelMetaData,
        currentDomainModelType: ClassConstructor<any>,
        processedDomainModels = [],
        parentDomainModelMetaData: DomainModelMetaData
    ) {

        if (processedDomainModels.find((element) => element === currentDomainModelMetaData.fullName) === undefined) {
            processedDomainModels.push(currentDomainModelMetaData.fullName);

            // #region Stringhe
            currentDomainModelMetaData.strings?.forEach((stringMetaData: StringMetaData) => {
                const propertyName: string = MetaDataUtils.toCamelCase(stringMetaData.name);

                let isRequired: boolean = stringMetaData.isRequired;

                // Non più necessaria nel client, i backing field devono sempre essere con required uguale a false indipendentemente da quello che dice il server
                // @deprecated Nel caso in cui il campo corrente è un backing field di un external
                // @deprecated Il required viene definito dall'external
                // @deprecated if (MetaDataUtils.checkIfPropertyIsACodeForExternal(currentDomainModelMetaData, stringMetaData.name)) {
                // @deprecated    const externalMetaData = currentDomainModelMetaData.externals.find(
                // @deprecated        (external: ExternalMetaData) => external.associationProperties && external.associationProperties.length > 0 && external.associationProperties.find(ass => ass.principalPropertyName === stringMetaData.name));
                // @deprecated    isRequired = externalMetaData.isRequired;
                // @deprecated }

                // è un backing field di un external?
                if (MetaDataUtils.checkIfPropertyIsACodeForExternal(currentDomainModelMetaData, stringMetaData.name)) {
                    // forzo il required a false
                    isRequired = false;
                }

                // Nel caso in cui il campo corrente è un campo di associazione di un internal relation
                // Il required è sempre false
                if (parentDomainModelMetaData && MetaDataUtils.checkIfPropertyIsAnInternalAssociationCode(parentDomainModelMetaData, currentDomainModelMetaData, stringMetaData.name)) {
                    isRequired = false;
                }

                // Nel caso in cui il campo corrente è un campo di associazione di un internal collection
                // Il required è sempre false
                if (parentDomainModelMetaData && MetaDataUtils.checkIfPropertyIsAnInternalCollectionAssociationCode(parentDomainModelMetaData, currentDomainModelMetaData, stringMetaData.name)) {
                    isRequired = false;
                }

                // Verifica se è stato utilizzato un decoratore
                const decoratorData: StringMetaData = BaseValidator.getPropertyMetaData(currentDomainModelType, propertyName) as StringMetaData;

                // Sovrascrivo con il required indicato nel decoratore
                if (decoratorData?.isRequired != null) {
                    isRequired = decoratorData.isRequired;
                }

                // Mergio le informazione dai meta-dati con quelli (se esiste) del decoratore
                const decoratorInterface: StringDecoratorInterface = {
                    context: decoratorData?.context,
                    displayNameKey: decoratorData?.descriptions.displayNameKey ?? stringMetaData.descriptions.displayNameKey,
                    displayName: decoratorData?.descriptions.displayName ?? stringMetaData.descriptions.displayName,
                    descriptionKey: decoratorData?.descriptions.descriptionKey ?? stringMetaData.descriptions.descriptionKey,
                    description: decoratorData?.descriptions.description ?? stringMetaData.descriptions.description,
                    shortNameKey: decoratorData?.descriptions.shortNameKey ?? stringMetaData.descriptions.shortNameKey,
                    shortName: decoratorData?.descriptions.shortName ?? stringMetaData.descriptions.shortName,
                    maxLength: decoratorData?.maxLen ?? stringMetaData.maxLen,
                    isRequired,
                    isEmail: decoratorData?.isEmail ?? stringMetaData.isEmail,
                    isMainDescription: decoratorData?.isMainDescription ?? stringMetaData.isMainDescription,
                    allowedChars: decoratorData?.allowedCharacters ?? stringMetaData.allowedCharacters,
                } as StringDecoratorInterface;

                // Rimuovo il decoratore per il required se esisteva
                if (decoratorData?.isRequired) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.IS_DEFINED)
                }

                // Rimuovo il vecchio decoratore se esisteva
                if (decoratorData) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.CUSTOM_VALIDATION)
                }

                // Metodo di base per tutti i decoratori
                BaseValidator.initBaseValidatorWithConstructor(decoratorInterface, currentDomainModelType, propertyName);

                // Registro il decoratore
                registerDecorator({
                    target: currentDomainModelType,
                    propertyName,
                    options: { context: decoratorInterface.context },
                    constraints: [decoratorInterface],
                    validator: StringValidator
                });

                // Aggiunge informazioni alla property sulla validazione sul tipo della classe
                StringValidator.buildPropertyMetaData<StringDecoratorInterface>(
                    currentDomainModelType,
                    propertyName,
                    decoratorInterface
                );
            });
            // #endregion Stringhe

            // #region TimeSpan
            currentDomainModelMetaData.timeSpans?.forEach((timeSpanMetaData: TimeSpanMetaData) => {
                const propertyName: string = MetaDataUtils.toCamelCase(timeSpanMetaData.name);

                // Verifica se è stato utilizzato un decoratore
                const decoratorData: TimeSpanMetaData = BaseValidator.getPropertyMetaData(currentDomainModelType, propertyName) as TimeSpanMetaData;

                // Mergio le informazione dai meta-dati con quelli (se esiste) del decoratore
                const decoratorInterface: TimeSpanDecoratorInterface = {
                    context: decoratorData?.context,
                    displayNameKey: decoratorData?.descriptions.displayNameKey ?? timeSpanMetaData.descriptions.displayNameKey,
                    displayName: decoratorData?.descriptions.displayName ?? timeSpanMetaData.descriptions.displayName,
                    descriptionKey: decoratorData?.descriptions.descriptionKey ?? timeSpanMetaData.descriptions.descriptionKey,
                    description: decoratorData?.descriptions.description ?? timeSpanMetaData.descriptions.description,
                    shortNameKey: decoratorData?.descriptions.shortNameKey ?? timeSpanMetaData.descriptions.shortNameKey,
                    shortName: decoratorData?.descriptions.shortName ?? timeSpanMetaData.descriptions.shortName,
                    isRequired: decoratorData?.isRequired ?? timeSpanMetaData.isRequired,
                    isAutoComputed: decoratorData?.isAutoComputed ?? timeSpanMetaData.isAutoComputed,
                    minValue: decoratorData?.minValue ?? timeSpanMetaData.minValue,
                    maxValue: decoratorData?.maxValue ?? timeSpanMetaData.maxValue
                } as TimeSpanDecoratorInterface;

                // Rimuovo il decoratore per il required se esisteva
                if (decoratorData?.isRequired) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.IS_DEFINED)
                }

                // Rimuovo il vecchio decoratore se esisteva
                if (decoratorData) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.CUSTOM_VALIDATION)
                }

                // Metodo di base per tutti i decoratori
                BaseValidator.initBaseValidatorWithConstructor(decoratorInterface, currentDomainModelType, propertyName);

                // Registro il decoratore
                registerDecorator({
                    target: currentDomainModelType,
                    propertyName,
                    options: { context: decoratorInterface.context },
                    constraints: [decoratorInterface],
                    validator: TimeSpanValidator
                });

                // Aggiunge informazioni alla property sulla validazione sul tipo della classe
                TimeSpanValidator.buildPropertyMetaData<TimeSpanDecoratorInterface>(
                    currentDomainModelType,
                    propertyName,
                    decoratorInterface
                );
            });
            // #endregion TimeSpan

            // #region DateTime
            currentDomainModelMetaData.dateTimes?.forEach((dateTimeMetaData: DateTimeMetaData) => {
                const propertyName: string = MetaDataUtils.toCamelCase(dateTimeMetaData.name);

                // Verifica se è stato utilizzato un decoratore
                const decoratorData: DateTimeMetaData = BaseValidator.getPropertyMetaData(currentDomainModelType, propertyName) as DateTimeMetaData;

                // Mergio le informazione dai meta-dati con quelli (se esiste) del decoratore
                const decoratorInterface = {
                    context: decoratorData?.context,
                    displayNameKey: decoratorData?.descriptions.displayNameKey ?? dateTimeMetaData.descriptions.displayNameKey,
                    displayName: decoratorData?.descriptions.displayName ?? dateTimeMetaData.descriptions.displayName,
                    descriptionKey: decoratorData?.descriptions.descriptionKey ?? dateTimeMetaData.descriptions.descriptionKey,
                    description: decoratorData?.descriptions.description ?? dateTimeMetaData.descriptions.description,
                    shortNameKey: decoratorData?.descriptions.shortNameKey ?? dateTimeMetaData.descriptions.shortNameKey,
                    shortName: decoratorData?.descriptions.shortName ?? dateTimeMetaData.descriptions.shortName,
                    isRequired: decoratorData?.isRequired ?? dateTimeMetaData.isRequired,
                    isDateTimeOffset: decoratorData?.isDateTimeOffset ?? dateTimeMetaData.isDateTimeOffset,
                } as DateDecoratorInterface;

                // Rimuovo il decoratore per il required se esisteva
                if (decoratorData?.isRequired) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.IS_DEFINED)
                }

                // Rimuovo il vecchio decoratore se esisteva
                if (decoratorData) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.CUSTOM_VALIDATION)
                }

                // Metodo di base per tutti i decoratori
                BaseValidator.initBaseValidatorWithConstructor(decoratorInterface, currentDomainModelType, propertyName);

                // Registro il decoratore
                registerDecorator({
                    target: currentDomainModelType,
                    propertyName,
                    options: { context: decoratorInterface.context },
                    constraints: [decoratorInterface],
                    validator: DateValidator
                });

                // Aggiunge informazioni alla property sulla validazione sul tipo della classe
                DateValidator.buildPropertyMetaData<DateDecoratorInterface>(
                    currentDomainModelType,
                    propertyName,
                    decoratorInterface
                );
            });
            // #endregion DateTime

            // #region Numerics
            currentDomainModelMetaData.numerics?.forEach((numericMetaData: NumericMetaData) => {
                const propertyName: string = MetaDataUtils.toCamelCase(numericMetaData.name);

                let isRequired: boolean = numericMetaData.isRequired;

                // Non più necessaria nel client, i backing field devono sempre essere con required uguale a false indipendentemente da quello che dice il server
                // @deprecated Nel caso in cui il campo corrente è un backing field di un external
                // @deprecated Il required viene definito dall'external
                // @deprecated if (MetaDataUtils.checkIfPropertyIsACodeForExternal(currentDomainModelMetaData, numericMetaData.name)) {
                // @deprecated    const externalMetaData: ExternalMetaData = currentDomainModelMetaData.externals.find(
                // @deprecated        (external: ExternalMetaData) => external.associationProperties && external.associationProperties.length > 0 && external.associationProperties.find(ass => ass.principalPropertyName === numericMetaData.name));
                // @deprecated    isRequired = externalMetaData.isRequired;
                // @deprecated }

                // è un backing field di un external?
                if (MetaDataUtils.checkIfPropertyIsACodeForExternal(currentDomainModelMetaData, numericMetaData.name)) {
                    // forzo il required a false
                    isRequired = false;
                }

                // Nel caso in cui il campo corrente è un campo di associazione di un internal relation
                // Il required è sempre false
                if (parentDomainModelMetaData && MetaDataUtils.checkIfPropertyIsAnInternalAssociationCode(parentDomainModelMetaData, currentDomainModelMetaData, numericMetaData.name)) {
                    isRequired = false;
                }

                // Nel caso in cui il campo corrente è un campo di associazione di un internal collection
                // Il required è sempre false
                if (parentDomainModelMetaData && MetaDataUtils.checkIfPropertyIsAnInternalCollectionAssociationCode(parentDomainModelMetaData, currentDomainModelMetaData, numericMetaData.name)) {
                    isRequired = false;
                }

                // Verifica se è stato utilizzato un decoratore
                const decoratorData: NumericMetaData = BaseValidator.getPropertyMetaData(currentDomainModelType, propertyName) as NumericMetaData;

                // Sovrascrivo con il required indicato nel decoratore
                if (decoratorData?.isRequired != null) {
                    isRequired = decoratorData.isRequired;
                }

                // Mergio le informazione dai meta-dati con quelli (se esiste) del decoratore
                const decoratorInterface = {
                    context: decoratorData?.context,
                    displayNameKey: decoratorData?.descriptions.displayNameKey ?? numericMetaData.descriptions.displayNameKey,
                    displayName: decoratorData?.descriptions.displayName ?? numericMetaData.descriptions.displayName,
                    descriptionKey: decoratorData?.descriptions.descriptionKey ?? numericMetaData.descriptions.descriptionKey,
                    description: decoratorData?.descriptions.description ?? numericMetaData.descriptions.description,
                    shortNameKey: decoratorData?.descriptions.shortNameKey ?? numericMetaData.descriptions.shortNameKey,
                    shortName: decoratorData?.descriptions.shortName ?? numericMetaData.descriptions.shortName,
                    maxValue: decoratorData?.maxValue ?? numericMetaData.maxValue,
                    minValue: decoratorData?.minValue ?? numericMetaData.minValue,
                    maxDecimalPrecision: decoratorData?.maxDecimalPrecision ?? numericMetaData.maxDecimalPrecision,
                    maxIntegerPrecision: decoratorData?.maxIntegerPrecision ?? numericMetaData.maxIntegerPrecision,
                    isRequired,
                } as NumberDecoratorInterface;

                // Rimuovo il decoratore per il required se esisteva
                if (decoratorData?.isRequired) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.IS_DEFINED)
                }

                // Rimuovo il vecchio decoratore se esisteva
                if (decoratorData) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.CUSTOM_VALIDATION)
                }

                // Metodo di base per tutti i decoratori
                BaseValidator.initBaseValidatorWithConstructor(decoratorInterface, currentDomainModelType, propertyName);

                // Registro il decoratore
                registerDecorator({
                    target: currentDomainModelType,
                    propertyName,
                    options: { context: decoratorInterface.context },
                    constraints: [decoratorInterface],
                    validator: NumberValidator
                });

                // Aggiunge informazioni alla property sulla validazione sul tipo della classe
                NumberValidator.buildPropertyMetaData<NumberDecoratorInterface>(
                    currentDomainModelType,
                    propertyName,
                    decoratorInterface
                );
            });
            // #endregion Numerics

            // #region Bools
            currentDomainModelMetaData.bools?.forEach((boolMetaData: BoolMetaData) => {
                const propertyName: string = MetaDataUtils.toCamelCase(boolMetaData.name);

                // Verifica se è stato utilizzato un decoratore
                const decoratorData: BoolMetaData = BaseValidator.getPropertyMetaData(currentDomainModelType, propertyName) as BoolMetaData;

                // Mergio le informazione dai meta-dati con quelli (se esiste) del decoratore
                const decoratorInterface = {
                    context: decoratorData?.context,
                    isRequired: decoratorData?.isRequired ?? boolMetaData.isRequired,
                    displayNameKey: decoratorData?.descriptions.displayNameKey ?? boolMetaData.descriptions.displayNameKey,
                    displayName: decoratorData?.descriptions.displayName ?? boolMetaData.descriptions.displayName,
                    descriptionKey: decoratorData?.descriptions.descriptionKey ?? boolMetaData.descriptions.descriptionKey,
                    description: decoratorData?.descriptions.description ?? boolMetaData.descriptions.description,
                    shortNameKey: decoratorData?.descriptions.shortNameKey ?? boolMetaData.descriptions.shortNameKey,
                    shortName: decoratorData?.descriptions.shortNameKey ?? boolMetaData.descriptions.shortName,
                } as BoolDecoratorInterface;

                // Rimuovo il decoratore per il required se esisteva
                if (decoratorData?.isRequired) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.IS_DEFINED)
                }

                // Rimuovo il vecchio decoratore se esisteva
                if (decoratorData) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.CUSTOM_VALIDATION)
                }

                // Metodo di base per tutti i decoratori
                BaseValidator.initBaseValidatorWithConstructor(decoratorInterface, currentDomainModelType, propertyName);

                // Registro il decoratore
                registerDecorator({
                    target: currentDomainModelType,
                    propertyName,
                    options: { context: decoratorInterface.context },
                    constraints: [decoratorInterface],
                    validator: BoolValidator
                });

                // Aggiunge informazioni alla property sulla validazione sul tipo della classe
                BoolValidator.buildPropertyMetaData<BoolDecoratorInterface>(
                    currentDomainModelType,
                    propertyName,
                    decoratorInterface
                );
            });
            // #endregion Bools

            // #region Enums
            currentDomainModelMetaData.enums?.forEach((enumMetaData: EnumMetaData) => {
                const propertyName: string = MetaDataUtils.toCamelCase(enumMetaData.name);

                // Verifica se è stato utilizzato un decoratore
                const decoratorData: EnumMetaData = BaseValidator.getPropertyMetaData(currentDomainModelType, propertyName) as EnumMetaData;

                // Recurpero le informazioni dai meta-dati
                const decoratorInterface: EnumDecoratorInterface = {
                    context: decoratorData?.context,
                    displayNameKey: decoratorData?.descriptions.displayNameKey ?? enumMetaData.descriptions.displayNameKey,
                    displayName: decoratorData?.descriptions.displayName ?? enumMetaData.descriptions.displayName,
                    descriptionKey: decoratorData?.descriptions.descriptionKey ?? enumMetaData.descriptions.descriptionKey,
                    description: decoratorData?.descriptions.description ?? enumMetaData.descriptions.description,
                    shortNameKey: decoratorData?.descriptions.shortNameKey ?? enumMetaData.descriptions.shortNameKey,
                    shortName: decoratorData?.descriptions.shortName ?? enumMetaData.descriptions.shortName,
                    isRequired: decoratorData?.isRequired ?? enumMetaData.isRequired,
                } as EnumDecoratorInterface;

                // Rimuovo il decoratore per il required se esisteva
                if (decoratorData?.isRequired) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.IS_DEFINED)
                }

                // Rimuovo il vecchio decoratore se esisteva
                if (decoratorData) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.CUSTOM_VALIDATION)
                }

                // Metodo di base per tutti i decoratori
                BaseValidator.initBaseValidatorWithConstructor(decoratorInterface, currentDomainModelType, propertyName);

                // Registro il decoratore
                registerDecorator({
                    target: currentDomainModelType,
                    propertyName,
                    options: { context: decoratorInterface.context },
                    constraints: [decoratorInterface],
                    validator: EnumValidator
                });

                // Aggiunge informazioni alla property sulla validazione sul tipo della classe
                EnumValidator.buildPropertyMetaData<EnumDecoratorInterface>(
                    currentDomainModelType,
                    propertyName,
                    decoratorInterface
                );
            });
            // #endregion Enums

            // #region Externals
            currentDomainModelMetaData.externals?.forEach((externalMetaData: ExternalMetaData) => {
                const propertyName: string = MetaDataUtils.toCamelCase(externalMetaData.principalPropertyName);

                // Verifica se è stato utilizzato un decoratore
                const decoratorData: ExternalMetaData = BaseValidator.getPropertyMetaData(currentDomainModelType, propertyName) as ExternalMetaData;

                // Mergio le informazione dai meta-dati con quelli (se esiste) del decoratore
                const decoratorInterface = {
                    context: decoratorData?.context,
                    displayNameKey: decoratorData?.descriptions.displayNameKey ?? externalMetaData.descriptions.displayNameKey,
                    displayName: decoratorData?.descriptions.displayName ?? externalMetaData.descriptions.displayName,
                    descriptionKey: decoratorData?.descriptions.descriptionKey ?? externalMetaData.descriptions.descriptionKey,
                    description: decoratorData?.descriptions.description ?? externalMetaData.descriptions.description,
                    shortNameKey: decoratorData?.descriptions.shortNameKey ?? externalMetaData.descriptions.shortNameKey,
                    shortName: decoratorData?.descriptions.shortName ?? externalMetaData.descriptions.shortName,
                    isRequired: decoratorData?.isRequired ?? externalMetaData.isRequired,
                    isRemote: decoratorData?.isRemote ?? externalMetaData.isRemote,
                    parentIdentityPropertyPathName: decoratorData?.parentIdentityPropertyPathName ?? externalMetaData.parentIdentityPropertyPathName,
                    principalPName1: decoratorData?.associationProperties[0]?.principalPropertyName ?? externalMetaData?.associationProperties[0]?.principalPropertyName,
                    dependantPName1: decoratorData?.associationProperties[0]?.dependentPropertyName ?? externalMetaData?.associationProperties[0]?.dependentPropertyName,
                    principalPName2: decoratorData?.associationProperties[1]?.principalPropertyName ?? externalMetaData?.associationProperties[1]?.principalPropertyName,
                    dependantPName2: decoratorData?.associationProperties[1]?.dependentPropertyName ?? externalMetaData?.associationProperties[1]?.dependentPropertyName,
                    principalPName3: decoratorData?.associationProperties[2]?.principalPropertyName ?? externalMetaData?.associationProperties[2]?.principalPropertyName,
                    dependantPName3: decoratorData?.associationProperties[2]?.dependentPropertyName ?? externalMetaData?.associationProperties[2]?.dependentPropertyName,
                    principalPName4: decoratorData?.associationProperties[3]?.principalPropertyName ?? externalMetaData?.associationProperties[3]?.principalPropertyName,
                    dependantPName4: decoratorData?.associationProperties[3]?.dependentPropertyName ?? externalMetaData?.associationProperties[3]?.dependentPropertyName,
                } as ExternalDecoratorInterface;

                // Rimuovo il decoratore per il required se esisteva
                if (decoratorData?.isRequired) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.IS_DEFINED)
                }

                // Rimuovo il vecchio decoratore se esisteva
                if (decoratorData) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.CUSTOM_VALIDATION)
                }

                // Metodo di base per tutti i decoratori
                BaseValidator.initBaseValidatorWithConstructor(decoratorInterface, currentDomainModelType, propertyName);

                // Registro il decoratore
                registerDecorator({
                    target: currentDomainModelType,
                    propertyName,
                    options: { context: decoratorInterface.context },
                    constraints: [decoratorInterface],
                    validator: ExternalValidator
                });

                // Aggiunge informazioni alla property sulla validazione sul tipo della classe
                ExternalValidator.buildPropertyMetaData<ExternalDecoratorInterface>(
                    currentDomainModelType,
                    propertyName,
                    decoratorInterface
                );

                // recupero il tipo dal decoratore @Type
                const typeMetadata: TypeMetadata = defaultMetadataStorage.findTypeMetadata(currentDomainModelType, propertyName);
                if (typeMetadata && typeMetadata.typeFunction({
                    newObject: new currentDomainModelType()
                } as any)) {
                    this.recursiveProcessValidators(
                        externalMetaData.dependentAggregateMetaData.rootMetaData,
                        typeMetadata.typeFunction({
                            newObject: new currentDomainModelType()
                        } as TypeHelpOptions) as ClassConstructor<any>,
                        processedDomainModels,
                        currentDomainModelMetaData
                    );
                } else {
                    LogService.warn(`ATTENZIONE: decoratori automatici non gestiti per la classe ${externalMetaData.dependentAggregateMetaData.rootMetaData.name} associata alla property ${externalMetaData.principalPropertyName}`);
                }
            });
            // #endregion Externals

            // #region Internal
            currentDomainModelMetaData.internalRelations?.forEach((internalRelationMetaData: InternalRelationMetaData) => {
                const propertyName: string = MetaDataUtils.toCamelCase(internalRelationMetaData.principalPropertyName);

                // Verifica se è stato utilizzato un decoratore
                const decoratorData: InternalRelationMetaData = BaseValidator.getPropertyMetaData(currentDomainModelType, propertyName) as InternalRelationMetaData;

                // Mergio le informazione dai meta-dati con quelli (se esiste) del decoratore
                const decoratorInterface = {
                    context: decoratorData?.context,
                    displayNameKey: decoratorData?.descriptions.displayNameKey ?? internalRelationMetaData.descriptions.displayNameKey,
                    displayName: decoratorData?.descriptions.displayName ?? internalRelationMetaData.descriptions.displayName,
                    descriptionKey: decoratorData?.descriptions.descriptionKey ?? internalRelationMetaData.descriptions.descriptionKey,
                    description: decoratorData?.descriptions.description ?? internalRelationMetaData.descriptions.description,
                    shortNameKey: decoratorData?.descriptions.shortNameKey ?? internalRelationMetaData.descriptions.shortNameKey,
                    shortName: decoratorData?.descriptions.shortName ?? internalRelationMetaData.descriptions.shortName,
                    principalPName1: decoratorData?.associationProperties[0]?.principalPropertyName ?? internalRelationMetaData?.associationProperties[0]?.principalPropertyName,
                    dependantPName1: decoratorData?.associationProperties[0]?.dependentPropertyName ?? internalRelationMetaData?.associationProperties[0]?.dependentPropertyName,
                    principalPName2: decoratorData?.associationProperties[1]?.principalPropertyName ?? internalRelationMetaData?.associationProperties[1]?.principalPropertyName,
                    dependantPName2: decoratorData?.associationProperties[1]?.dependentPropertyName ?? internalRelationMetaData?.associationProperties[1]?.dependentPropertyName,
                    principalPName3: decoratorData?.associationProperties[2]?.principalPropertyName ?? internalRelationMetaData?.associationProperties[2]?.principalPropertyName,
                    dependantPName3: decoratorData?.associationProperties[2]?.dependentPropertyName ?? internalRelationMetaData?.associationProperties[2]?.dependentPropertyName,
                    principalPName4: decoratorData?.associationProperties[3]?.principalPropertyName ?? internalRelationMetaData?.associationProperties[3]?.principalPropertyName,
                    dependantPName4: decoratorData?.associationProperties[3]?.dependentPropertyName ?? internalRelationMetaData?.associationProperties[3]?.dependentPropertyName,
                } as InternalDecoratorInterface;

                // Rimuovo il vecchio decoratore se esisteva
                if (decoratorData) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.CUSTOM_VALIDATION)
                }

                // Metodo di base per tutti i decoratori
                BaseValidator.initBaseValidatorWithConstructor(decoratorInterface, currentDomainModelType, propertyName);

                // Registro il decoratore
                registerDecorator({
                    target: currentDomainModelType,
                    propertyName,
                    options: { context: decoratorInterface.context },
                    constraints: [decoratorInterface],
                    validator: InternalValidator
                });

                // Aggiunge informazioni alla property sulla validazione sul tipo della classe
                InternalValidator.buildPropertyMetaData<InternalDecoratorInterface>(
                    currentDomainModelType, propertyName, decoratorInterface);

                // recupero il tipo dal decoratore @Type
                const typeMetadata: TypeMetadata = defaultMetadataStorage.findTypeMetadata(currentDomainModelType, propertyName);
                if (typeMetadata && typeMetadata.typeFunction({
                    newObject: new currentDomainModelType()
                } as any)) {
                    this.recursiveProcessValidators(
                        internalRelationMetaData.dependentMetaData,
                        typeMetadata.typeFunction({
                            newObject: new currentDomainModelType()
                        } as TypeHelpOptions) as ClassConstructor<any>,
                        processedDomainModels,
                        currentDomainModelMetaData
                    );
                } else if (GenericsPropertiesTypeInspector.isApplied(new currentDomainModelType)) {

                    const camelCaseProperty = MetaDataUtils.toCamelCase(internalRelationMetaData.principalPropertyName);
                    const innerCurrentDomainModelType = ((new currentDomainModelType) as GenericsPropertiesTypeInterface).getGenericPropertyType(camelCaseProperty);
                    if (innerCurrentDomainModelType) {
                        this.recursiveProcessValidators(
                            internalRelationMetaData.dependentMetaData,
                            innerCurrentDomainModelType,
                            processedDomainModels,
                            currentDomainModelMetaData
                        );
                    } else {

                        let ignoreObject = null;
                        if (IgnoreAutomaticDecoratorWarningInspector.isApplied(new currentDomainModelType)) {
                            ignoreObject = IgnoreAutomaticDecoratorWarningInspector.getValue(new currentDomainModelType);
                        }

                        if (
                            ignoreObject == null ||
                            (ignoreObject[internalRelationMetaData.principalPropertyName] == null && ignoreObject[MetaDataUtils.toCamelCase(internalRelationMetaData.principalPropertyName)] == null) ||
                            (ignoreObject[internalRelationMetaData.principalPropertyName] != internalRelationMetaData.dependentMetaData.name && ignoreObject[internalRelationMetaData.principalPropertyName] != null) ||
                            (ignoreObject[MetaDataUtils.toCamelCase(internalRelationMetaData.principalPropertyName)] != internalRelationMetaData.dependentMetaData.name && ignoreObject[MetaDataUtils.toCamelCase(internalRelationMetaData.principalPropertyName)] != null)
                        ) {
                            LogService.warn(`[1<->1] ATTENZIONE: decoratori automatici non gestiti per la classe ${internalRelationMetaData.dependentMetaData.name} associata alla property ${internalRelationMetaData.principalPropertyName} della classe ${internalRelationMetaData.principalMetaData.name}`);
                            LogService.warn(`Prova ad aggiungere questo chiave nel decoratore ${GenericsPropertiesTypeInspector.DECORATOR_NAME} nella classe ${internalRelationMetaData.principalMetaData.name}:
    ${MetaDataUtils.toCamelCase(internalRelationMetaData.principalPropertyName)}: ${internalRelationMetaData.dependentMetaData.name}`);
                        }
                    }
                } else {

                    let ignoreObject = null;
                    if (IgnoreAutomaticDecoratorWarningInspector.isApplied(new currentDomainModelType)) {
                        ignoreObject = IgnoreAutomaticDecoratorWarningInspector.getValue(new currentDomainModelType);
                    }

                    if (
                        ignoreObject == null ||
                        (ignoreObject[internalRelationMetaData.principalPropertyName] == null && ignoreObject[MetaDataUtils.toCamelCase(internalRelationMetaData.principalPropertyName)] == null) ||
                        (ignoreObject[internalRelationMetaData.principalPropertyName] != internalRelationMetaData.dependentMetaData.name && ignoreObject[internalRelationMetaData.principalPropertyName] != null) ||
                        (ignoreObject[MetaDataUtils.toCamelCase(internalRelationMetaData.principalPropertyName)] != internalRelationMetaData.dependentMetaData.name && ignoreObject[MetaDataUtils.toCamelCase(internalRelationMetaData.principalPropertyName)] != null)
                    ) {
                        LogService.warn(`[1<->1] ATTENZIONE: decoratori automatici non gestiti per la classe ${internalRelationMetaData.dependentMetaData.name} associata alla property ${internalRelationMetaData.principalPropertyName} della classe ${internalRelationMetaData.principalMetaData.name}`);
                        LogService.warn(`Prova ad aggiungere questo decoratore nella classe ${internalRelationMetaData.principalMetaData.name}:
    ${GenericsPropertiesTypeInspector.DECORATOR_NAME}({
        ${MetaDataUtils.toCamelCase(internalRelationMetaData.principalPropertyName)}: ${internalRelationMetaData.dependentMetaData.name}
    })`);
                    }
                }
            });
            // #endregion Internal

            // #region Internal collection
            currentDomainModelMetaData.internalCollections?.forEach((internalCollectionMetaData: InternalCollectionMetaData) => {

                const propertyName: string = MetaDataUtils.toCamelCase(internalCollectionMetaData.principalPropertyName);

                // Verifica se è stato utilizzato un decoratore
                const decoratorData: InternalCollectionMetaData = BaseValidator.getPropertyMetaData(currentDomainModelType, propertyName) as InternalCollectionMetaData;

                // Mergio le informazione dai meta-dati con quelli (se esiste) del decoratore
                const decoratorInterface = {
                    displayNameKey: decoratorData?.descriptions.displayNameKey ?? internalCollectionMetaData.descriptions.displayNameKey,
                    displayName: decoratorData?.descriptions.displayName ?? internalCollectionMetaData.descriptions.displayName,
                    descriptionKey: decoratorData?.descriptions.descriptionKey ?? internalCollectionMetaData.descriptions.descriptionKey,
                    description: decoratorData?.descriptions.description ?? internalCollectionMetaData.descriptions.description,
                    shortNameKey: decoratorData?.descriptions.shortNameKey ?? internalCollectionMetaData.descriptions.shortNameKey,
                    shortName: decoratorData?.descriptions.shortName ?? internalCollectionMetaData.descriptions.shortName,
                    principalPName1: decoratorData?.associationProperties[0]?.principalPropertyName ?? internalCollectionMetaData?.associationProperties[0]?.principalPropertyName,
                    dependantPName1: decoratorData?.associationProperties[0]?.dependentPropertyName ?? internalCollectionMetaData?.associationProperties[0]?.dependentPropertyName,
                    principalPName2: decoratorData?.associationProperties[1]?.principalPropertyName ?? internalCollectionMetaData?.associationProperties[1]?.principalPropertyName,
                    dependantPName2: decoratorData?.associationProperties[1]?.dependentPropertyName ?? internalCollectionMetaData?.associationProperties[1]?.dependentPropertyName,
                    principalPName3: decoratorData?.associationProperties[2]?.principalPropertyName ?? internalCollectionMetaData?.associationProperties[2]?.principalPropertyName,
                    dependantPName3: decoratorData?.associationProperties[2]?.dependentPropertyName ?? internalCollectionMetaData?.associationProperties[2]?.dependentPropertyName,
                    principalPName4: decoratorData?.associationProperties[3]?.principalPropertyName ?? internalCollectionMetaData?.associationProperties[3]?.principalPropertyName,
                    dependantPName4: decoratorData?.associationProperties[3]?.dependentPropertyName ?? internalCollectionMetaData?.associationProperties[3]?.dependentPropertyName,
                    isUniqueFunc: decoratorData?.isUniqueFunc ?? internalCollectionMetaData?.isUniqueFunc,
                    uniqueFields: decoratorData?.uniqueFields ?? internalCollectionMetaData?.uniqueFields,
                    context: decoratorData?.context,
                    isCollection: true
                } as InternalDecoratorInterface;

                // Rimuovo il vecchio decoratore se esisteva
                if (decoratorData) {
                    this.removePropertyDecorator(currentDomainModelType, propertyName, ValidationTypes.CUSTOM_VALIDATION)
                }

                // Metodo di base per tutti i decoratori
                BaseValidator.initBaseValidatorWithConstructor(decoratorInterface, currentDomainModelType, propertyName);

                // Registro il decoratore
                registerDecorator({
                    target: currentDomainModelType,
                    propertyName,
                    options: { context: decoratorData?.context },
                    constraints: [decoratorInterface],
                    validator: InternalValidator
                });

                // Aggiunge informazioni alla property sulla validazione sul tipo della classe
                InternalValidator.buildPropertyMetaData<InternalDecoratorInterface>(
                    currentDomainModelType, propertyName, decoratorInterface);

                // recupero il tipo dal decoratore @Type
                const typeMetadata: TypeMetadata = defaultMetadataStorage.findTypeMetadata(currentDomainModelType, propertyName);

                if (typeMetadata && typeMetadata.typeFunction({
                    newObject: new currentDomainModelType()
                } as any)) {

                    const collectionDomainModelType = typeMetadata.typeFunction({
                        newObject: new currentDomainModelType()
                    } as any) as any;
                    // recupero il tipo dell'elemento dal tipo della collection
                    const collectionElementDomainModelType = ModelTypeInspector.getValue(new collectionDomainModelType());
                    if (collectionElementDomainModelType) {
                        this.recursiveProcessValidators(
                            internalCollectionMetaData.dependentMetaData,
                            collectionElementDomainModelType,
                            processedDomainModels,
                            currentDomainModelMetaData
                        );
                    } else {
                        LogService.warn(`[1->N] ATTENZIONE: decoratori automatici non gestiti per la classe ${internalCollectionMetaData.dependentMetaData.name} associata alla property ${internalCollectionMetaData.principalPropertyName} della classe ${internalCollectionMetaData.principalMetaData.name}`);
                        LogService.warn(`Prova ad aggiungere questo decoratore alla classe ${collectionDomainModelType.prototype.constructor.name}: ${ModelTypeInspector.DECORATOR_NAME}(${internalCollectionMetaData.dependentMetaData.name})`);
                    }
                } else if (GenericsPropertiesTypeInspector.isApplied(new currentDomainModelType)) {

                    const camelCaseProperty = MetaDataUtils.toCamelCase(internalCollectionMetaData.principalPropertyName);
                    const collectionDomainModelType = ((new currentDomainModelType) as GenericsPropertiesTypeInterface).getGenericPropertyType(camelCaseProperty);

                    if (collectionDomainModelType) {

                        // recupero il tipo dell'elemento dal tipo della collection
                        const collectionElementDomainModelType = ModelTypeInspector.getValue(new collectionDomainModelType());
                        if (collectionElementDomainModelType) {
                            this.recursiveProcessValidators(
                                internalCollectionMetaData.dependentMetaData,
                                collectionElementDomainModelType,
                                processedDomainModels,
                                currentDomainModelMetaData
                            );
                        } else {
                            LogService.warn(`[1->N] ATTENZIONE: decoratori automatici non gestiti per la classe ${internalCollectionMetaData.dependentMetaData.name} associata alla property ${camelCaseProperty} della classe ${internalCollectionMetaData.principalMetaData.name}`);
                            LogService.warn(`Prova ad aggiungere questo decoratore alla classe ${collectionDomainModelType.prototype.constructor.name}: ${ModelTypeInspector.DECORATOR_NAME}(${internalCollectionMetaData.dependentMetaData.name})`);
                        }
                    } else {
                        LogService.warn(`[1->N] ATTENZIONE: decoratori automatici non gestiti per la classe ${internalCollectionMetaData.dependentMetaData.name} associata alla property ${camelCaseProperty} della classe ${internalCollectionMetaData.principalMetaData.name}`);
                        LogService.warn(`Prova ad aggiungere questo chiave nel decoratore ${GenericsPropertiesTypeInspector.DECORATOR_NAME} nella classe ${internalCollectionMetaData.principalMetaData.name}:
${MetaDataUtils.toCamelCase(internalCollectionMetaData.principalPropertyName)}: ${internalCollectionMetaData.dependentMetaData.name}`);
                    }
                } else {
                    const camelCaseProperty = MetaDataUtils.toCamelCase(internalCollectionMetaData.principalPropertyName);

                    LogService.warn(`[1->N] ATTENZIONE: decoratori automatici non gestiti per la classe ${internalCollectionMetaData.dependentMetaData.name} associata alla property ${camelCaseProperty} della classe ${internalCollectionMetaData.principalMetaData.name}`);
                    LogService.warn(`Prova ad aggiungere questo decoratore nella classe ${internalCollectionMetaData.principalMetaData.name}:
${GenericsPropertiesTypeInspector.DECORATOR_NAME}({
    ${MetaDataUtils.toCamelCase(camelCaseProperty)}: ${internalCollectionMetaData.dependentMetaData.name}
})`);
                }
            });
            // #endregion Internal collection
        }
    }

    removePropertyDecorator(target: any, propertyName: string, type: string) {
        const metadataStorage = getMetadataStorage() as MetadataStorage;
        const validationMetadatas = (metadataStorage as any).validationMetadatas as Map<any, ValidationMetadata[]>;
        const foundMetaData = validationMetadatas.get(target);
        const foundIndex = foundMetaData?.findIndex((v) =>
            v.target === target && v.type === type && v.propertyName === propertyName,
        );
        if (foundIndex > -1) {
            foundMetaData.splice(foundIndex, 1);
            if (foundMetaData?.length === 0) {
                validationMetadatas.delete(target);
            } else {
                validationMetadatas.set(target, foundMetaData);
            }

        }
    }

    // TODO Tommy da revisionare e testare
    private recursiveMockDomainModel(
        metadata: AggregateMetaData,
        currentDomainModelMetadata: DomainModelMetaData,
        currentDomainModel: ModelInterface,
        processedDomainModels = []
    ) {
        if (processedDomainModels.find((element) => element === currentDomainModel) === undefined) {
            processedDomainModels.push(currentDomainModel);
            currentDomainModel.isMock = true;
            for (const relation of currentDomainModelMetadata.internalRelations) {
                const subDomainModel = currentDomainModel.getPropertyValue(
                    MetaDataUtils.toCamelCase(relation.principalPropertyName)) as ModelInterface;
                if (subDomainModel != null) {
                    const subCurrentEntityMetadata = metadata.domainModels
                        .find((el: DomainModelMetaData) => el.name === relation.dependentMetaData.name);
                    // if (relation.multiplicity === PrincipalToDependentMultiplicity.OneToMany ||
                    //     relation.multiplicity === PrincipalToDependentMultiplicity.ManyToMany) {
                    //     const entityCollection = (subEntity as any) as EntityCollectionInterface;
                    //     for (const item of entityCollection.collectionItems) {
                    //         item.isMock = true;
                    //         this.recursiveMockEntity(metadata, subCurrentEntityMetadata, item, processedDomainModels);
                    //     }
                    // } else {
                    subDomainModel.isMock = true;
                    this.recursiveMockDomainModel(metadata, subCurrentEntityMetadata, subDomainModel, processedDomainModels);
                    // }
                }
            }
        }
    }

    private buildGridColumnsForDomainModelMetaData(
        domainModelMetaData: DomainModelMetaData,
        aggregateMetaData: AggregateMetaData,
        processedExternals: string[],
        pathName = '',
        inCollection = false
    ) {

        const positionOffset = 0;

        // Per ogni collection
        for (const internalCollection of domainModelMetaData.internalCollections) {

            const excludedPropertyList = ColumnsUtils.getGridExcludedPropertyListForInternalCollection(internalCollection);

            // Recupero le colonne
            const columns = ColumnsUtils.getGridColumnsFromDomainModelMetaData(internalCollection.dependentMetaData, aggregateMetaData, null, excludedPropertyList, pathName, positionOffset);

            columns.metadataShortDescription = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(
                internalCollection.descriptions.displayNameKey) : internalCollection.descriptions.displayName;

            columns.metadataDescription = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(
                internalCollection.descriptions.descriptionKey) : internalCollection.descriptions.description;

            columns.push(...this.recursiveBuildGridColumns(
                internalCollection.dependentMetaData,
                aggregateMetaData,
                pathName,
                undefined,
                processedExternals
            ));

            this._gridColumns.set(internalCollection.dependentMetaData.name, columns);

        }

        // Per ogni internal relation
        for (const internalRelation of domainModelMetaData.internalRelations) {

            this.buildGridColumnsForDomainModelMetaData(
                internalRelation.dependentMetaData,
                aggregateMetaData,
                processedExternals,
                inCollection ? pathName?.length > 0 ? (pathName + internalRelation.principalPropertyName) : internalRelation.principalPropertyName : ''
            );

        }

        // Per ogni external
        for (const external of domainModelMetaData.externals) {
            external.dependentAggregateMetaData.useMessageResourceKey = aggregateMetaData.useMessageResourceKey;
            if (processedExternals.indexOf(external.dependentAggregateMetaData.rootFullName) === -1) {
                // Evito il loop negli external annidati dello stesso tipo
                processedExternals.push(external.dependentAggregateMetaData.rootFullName);
                this.buildGridColumnsForDomainModelMetaData(
                    external.dependentAggregateMetaData.rootMetaData,
                    external.dependentAggregateMetaData,
                    processedExternals,
                    inCollection ? pathName?.length > 0 ? (pathName + external.principalPropertyName) : external.principalPropertyName : ''
                );
            }

        }
    }

    private recursiveBuildGridColumns(
        metaData: DomainModelMetaData,
        aggregateMetaData: AggregateMetaData,
        pathName: string = '',
        baseLabelPath = '',
        processedExternals: string[] = [],
    ): ColumnInfoCollection {

        const columns = new ColumnInfoCollection();

        // Internal relation
        for (const internalRelation of metaData.internalRelations) {

            const excludedPropertyList = ColumnsUtils.getGridExcludedPropertyListForInternalRelation(internalRelation);
            const displayName = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(internalRelation.dependentMetaData.descriptions.displayNameKey) : internalRelation.dependentMetaData.descriptions.displayName;
            const property = MetaDataUtils.toCamelCase(internalRelation.principalPropertyName);

            columns.push(...ColumnsUtils.getGridColumnsFromDomainModelMetaData(
                internalRelation.dependentMetaData,
                aggregateMetaData,
                pathName?.length > 0 ? pathName + '.' + property : property,
                excludedPropertyList,
                baseLabelPath.length === 0 ? displayName : baseLabelPath + '->' + displayName
            ));
            columns.push(...this.recursiveBuildGridColumns(internalRelation.dependentMetaData, aggregateMetaData, pathName?.length > 0 ? pathName + '.' + property : property))

        }

        // External
        for (const externalRelation of metaData.externals) {

            if (processedExternals.indexOf(externalRelation.dependentAggregateMetaData.rootFullName) === -1) {

                // Evito il loop negli external annidati dello stesso tipo
                processedExternals.push(externalRelation.dependentAggregateMetaData.rootFullName);

                const excludedPropertyList = ColumnsUtils.getGridExcludedPropertyListForExternalRelation(externalRelation);

                // Per sicurezza allineo useMessageResourceKey nel dependentAggregateMetaData
                externalRelation.dependentAggregateMetaData.useMessageResourceKey = aggregateMetaData.useMessageResourceKey;

                const displayName = externalRelation.dependentAggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(externalRelation.dependentAggregateMetaData.rootMetaData.descriptions.displayNameKey) : externalRelation.dependentAggregateMetaData.rootMetaData.descriptions.displayName;
                const property = MetaDataUtils.toCamelCase(externalRelation.principalPropertyName);

                columns.push(...ColumnsUtils.getGridColumnsFromExternal(
                    externalRelation,
                    externalRelation.dependentAggregateMetaData,
                    pathName?.length > 0 ? pathName + '.' + property : property,
                    excludedPropertyList,
                    baseLabelPath.length === 0 ? displayName : baseLabelPath + '->' + displayName,
                    this.layoutMetaData != null // Sono in un layout custom?
                ));
                columns.push(...this.recursiveBuildGridColumns(
                    externalRelation.dependentAggregateMetaData.rootMetaData,
                    externalRelation.dependentAggregateMetaData,
                    pathName?.length > 0 ? pathName + '.' + property : property,
                    undefined,
                    processedExternals
                ))

            }
        }

        // Internal collection
        if (metaData.internalCollections.length > 0) {
            this.buildGridColumnsForDomainModelMetaData(
                metaData,
                aggregateMetaData,
                processedExternals,
                pathName,
                true
            )
        }

        return columns;
    }

    private async beginReportPolling(
        spoolControllerAddress: string,
        operationIdentity: OperationIdentity,
        reportLabel: string,
        onProgress?: (data: { operationState: OperationState, attachments: AttachmentIdentity[], processResult: OperationStateResultDto }) => Promise<void>,
        onStop?: () => Promise<void>
    ): Promise<SpoolProcess<OperationStateResultDto>> {
        const process = new SpoolProcess<OperationStateResultDto>();
        process.operationIdentity = operationIdentity;
        process.spoolControllerAddress = spoolControllerAddress;
        process.downloadAttachements = true;
        process.CheckProcess$ = this.apiClient.getOperationState(spoolControllerAddress, operationIdentity).pipe(map((data) => {
            return {
                operationState: data.result.currentOperationState,
                processResult: data.result,
                attachments: data.result.attachmentIdentities
            }
        }), tap((data) => {
            if (onProgress) {
                onProgress(data);
            }

            if (data.operationState === OperationState.EndedWithError || data.operationState === OperationState.EndedWithSuccess || data.operationState == OperationState.Stopped) {
                if (onStop) {
                    onStop();
                }
            }

            if (data.operationState === OperationState.EndedWithError) {
                this.toastMessageService.showToast({
                    message: MessageResourceManager.Current.getMessageWithStrings('std_GenerateReportEndedWithError', reportLabel),
                    title: MessageResourceManager.Current.getMessage(MessageCodes.Warning),
                    type: ToastMessageType.warn
                })
            }

            if (data.operationState === OperationState.Stopped) {
                this.toastMessageService.showToast({
                    message: MessageResourceManager.Current.getMessageWithStrings('std_GenerateReportStopped', reportLabel),
                    title: MessageResourceManager.Current.getMessage(MessageCodes.Warning),
                    type: ToastMessageType.warn
                })
            }

        }));
        this.rootViewModelChangedFromNow.pipe(take(1)).subscribe(() => {
            if (onStop) {
                onStop();
            }
        })

        this.spoolProcessStarted.next(process);
        this.addSpoolProcess<OperationStateResultDto>(process);
        return process;
    }

    private async generateReportWithCommand<TParams, TRequestDto extends PrintOutDemandDto<TParams>>(
        command: UICommandInterface<any, any, OperationIdentity>,
        reportInfo: ReportInfoDto,
        paramsGetter: () => TParams,
        controllerAddress: string,
        dtoType: ClassConstructor<TRequestDto>
    ) {

        if (await firstValueFrom(command.loading$) == true) {
            this.openSpoolProcess(command.data as OperationIdentity);
            return;
        }

        const dto = new dtoType();
        dto.printOutData = paramsGetter();
        dto.reportFileSelection.customReportItemIdentity = reportInfo.customReportItemIdentity;
        dto.reportFileSelection.standardReportIdentity = reportInfo.standardReportIdentity;

        const response = await firstValueFrom(this.apiClient.printOutFileDemand<TParams, TRequestDto>(controllerAddress, dto));

        if (response.operationSuccedeed) {
            this.toastMessageService.showToastsFromResponse(response);

            const onProgress = async (data: { operationState: OperationState, attachments: AttachmentIdentity[], processResult: OperationStateResultDto }) => {
                command.loading$.next(true)
                this.moreOptionsMenuItemUpdated.next();
                this.mobileMenuItemUpdated.next();
            };

            const onStop = async () => {
                command.loading$.next(false)
                this.moreOptionsMenuItemUpdated.next();
                this.mobileMenuItemUpdated.next();
            };

            command.data = response.result;
            command.loading$.next(true)
            this.moreOptionsMenuItemUpdated.next();
            this.mobileMenuItemUpdated.next();

            await this.beginReportPolling(controllerAddress, response.result, command.displayName, onProgress, onStop);
        } else {
            this.showFromResponse(response, MsgClearMode.ClearAllMessages);
            this.spoolProcessError.next(null);
        }
    }
}
