import { Input, OnChanges, OnDestroy, Component } from '@angular/core';
import { ExternalViewModelInterface } from '../../../view-models/external-view-model.interface';
import { take, takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ZoomResult } from '../../../domain-models/zoom/zoom-result';
import { NewBasePropertyTextBox } from './new-base-property-text-box';
import { FocusableElement, tabbable } from 'tabbable';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    template: ''
})
export abstract class BaseExternalPropertyTextBox<T> extends NewBasePropertyTextBox<T> implements OnChanges {

    @Input() externalPropertyViewModel: ExternalViewModelInterface;

    eventSubscriptions: Array<Subscription> = new Array<Subscription>();
    nextClickEvent: any;
    zoomPending = false;
    nextFocus: FocusableElement;
    tabNext: boolean;
    tabPrevious: boolean;

    override focus(showErroTooltip: boolean) {
        super.focus(showErroTooltip);
        
        setTimeout(() => {
            this.externalPropertyViewModel.gotFocus(this.propertyViewModel);
        }, 1);    
    }

    override init(): void {
        super.init();

        // Mi sottoscrivo alla code properties in modo tale se qualcuno richiede il focus
        // lo passo a quello principale
        this.externalPropertyViewModel.codeProperties.forEach((pvm) => {
            pvm.onFocusRequested.pipe(this.untilDestroyedThis()).subscribe(() => {
                this.focus(this.propertyViewModel.hasErrors);
            });
        });
    }

    override onBlur(e: any) {
        if (!this.isDisabled && this.externalPropertyViewModel.isEnabled) {
            this.externalPropertyViewModel.validate();
        }

        setTimeout(() => {
            this.externalPropertyViewModel.lostFocus();
        }, 0);

        this.blur.emit(e);
    }

    override updateModel() {
        if (typeof this.inputValue === 'number' && this.inputValue === 0) {
            this.valueChange.emit(null);
        } else {
            this.valueChange.emit(this.inputValue);
        }
        if (!this.modelUpdate ||
            (this.propertyViewModel?.value == null &&
                (this.inputValue == null || (typeof this.inputValue === 'string' && this.inputValue === '')
                )
            ) || ((this.propertyViewModel?.value === this.inputValue) && !(typeof this.inputValue === 'number' && this.inputValue === 0)
            )) {
            return;
        }
        if (typeof this.inputValue === 'number' && this.inputValue === 0) {
            this.propertyViewModel.value = null;
        } else {
            this.propertyViewModel.value = this.inputValue;
        }
    }

    override onFocusOut(ev: any) {
        this.nextFocus = ev.relatedTarget;
        this.tabNext = false;
        this.tabPrevious = false;
        super.onFocusOut(ev);
    }

    async zoom(inputRef: NgSelectComponent = null) {
        if (this.externalPropertyViewModel.zoomSearchIsEnabled && !this.zoomPending) {
            this.zoomPending = true;
            this.updateModel();
            this.externalPropertyViewModel.eventDispatcher.beforeZoomOpen.pipe(take(1)).subscribe(() => {
                if (inputRef) {
                    inputRef.close();
                }                
            })
            if (this.externalPropertyViewModel.decodeInProgress) {
                this.externalPropertyViewModel.decodeCompleted.pipe(take(1)).subscribe(async res => {
                    await this.showZoom();
                });
            } else {
                await this.showZoom();
            }
        }
    }

    startPresentation(event = null, showRead = true, externalReturn = true) {
        let newTab = false;
        if (event && (event.altKey)) {
            newTab = true;
            event.preventDefault();
            event.stopPropagation();
            // unselect accidentally selected text (browser default behaviour)
            document.getSelection().removeAllRanges();
        }
        this.externalPropertyViewModel.startPresentation(this.propertyViewModel, newTab, showRead, undefined, externalReturn);
    }

    protected override onKeyDown(ev: KeyboardEvent) {
        super.onKeyDown(ev);

        this.nextClickEvent = null;

        if (ev.key === 'Tab') {

            const tabbableList = tabbable(document.body);
            const size = tabbableList.length;
            let currentIndex = tabbableList.indexOf(this.input);
            if (ev.shiftKey) {
                currentIndex--;
                this.tabNext = false;
                this.tabPrevious = true;
            } else {
                currentIndex++;
                this.tabNext = true;
                this.tabPrevious = false;
            }

            if (currentIndex > 0) {
                this.nextFocus = tabbableList[currentIndex % size];
            } else {
                this.nextFocus = tabbableList[size + (currentIndex % size)];
            }

            this.updateModel();
        }
    }

    private async showZoom() {
        const zoomResult = await this.externalPropertyViewModel.findExternal(this.propertyViewModel);
        if (zoomResult.result === ZoomResult.empty().result && this.externalPropertyViewModel.hasDecodeError) {
        }
        this.zoomPending = false;

        this.input.focus();
        // this.decodeTooltip.hide();
    }
}
