import * as i0 from '@angular/core';
import { Injectable, Component, NgModule } from '@angular/core';
import { PropertiesPluginIdentifier, AnalyticsPluginIdentifier } from '@microsoft/applicationinsights-common';
import { isFunction, arrForEach, BaseTelemetryPlugin, getLocation, generateW3CId, _throwInternal } from '@microsoft/applicationinsights-core-js';
import { NavigationEnd } from '@angular/router';

class ApplicationinsightsAngularpluginErrorService {
    constructor() {
        this.errorServices = [];
        if (ApplicationinsightsAngularpluginErrorService.instance === null) {
            ApplicationinsightsAngularpluginErrorService.instance = this;
        }
    }
    set plugin(analyticsPlugin) {
        this.analyticsPlugin = analyticsPlugin;
    }
    addErrorHandler(errorService) {
        if (errorService && isFunction(errorService.handleError)) {
            this.errorServices.push(errorService);
        }
    }
    removeErrorHandler(errorService) {
        if (errorService && isFunction(errorService.handleError)) {
            const idx = this.errorServices.indexOf(errorService);
            if (idx !== -1) {
                this.errorServices.splice(idx, 1);
            }
        }
    }
    handleError(error) {
        if (this.analyticsPlugin) {
            this.analyticsPlugin.trackException({ exception: error });
        }
        if (this.errorServices && this.errorServices.length > 0) {
            arrForEach(this.errorServices, errorService => {
                if (isFunction(errorService.handleError)) {
                    errorService.handleError(error);
                }
            });
        }
    }
}
ApplicationinsightsAngularpluginErrorService.instance = null;
ApplicationinsightsAngularpluginErrorService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "14.2.12", ngImport: i0, type: ApplicationinsightsAngularpluginErrorService, deps: [], target: i0.ɵɵFactoryTarget.Injectable });
ApplicationinsightsAngularpluginErrorService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "14.2.12", ngImport: i0, type: ApplicationinsightsAngularpluginErrorService, providedIn: 'root' });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "14.2.12", ngImport: i0, type: ApplicationinsightsAngularpluginErrorService, decorators: [{
            type: Injectable,
            args: [{
                    providedIn: 'root'
                }]
        }], ctorParameters: function () { return []; } });

// eslint-disable-next-line @angular-eslint/component-class-suffix
class AngularPlugin extends BaseTelemetryPlugin {
    constructor() {
        super();
        this.priority = 186;
        this.identifier = 'AngularPlugin';
        this._angularCfg = null;
        this._eventSubscription = null;
        let self = this;
        self._doTeardown = (unloadCtx, unloadState, asyncCallback) => {
            if (this.analyticsPlugin && ApplicationinsightsAngularpluginErrorService.instance !== null) {
                ApplicationinsightsAngularpluginErrorService.instance.plugin = null;
                if (self._angularCfg) {
                    if (self._angularCfg.errorServices && Array.isArray(self._angularCfg.errorServices)) {
                        arrForEach(self._angularCfg.errorServices, (errorService) => {
                            ApplicationinsightsAngularpluginErrorService.instance.removeErrorHandler(errorService);
                        });
                    }
                }
            }
            if (self._eventSubscription) {
                self._eventSubscription.unsubscribe();
                self._eventSubscription = null;
            }
            self.analyticsPlugin = null;
            self.propertiesPlugin = null;
            self._angularCfg = null;
        };
    }
    initialize(config, core, extensions, pluginChain) {
        super.initialize(config, core, extensions, pluginChain);
        const self = this;
        const ctx = self._getTelCtx();
        const extConfig = ctx.getExtCfg(self.identifier, { router: null, errorServices: null });
        self.propertiesPlugin = core.getPlugin(PropertiesPluginIdentifier)?.plugin;
        self.analyticsPlugin = core.getPlugin(AnalyticsPluginIdentifier)?.plugin;
        if (self.analyticsPlugin) {
            if (ApplicationinsightsAngularpluginErrorService.instance !== null) {
                ApplicationinsightsAngularpluginErrorService.instance.plugin = self.analyticsPlugin;
                if (extConfig.errorServices && Array.isArray(extConfig.errorServices)) {
                    arrForEach(extConfig.errorServices, (errorService) => {
                        ApplicationinsightsAngularpluginErrorService.instance.addErrorHandler(errorService);
                    });
                }
            }
        }
        if (extConfig.router) {
            let isPageInitialLoad = true;
            const pageViewTelemetry = {
                uri: extConfig.router.url
            };
            self.trackPageView(pageViewTelemetry);
            self._eventSubscription = extConfig.router.events.subscribe(event => {
                if (self.isInitialized()) {
                    if (event instanceof NavigationEnd) {
                        // for page initial load, do not call trackPageView twice
                        if (isPageInitialLoad) {
                            isPageInitialLoad = false;
                            return;
                        }
                        const pvt = {
                            uri: extConfig.router.url,
                            properties: { duration: 0 } // SPA route change loading durations are undefined, so send 0
                        };
                        self.trackPageView(pvt);
                    }
                }
            });
        }
    }
    /**
     * Add Part A fields to the event
     *
     * @param event The event that needs to be processed
     */
    processTelemetry(event, itemCtx) {
        this.processNext(event, itemCtx);
    }
    trackPageView(pageView) {
        const self = this;
        if (self.analyticsPlugin) {
            const location = getLocation();
            if (self.propertiesPlugin && self.propertiesPlugin.context && self.propertiesPlugin.context.telemetryTrace) {
                self.propertiesPlugin.context.telemetryTrace.traceID = generateW3CId();
                self.propertiesPlugin.context.telemetryTrace.name = location && location.pathname || '_unknown_';
            }
            self.analyticsPlugin.trackPageView(pageView);
        }
        else {
            _throwInternal(self.diagLog(), 1 /* eLoggingSeverity.CRITICAL */, 64 /* _eInternalMessageId.TelemetryInitializerFailed */, 'Analytics plugin is not available, Angular plugin telemetry will not be sent: ');
        }
    }
}
AngularPlugin.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "14.2.12", ngImport: i0, type: AngularPlugin, deps: [], target: i0.ɵɵFactoryTarget.Component });
AngularPlugin.ɵcmp = i0.ɵɵngDeclareComponent({ minVersion: "14.0.0", version: "14.2.12", type: AngularPlugin, selector: "lib-applicationinsights-angularplugin-js", usesInheritance: true, ngImport: i0, template: ``, isInline: true });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "14.2.12", ngImport: i0, type: AngularPlugin, decorators: [{
            type: Component,
            args: [{ selector: 'lib-applicationinsights-angularplugin-js', template: `` }]
        }], ctorParameters: function () { return []; } });

class ApplicationinsightsAngularpluginJsModule {
}
ApplicationinsightsAngularpluginJsModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "14.2.12", ngImport: i0, type: ApplicationinsightsAngularpluginJsModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
ApplicationinsightsAngularpluginJsModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "14.2.12", ngImport: i0, type: ApplicationinsightsAngularpluginJsModule, declarations: [AngularPlugin], exports: [AngularPlugin] });
ApplicationinsightsAngularpluginJsModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "14.2.12", ngImport: i0, type: ApplicationinsightsAngularpluginJsModule, providers: [
        ApplicationinsightsAngularpluginErrorService
    ] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "14.2.12", ngImport: i0, type: ApplicationinsightsAngularpluginJsModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [AngularPlugin],
                    imports: [],
                    exports: [AngularPlugin],
                    providers: [
                        ApplicationinsightsAngularpluginErrorService
                    ]
                }]
        }] });

/*
 * Public API Surface of applicationinsights-angularplugin-js
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AngularPlugin, ApplicationinsightsAngularpluginErrorService, ApplicationinsightsAngularpluginJsModule };

