import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, Directive, Input, NgModule } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

/**
 * Injection token for gravatar configuration
 */
const GRAVATAR_CONFIG_TOKEN = new InjectionToken('gravatarCustom.config');

// Visit https://en.gravatar.com/site/implement/images for more information
var FALLBACK;
(function (FALLBACK) {
    FALLBACK["blank"] = "blank";
    FALLBACK["identicon"] = "identicon";
    FALLBACK["mm"] = "mm";
    FALLBACK["mp"] = "mp";
    FALLBACK["monsterid"] = "monsterid";
    FALLBACK["retro"] = "retro";
    FALLBACK["robohash"] = "robohash";
    FALLBACK["wavatar"] = "wavatar";
})(FALLBACK || (FALLBACK = {}));
var RATING;
(function (RATING) {
    RATING["g"] = "g";
    RATING["pg"] = "pg";
    RATING["r"] = "r";
    RATING["x"] = "x";
})(RATING || (RATING = {}));

const DEFAULT_CONFIG = {
    fallback: FALLBACK.retro,
    size: 40,
    cornerRadius: 0,
    preferGravatar: false,
    hasBorder: false,
    borderColor: '#000000',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    round: true,
    backgroundColor: 'transparent',
    rating: RATING.g,
    ratio: 2,
};

class NgxGravatarService {
    constructor(gravatarConfig) {
        this.gravatarConfig = gravatarConfig;
        this.defaultConfig = { ...DEFAULT_CONFIG };
        if (this.gravatarConfig) {
            this.defaultConfig = { ...this.defaultConfig, ...this.gravatarConfig };
        }
    }
    /**
     * Return defaultConfig object
     */
    getDefaultConfig() {
        return this.defaultConfig;
    }
    /**
     * Generate gravatar url
     *
     * @param email is a string. If email is not a string, email will be set to empty string "" by default
     * @param md5Hash is a string. If value is given it will take precedence over email.
     * @param size number
     * @param rating string
     * @param fallback string
     * @return gravatar url
     */
    generateGravatarUrl(email, md5Hash, size = this.defaultConfig.size, rating = this.defaultConfig.rating, fallback = this.defaultConfig.fallback) {
        let emailHash;
        if (md5Hash) {
            emailHash = md5Hash;
        }
        else {
            try {
                email = email.trim().toLowerCase();
            }
            catch (e) {
                console.error(`[ngx-gravatar] - Email (${email}) is not a string. Empty string is used as a default email.`);
                email = '';
            }
            emailHash = Md5.hashStr(email);
        }
        return `https://www.gravatar.com/avatar/${emailHash}?s=${size}&r=${rating}&d=${fallback}`;
    }
}
NgxGravatarService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.2.6", ngImport: i0, type: NgxGravatarService, deps: [{ token: GRAVATAR_CONFIG_TOKEN, optional: true }], target: i0.ɵɵFactoryTarget.Injectable });
NgxGravatarService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "13.2.6", ngImport: i0, type: NgxGravatarService, providedIn: 'root' });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.2.6", ngImport: i0, type: NgxGravatarService, decorators: [{
            type: Injectable,
            args: [{
                    providedIn: 'root',
                }]
        }], ctorParameters: function () { return [{ type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [GRAVATAR_CONFIG_TOKEN]
                }] }]; } });

/* eslint-disable @angular-eslint/directive-selector */
class NgxGravatarDirective {
    constructor(elementRef, renderer, gravatarService) {
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.gravatarService = gravatarService;
        this.style = {};
        this.initialized = false;
        this.defaultConfig = this.gravatarService.getDefaultConfig();
        // Listen for error when fetching custom src
        this.renderer.listen(this.elementRef.nativeElement, 'error', (event) => {
            if (!this.isGravatarUsed) {
                this.initializeAvatar(true); // Force using gravatar
            }
        });
    }
    ngOnInit() {
        this.initializeAvatar();
        this.initialized = true;
        this.isGravatarUsed = false;
    }
    ngOnChanges() {
        if (this.initialized) {
            this.initializeAvatar();
        }
    }
    /**
     * Set default values for user inputs if they are not provided
     */
    setDefaultValues() {
        this.size = this.computeSize();
        this.ratio =
            this.ratio === undefined ? this.defaultConfig.ratio : this.ratio;
        this.requestedSize = this.size * this.ratio;
        this.round =
            this.round === undefined ? this.defaultConfig.round : this.round;
        this.cornerRadius =
            this.cornerRadius === undefined
                ? this.defaultConfig.cornerRadius
                : this.cornerRadius;
        this.preferGravatar =
            this.preferGravatar === undefined
                ? this.defaultConfig.preferGravatar
                : this.preferGravatar;
    }
    /**
     * Initialize avatar.
     * Custom source has higher priority if preferGravatar is not set on.
     * Finally, set styles for the avatar.
     */
    initializeAvatar(forcedGravatar) {
        this.setDefaultValues();
        let url = '';
        if (this.preferGravatar || forcedGravatar) {
            url = this.gravatarService.generateGravatarUrl(this.email, this.md5Hash, this.requestedSize, this.rating, this.fallback);
            this.isGravatarUsed = true;
        }
        else {
            // this.preferGravatar == false
            if (this.src) {
                url = this.src;
            }
            else {
                // fallback to gravatar
                url = this.gravatarService.generateGravatarUrl(this.email, this.md5Hash, this.requestedSize, this.rating, this.fallback);
                this.isGravatarUsed = true;
            }
        }
        this.renderer.setProperty(this.elementRef.nativeElement, 'src', url);
        this.setStyle(this.avatarStyle());
    }
    /**
     * Compute the size of the avatar
     *
     * @return size
     */
    computeSize() {
        let size = this.size === undefined ? this.defaultConfig.size : this.size;
        if (this.style && this.style.width) {
            try {
                const width = this.style.width.trim();
                if (width.match(/^\d+px$/)) {
                    // width with px unit
                    size = width.replace('px', '');
                }
            }
            catch (e) {
                return size;
            }
        }
        return size;
    }
    /**
     * Compute style object
     *
     * @return style object
     */
    avatarStyle() {
        const style = {
            width: this.size + 'px',
            height: this.size + 'px',
            borderRadius: this.round
                ? this.defaultConfig.borderRadius
                : this.cornerRadius + 'px',
            borderStyle: this.defaultConfig.hasBorder || this.borderColor || this.borderWidth
                ? this.defaultConfig.borderStyle
                : 'none',
            borderColor: this.borderColor
                ? this.borderColor
                : this.defaultConfig.borderColor,
            borderWidth: this.borderWidth
                ? this.borderWidth + 'px'
                : this.defaultConfig.borderWidth + 'px',
            backgroundColor: this.backgroundColor
                ? this.backgroundColor
                : this.defaultConfig.backgroundColor,
        };
        return { ...style, ...this.style };
    }
    /**
     * Set style for the avatar
     *
     * @param styles style object
     */
    setStyle(styles) {
        Object.keys(styles).forEach((key) => {
            this.renderer.setStyle(this.elementRef.nativeElement, key, styles[key]);
        });
    }
}
NgxGravatarDirective.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.2.6", ngImport: i0, type: NgxGravatarDirective, deps: [{ token: i0.ElementRef }, { token: i0.Renderer2 }, { token: NgxGravatarService }], target: i0.ɵɵFactoryTarget.Directive });
NgxGravatarDirective.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "12.0.0", version: "13.2.6", type: NgxGravatarDirective, selector: "[ngx-gravatar], [ngxGravatar]", inputs: { src: "src", email: "email", md5Hash: "md5Hash", size: "size", fallback: "fallback", rating: "rating", round: "round", cornerRadius: "cornerRadius", borderColor: "borderColor", borderWidth: "borderWidth", style: "style", preferGravatar: "preferGravatar", backgroundColor: "backgroundColor", ratio: "ratio" }, usesOnChanges: true, ngImport: i0 });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.2.6", ngImport: i0, type: NgxGravatarDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[ngx-gravatar], [ngxGravatar]',
                }]
        }], ctorParameters: function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }, { type: NgxGravatarService }]; }, propDecorators: { src: [{
                type: Input
            }], email: [{
                type: Input
            }], md5Hash: [{
                type: Input
            }], size: [{
                type: Input
            }], fallback: [{
                type: Input
            }], rating: [{
                type: Input
            }], round: [{
                type: Input
            }], cornerRadius: [{
                type: Input
            }], borderColor: [{
                type: Input
            }], borderWidth: [{
                type: Input
            }], style: [{
                type: Input
            }], preferGravatar: [{
                type: Input
            }], backgroundColor: [{
                type: Input
            }], ratio: [{
                type: Input
            }] } });

class GravatarModule {
    static forRoot(gravatarConfig) {
        return {
            ngModule: GravatarModule,
            providers: [
                {
                    provide: GRAVATAR_CONFIG_TOKEN,
                    useValue: gravatarConfig,
                },
            ],
        };
    }
}
GravatarModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "13.2.6", ngImport: i0, type: GravatarModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
GravatarModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "13.2.6", ngImport: i0, type: GravatarModule, declarations: [NgxGravatarDirective], exports: [NgxGravatarDirective] });
GravatarModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "13.2.6", ngImport: i0, type: GravatarModule });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "13.2.6", ngImport: i0, type: GravatarModule, decorators: [{
            type: NgModule,
            args: [{
                    declarations: [NgxGravatarDirective],
                    exports: [NgxGravatarDirective],
                }]
        }] });

/*
 * Public API Surface of ngx-gravatar
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FALLBACK, GravatarModule, NgxGravatarDirective, NgxGravatarService, RATING };

