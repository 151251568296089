import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BaseExternalCellRendererComponent } from './base_external_cell_renderer.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ElementRef } from '@angular/core';
import { NgZone } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { EnumPropertyViewModel } from '../../../../src/lib/view-models/base-type/enum-property-view-model';
import { BoolPropertyViewModel } from '../../../../src/lib/view-models/base-type/bool-property-view-model';
import { ExternalFieldInputMode } from '../../../../src/lib/layout-meta-data/external-field-meta-data';
import { MessageResourceManager } from '../../../../src/lib/resources/message-resource-manager';
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { PopperHelper } from '@nts/std/utility';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { CheckboxComponent } from '../../../../src/lib/components/shared/checkbox/checkbox.component';

// create your cellEditor as a Angular component
@UntilDestroy()
@Component({
    selector: 'nts-external-renderer-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgIf,
        CheckboxComponent,
        NgFor,
        AsyncPipe
    ],
    template: `
        <span>
            <div *ngIf="!params?.data" class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </span>
        <div *ngIf="params?.data" class="read-template">
            <div class="renderer-cell"
                #popperError="popper"
                [popper]="tooltipErrTemplate"
                popperAppendTo="body"
                popperApplyClass="error"
                [popperPreventOverflow]="false"
                [popperHideOnScroll]="true"
                [popperDisabled]="(externalPropertyViewModel.errors$ | async).length == 0"
                [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperPlacement]="ngxPopperjsPlacements.TOP"
                [style.height.px]="cellHeight" [style.width.px]="boolPropertyViewModel ? 32 : null">
                <nts-checkbox *ngIf="boolPropertyViewModel" [(value)]="displayValue" [title]="boolPropertyViewModel.metadataDescription" [isDisabled]="true" ></nts-checkbox>
                <div *ngIf="!boolPropertyViewModel" class="cell-render-content">{{ displayValue }}</div>
            </div>
            <popper-content #tooltipErrTemplate>
                <div *ngFor="let item of externalPropertyViewModel.errors$ | async">
                    {{ item }}
                </div>
            </popper-content>
        </div>
        `
})
export class ExternalCellRendererComponent extends BaseExternalCellRendererComponent implements OnDestroy {

    @ViewChild('popperError', { static: false }) popperError: NgxPopperjsDirective;

    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    displayValue;
    boolPropertyViewModel: BoolPropertyViewModel = null;

    constructor(
        private readonly cd: ChangeDetectorRef,
        public readonly el: ElementRef,
        private readonly zone: NgZone,
        private readonly renderer: Renderer2) {
        super();
    }

    override agInit(params: any): void {
        super.agInit(params);
        this.propertyViewModel.propertyViewModelChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.checkDisplayValue();
            this.cd.detectChanges();
        });
        this.externalPropertyViewModel.externalDomainModelChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.checkDisplayValue();
            this.cd.detectChanges();
        });

        this.externalPropertyViewModel.decodeCompleted.pipe(untilDestroyed(this)).subscribe(() => {
            this.checkDisplayValue();
            this.cd.detectChanges();
        });
        this.externalPropertyViewModel.onErrorStatusChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.params.api.refreshCells({ force: true });
            this.checkDisplayValue();
            this.cd.detectChanges();
        });

        this.propertyViewModel.onFocusRequested.pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            this.el.nativeElement.parentElement.parentElement.scrollIntoView();

            if (this.propertyViewModel.errors$.value?.length > 0) {
                PopperHelper.show(this.popperError);
            }
        });

        this.checkDisplayValue();

        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        // this._tooltip.hide();
    }

    checkDisplayValue() {
        if (!this.params.data) {
            this.displayValue = '';
        } else {
            if (this.params.columnInfo.propertyTypeName === "ExternalDecode-Enum") {
                this.displayValue = this.params.columnInfo.fieldName.split('.').reduce(
                    (o, i, index) => {
                        if (i === 'value' && ((this.params.columnInfo.fieldName.split('.').length - 1) === index)) {
                            const enumPropertyViewModel = o as EnumPropertyViewModel;
                            if (enumPropertyViewModel) {
                                for (let i = 0; i < enumPropertyViewModel.valueDescriptions.length; i++) {
                                    if (enumPropertyViewModel.valueDescriptions[i].key === enumPropertyViewModel.currentItem) {
                                        return enumPropertyViewModel.valueDescriptions[i].description;
                                    }
                                }
                            }
                        } else {
                            return o[i]
                        }
                    }, 
                    this.params.data
                );
            } else if (this.params.columnInfo.propertyTypeName === "ExternalDecode-Bool") {
                this.boolPropertyViewModel = this.params.columnInfo.fieldName.split('.').reduce(
                    (o, i, index) => {
                        if (i === 'value' && ((this.params.columnInfo.fieldName.split('.').length - 1) === index)) {
                            return o;
                        } else {
                            return o[i];
                        }
                    }, 
                    this.params.data
                );
            } else if (this.params.columnInfo.propertyTypeName === "ExternalDecode-DateTime") {
                this.displayValue = this.params.columnInfo.fieldName.split('.').reduce(
                    (o, i, index) => {
                        if (i === 'value' && ((this.params.columnInfo.fieldName.split('.').length - 1) === index)) {
                            return o.formattedValue;
                        } else {
                            return o[i]
                        }
                    }, 
                    this.params.data
                );
            } else if (this.params.columnInfo.inputMode === ExternalFieldInputMode.ExternalList) {
                this.displayValue = this.getCodeAndDescriptionForExternalList();
            } else {
                this.displayValue = this.params.columnInfo.fieldName.split('.').reduce((o, i) => o[i], this.params.data);
            }
        }
    }

    private getCodeAndDescriptionForExternalList(): string {

        const codePvm = this.externalPropertyViewModel.codeProperties.values().next().value;
        const notDefined =  MessageResourceManager.Current.getMessage('std_NotDefined');

        if (this.externalPropertyViewModel.descriptionProperties.length > 0) {
            const descriptionPropertyName = this.externalPropertyViewModel.descriptionProperties[0]?.propertyName;
            const descriptionPvm = this.externalPropertyViewModel?.getProperty(descriptionPropertyName);
            
            const showCodeInDescription = this.params.columnInfo.showCodeInDescription ?? this.externalPropertyViewModel.showCode;

            if (showCodeInDescription) {
                if (descriptionPvm?.getValue()?.length > 0) {
                    return codePvm.getValue() ? (codePvm.getValue() + ' - ' + descriptionPvm?.getValue()) : notDefined;
                }                
            } else {
                if (descriptionPvm?.getValue()?.length > 0) {
                    return codePvm.getValue() ? descriptionPvm?.getValue() : notDefined;
                } 
            }            
        }

        return codePvm.getValue() ? codePvm?.getValue() : notDefined;
    }
}
