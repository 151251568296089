import { MessageResourceManager } from '../../../../src/lib/resources/message-resource-manager';
import { AccessMode } from '../../../../src/lib/meta-data/access-mode.enum';
import { Component, OnDestroy } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { CustomHeaderParamsInterface } from './custom_header_params.interface';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';

@Component({
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        NgxPopperjsModule,
        SvgIconComponent,
        NgIf
    ],
    selector:'nts-default-header',
    template: `
        <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
            <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button" aria-hidden="true"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                <span class="locked-field" *ngIf="params?.columnInfo?.securityAccess != null">
                    <svg-icon key="locked"
                        [popper]="securityTooltipDescription"
                        [popperDisabled]="!securityTooltipDescription || securityTooltipDescription?.length == 0"
                        [popperTrigger]="ngxPopperjsTriggers.hover"
                        [popperDelay]="1000"
                        [popperApplyClass]="securityTooltipClass"
                        [popperPlacement]="ngxPopperjsPlacements.TOP"
                        [popperPreventOverflow]="false"
                        [popperHideOnScroll]="true"
                        popperAppendTo="body">
                    </svg-icon>
                </span>
                <span ref="eText" class="ag-header-cell-text">{{params?.displayName}}</span>
                <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
                <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
                <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true" *ngIf="params.enableSorting && ascSort == 'active'" (click)="onSortRequested('asc', $event)">
                    <svg-icon key="arrow-up">
                    </svg-icon>
                </span>
                <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true" *ngIf="params.enableSorting && descSort == 'active'" (click)="onSortRequested('desc', $event)">
                    <svg-icon key="arrow-down">
                    </svg-icon>
                </span>
                <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
            </div>
        </div>
    `
})
export class DefaultHeaderComponent implements IHeaderAngularComp, OnDestroy {
    params: CustomHeaderParamsInterface;
    // sorted: string;
    isExternalCode = false;
    securityTooltipDescription: string;
    securityTooltipClass: string;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    public ascSort = 'inactive';
    public descSort = 'inactive';
    public noSort = 'inactive';

    agInit(params: any): void {
        this.params = params;

        this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));

        if (this?.params?.columnInfo?.securityAccess === AccessMode.ReadOnly) {
            this.securityTooltipDescription = MessageResourceManager.Current.getMessage('std_Security_Access_Mode_ReadOnly');
            this.securityTooltipClass = 'tooltip-content alert';
        } else {
            this.securityTooltipDescription = MessageResourceManager.Current.getMessage('std_Security_Access_Mode_Deny');
            this.securityTooltipClass = 'tooltip-content error';
        }

        this.onSortChanged();
    }

    ngOnDestroy(): void {
        this.params?.column?.removeEventListener('sortChanged', this.onSortChanged.bind(this));
        this.params = null;
        this.ngxPopperjsTriggers = null;
        this.ngxPopperjsPlacements = null;
    }

    onSortChanged() {
        this.ascSort = this.descSort = this.noSort = 'inactive';
        if (this.params.column.isSortAscending()) {
          this.ascSort = 'active';
        } else if (this.params.column.isSortDescending()) {
          this.descSort = 'active';
        } else {
          this.noSort = 'active';
        }
      }

    onSortRequested(order: 'asc' | 'desc' | null, event: any) {
        if (!this.params.enableSorting) {
            return;
        }

        this.params.setSort(order, event.shiftKey);
    }

    refresh(params: IHeaderParams): boolean {
        return true;
    }
}
