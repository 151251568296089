import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { NgSelectComponent, NgSelectModule } from "@ng-select/ng-select";
import { TextButtonComponent } from "../../../../shared/buttons/text-button/text-button.component";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";
import { FormsModule } from "@angular/forms";
import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { BehaviorSubject, Observable, Subject, catchError, concat, debounceTime, distinctUntilChanged, filter, firstValueFrom, fromEvent, map, of, switchMap, takeUntil, tap, throttleTime } from "rxjs";
import { NTSTranslatePipe } from "../../../../pipe/nts-translation-pipe";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { UICommandInterface } from "../../../../../view-models/commands/ui-command.interface";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PopperHelper } from "@nts/std/utility";
import { MessageResourceManager } from "../../../../../resources/message-resource-manager";

let nextId = 0;

export interface BaseExternalBoxComponentInterface {
    clearPoppers(): void;
    toggleDropdown(): void;
    closeDropdown(): void;
    openDropdown(): void;
}

export class ExternalBoxCommandUtility {

    constructor(
        private instance: BaseExternalBoxComponentInterface
    ) {}

    clearPoppers() {
        this.instance.clearPoppers();
    }

    closeDropdown() {
        this.instance.closeDropdown();
    }

    openDropdown() {
        this.instance.openDropdown();
    }

    toggleDropdown() {
        this.instance.toggleDropdown();
    }
}

@UntilDestroy()
@Component({
    selector: 'nts-base-external-box',
    templateUrl: './base-external-box.component.html',
    styleUrls: ['./base-external-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgSelectModule,
        TextButtonComponent,
        NgxPopperjsModule,
        FormsModule,
        NgFor,
        NTSTranslatePipe,
        AsyncPipe,
        NgOptionHighlightModule,
        NgIf,
        NgClass
    ]
})
export class BaseExternalBoxComponent implements OnInit, OnChanges, BaseExternalBoxComponentInterface {
    
    @Input() errorList: string[] = [];
    @Input() appendTo = 'app-root' //'.nts-document-content';
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() addItemCommand: UICommandInterface = null;
    @Input() copyCommand: UICommandInterface = null;
    @Input() viewItemCommand: UICommandInterface = null;
    @Input() searchCommand: UICommandInterface = null;
    @Input() isDropdownOpen = new BehaviorSubject<boolean>(false);
    @Input() scrollElementClass = 'layout-main scrollable-content'
    @Input() showCodeInDescription = false;
    @Input() showDebugInfo = false;
    @Input() showDecodeError = false;
    @Input() isSearchable = true
    /**
     * Lunghezza minima per iniziare la ricerca
     */
    @Input() minSearchLength: number = 3;

    @Input() typeToSearchText = `Inserisci ${this.minSearchLength} o più caratteri`

    /**
     * Se si passa una base fixed identity viene utilizzata come base per la identity da utilizzare per le chiamate
     */
    @Input() basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null = null;
    
    /**
     * Disabilita il componente
     */
    @Input() isDisabled = false;

    @Input() isLocked: boolean = false;
    @Input() isLockedMessage = 'Campo bloccato.'
    @Input() placeholder: string = MessageResourceManager.Current.getMessage('std_NotDefined');
    
    @Input() defaultBorderColor = null;
    @Input() activeBorderColor = null;
    @Input() hoverBorderColor = null;

    /**
     * Valore iniziale dell'autocomplete
     * Sevalue.identity è valorizzato ma value.description non viene passato o è nullo viene usata la callback getItemFromIdentityCallback per recuperare la descrizione
     */
    @Input() value: {identity: {[key:string]: string|number}, description?: string, isValid?: boolean };    
    
    /**
     * Visualizza un loader per il caricamento
     */
    @Input() isLoading: boolean = false;

    /**
     * Se si abilita questa funzione al blur verrà ricercato un elemento per il testo inserito
     * Se non viene trovato verrà resituito errore
     */
    @Input() isExactTermFeatureEnabled: boolean = true;

    /**
     * Callback facoltativa solo se si abilita la funzionalità isExactTermFeatureEnabled
     */
    @Input() getExactItemFromTermCallback?: (term: string, showCodeInDescription: boolean, basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null) => Observable<{ description: string, identity: {[key:string]: string|number}, all: any }>
    
    /**
     * Callback obbligatoria per recuperare l'item quando si ha solo l'identity senza description
     */
    @Input() getItemFromIdentityCallback: (identity: {[key:string]: string|number}, showCodeInDescription: boolean, basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null) => Observable<{ description: string, identity: {[key:string]: string|number}, all: any }>
    
    /**
     * Callback obbligatorio per recuperare la lista di item disponibili passando un termine di ricerca
     */
    @Input() getItemsFromTermCallback: (term: string, showCodeInDescription: boolean, basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null) => Observable<{ description: string, identity: {[key:string]: string|number}, all: any }[]>
    @Input() getItemsCallback: (showCodeInDescription: boolean, basePlainPascalCaseFixedIdentity: {[key:string]: string|number}|null) => Observable<{ description: string, identity: {[key:string]: string|number}, all: any }[]>

    @Input() enableOutsideClickListener = false;

    @Output() valueChange = new EventEmitter<{identity: {[key:string]: string|number}, description?: string, isValid?: boolean }>();
    @Output() onBlur = new EventEmitter();
    @Output() onFocus = new EventEmitter();
    @Output() termValueChange = new EventEmitter();
    @Output() onFinishEditing = new EventEmitter();

    @ViewChild(NgSelectComponent, { static: false }) inputRef: NgSelectComponent;
    @ViewChild('popperError', { static: true }) popperError: NgxPopperjsDirective;
    @ViewChild('popperInfo', { static: true }) popperInfo: NgxPopperjsDirective;

    items$: Observable<{ description: string, identity: {[key:string]: string|number}, all: any }[]>;
    itemInput$ = new Subject<string>();
    // selectedItem: { description?: string, identity: {[key:string]: string|number}, all?: any } = null;
    searchFinished: Subject<void> = new Subject();
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    lastInputText: string;
    computedErrors: string[] = [];
    internalErrorList: string[] = [];
    externalBoxCommandUtility: ExternalBoxCommandUtility;
    isActive = false;
    uniqueId =  `external-box-${nextId++}`;
    documentClickListener: any;
    overrideBorderColor = null;
    isHover = false;
    computedPlaceholder = this.placeholder;

     constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly el: ElementRef,
        private readonly zone: NgZone,
        private readonly renderer: Renderer2,
    ) {
    }

    showErroTooltip(): void {
        if (this.popperError) {
            PopperHelper.show(this.popperError);
        }
    }

    checkComputedErrors() {
        this.computedErrors = this.errorList.concat(this.internalErrorList);
        this.cd.detectChanges();
    }

    ngOnInit(): void {
        this.externalBoxCommandUtility = new ExternalBoxCommandUtility(this);
        this.loadItems();
        this.checkValue()
        this.typeToSearchText = `Inserisci ${this.minSearchLength} o più caratteri`;
        this.isDropdownOpen.pipe(untilDestroyed(this)).subscribe((isOpen) => {
            if (isOpen) {
                this.onDropdownOpen();
            } else {
                this.onDropdownClose();
            }           
        })
        if (this.enableOutsideClickListener) {
            this.bindDocumentClickListener();
        }
        this.handleOverridesColors();
    }

    valuesAreEqual(val1: Object, val2: Object): boolean {
        if (val1 == null && val2 != null) {
            return false;
        }
        if (val2 == null && val1 != null) {
            return false;
        }
        if (val2 == null && val1 == null) {
            return true;
        }
        return JSON.stringify(val1) === JSON.stringify(val2);
    }


    ngOnChanges(changes: SimpleChanges): void {
        // this.loadValue();

        if (changes['value']) {

            this.debugInfo('ngOnChanges[value]')

            this.checkValue();
        }

        if (changes['errorList']) {
            if (changes['errorList'].currentValue != changes['errorList'].previousValue) {
                if (changes['errorList'].currentValue?.length === 0 && changes['errorList'].previousValue?.length === 0) {

                } else {
                    this.checkComputedErrors();
                    this.checkPopper();
                }
                
            }            
        }

        if (changes['isLocked']) {
            this.checkLockedLogics();
        }

        if (changes['isDisabled']) {
            this.checkLockedLogics();
        }

        if (changes['minSearchLength']) {
            this.typeToSearchText = `Inserisci ${this.minSearchLength} o più caratteri`;
        }
    }

    checkLockedLogics() {
        if (this.isLocked && !this.isDisabled) {
            this.computedPlaceholder = this.isLockedMessage;
        } else {
            this.computedPlaceholder = this.placeholder;
        }
        this.cd.detectChanges();
    }

    async checkValue(): Promise<void> {
        this.debugInfo('checkValue')
        if (this.value?.identity == null && this.value?.isValid === true) {
            this.lastInputText = '';
            this.value = null;
            this.internalErrorList = [];
        } else if (this.value == null || this.value?.identity == null) {
            this.lastInputText = '';
        } else {
            if (this.value?.isValid === true) {
                this.internalErrorList = [];
            }
            await this.loadDescriptionValue();
        }
        this.updateSearchInput();
        this.checkComputedErrors();
        this.checkPopper();
        this.cd.detectChanges();
    }

    getDescriptionFromIdentity(identity: {[key:string]: string|number}): string {
        this.debugInfo('getDescriptionFromIdentity')
        if (identity != null) {
            const values = Object.values(identity)
            if (values?.length > 0) {
                return values[0].toString();
            }
        }
        return '';
    }

    debugInfo(section: string) {
        if (this.showDebugInfo) {
            console.log(this.uniqueId, section, {value: this.value, lastInputText: this.lastInputText});
        }
    }

    async loadDescriptionValue(): Promise<void> {
        this.debugInfo('loadDescriptionValue')

        // if (this.currentDescription == null) {
        //     this.getItemFromIdentityCallback(this.identity)
        // }
        // se non è valido è inutile che provo a caricare la descrizione
        if ((this.value?.isValid == null || this.value.isValid != false) && (this.value?.description == null || this.value?.description?.length === 0)) {
            this.isLoading = true;
            this.value.description = this.getDescriptionFromIdentity({...this.value?.identity}),
            // this.cd.detectChanges();
            this.value.description = await firstValueFrom(this.getItemFromIdentityCallback(this.value.identity, this.showCodeInDescription, this.basePlainPascalCaseFixedIdentity).pipe(map((item) => item?.description)))
            this.isLoading = false;
        }

        // this.selectedItem = {
        //     description: this.value?.description,
        //     identity: this.value?.identity,
        //     all: this.value
        // };
    }

    mouseEnter(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    mouseLeave(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    onDropdownOpen() {
        this.debugInfo('onDropdownOpen')
    
            fromEvent(document.getElementsByClassName(this.scrollElementClass), 'scroll').pipe(
                untilDestroyed(this),
                takeUntil(this.isDropdownOpen.pipe(filter((isDropdownOpen) => isDropdownOpen === false))),
                throttleTime(1000)
            ).subscribe((e: Event) =>  {
                this.focus(null);
                this.isDropdownOpen.next(false);
            });
    
            setTimeout(() => {
                this.handleOverridesColors()  
            })
    }

    handleOverridesColors() {
        if (!this.defaultBorderColor || !this.activeBorderColor || !this.hoverBorderColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.defaultBorderColor;
        if (this.isActive && !this.isDisabled) {
            this.overrideBorderColor = this.activeBorderColor;
        }
        if (this.isHover && !this.isDisabled) {
            this.overrideBorderColor = this.hoverBorderColor;
        }

        const container = this.inputRef.element.querySelector(".ng-select-container") as any;
        if (container){
            container.style.borderColor = this.overrideBorderColor 
        }

        const dropdown = (this.inputRef.dropdownPanel as any)?._dropdown;
        if (dropdown){
            dropdown.style.borderColor = this.overrideBorderColor;
        }

        const dropdownFooter = (this.inputRef.dropdownPanel as any)?._dropdown?.querySelector(".ng-dropdown-footer");
        if (dropdownFooter){
            dropdownFooter.style.borderColor = this.overrideBorderColor; 
        }
    }

    onDropdownClose() {
        this.debugInfo('onDropdownClose')
    }

    async blur(): Promise<void> {

        this.debugInfo('onBlur')

        this.isActive = false;

        this.handleOverridesColors();

        this.isDropdownOpen.next(false);

        if (this.lastInputText?.length > 0 && this.isExactTermFeatureEnabled && this.lastInputText != this.getDescriptionFromCurrentValue()) {
            this.isLoading = true;
            this.cd.detectChanges();
            const result = await firstValueFrom(this.getExactItemFromTermCallback(this.lastInputText, this.showCodeInDescription, this.basePlainPascalCaseFixedIdentity));
            this.isLoading = false;
            if (result) {
                await this.onSelectedItem(result);
            } else if (result == null) {
                if (this.showDecodeError) {
                    this.internalErrorList = ['Il codice inserito non è stato trovato'];
                }                
                await this.onSelectedItem({identity: null, description: this.lastInputText, isValid: false});

            }
            
        }        

        this.onBlur.emit();
    }

    focus(event) {

        this.debugInfo('onFocus')

        this.isActive = true;

        this.handleOverridesColors();
        if (this.isDisabled) {
            return;
        }

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        
        this.updateSearchInput();
        this.isDropdownOpen.next(false);
        this.onFocus.emit();

    }

    updateSearchInput() {

        this.debugInfo('updateSearchInput')

        if (this.isSearchable) {
            if (this.value?.identity != null) {

                this.inputRef.searchInput.nativeElement.value = this.getDescriptionFromCurrentValue();
                // this.inputRef.searchInput.nativeElement.value = this.initialValue ? this.initialValue : this.getCodeAndDescription();
                // this.initialValue = null;
                this.inputRef.searchInput.nativeElement.dispatchEvent(new Event('input'));
                const pos = this.inputRef.searchInput.nativeElement.value ? this.inputRef.searchInput.nativeElement.value.length : 0;
                //     // this.inputRef.searchInput.nativeElement.setSelectionRange(pos, pos);
                this.inputRef.searchInput.nativeElement.selectionStart = 0;
                this.inputRef.searchInput.nativeElement.selectionEnd = pos;
                //     // if (!this.mouseDownOnSearchInput) {
                if (this.isActive) {
                    this.inputRef.searchInput.nativeElement.select();
                }
    
                // }
    
            } else if (this.value?.description != null && this.value?.description?.length) {
                this.inputRef.searchInput.nativeElement.value = this.value?.description;
                this.inputRef.searchInput.nativeElement.dispatchEvent(new Event('input'));
                const pos = this.inputRef.searchInput.nativeElement.value ? this.inputRef.searchInput.nativeElement.value.length : 0;
                //     // this.inputRef.searchInput.nativeElement.setSelectionRange(pos, pos);
                this.inputRef.searchInput.nativeElement.selectionStart = 0;
                this.inputRef.searchInput.nativeElement.selectionEnd = pos;
                //     // if (!this.mouseDownOnSearchInput) {
                if (this.isActive) {
                    this.inputRef.searchInput.nativeElement.select();
                }
            } else if (this.value == null && this.inputRef?.searchInput?.nativeElement?.value?.length > 0) {
                if (this.inputRef) {
                    (this.inputRef as any)._clearSearch();
    
                }
            } else if (this.value?.identity == null && this.value?.isValid == true) {
                if (this.inputRef) {
                    // (this.inputRef as any)._clearSearch();
                    (this.inputRef as any)._changeSearch(null);
                    (this.inputRef as any).itemsList.resetFilteredItems();
    
                }
            }
        }


       
        this.cd.detectChanges();
    }

    getDescriptionFromCurrentValue(): string {
        // let description = '';
        // if (this.selectedItem?.description?.length > 0) {
        //     description = this.selectedItem?.description;
        // } else {}

        // if (this.selectedItem?.identity != null && this.singleKeyCode) {
        //     return  description?.length > 0  ? `${this.singleKeyCode} - ${description}` : this.singleKeyCode.toString();
        // } else if (this.selectedItem?.identity != null && !this.singleKeyCode) {
        //     const values = Object.values(this.value.identity);
        //     return  description?.length > 0  ? `${values[0]} - ${description}` : values[0];
        // } else {
        //     return '';
        // }

        if (this.value?.description?.length > 0) {
            return this.value?.description;
        } else {
            if (this.value?.identity != null && this.singleKeyCode) {
                return this.singleKeyCode.toString();
            } else if (this.value?.identity != null && !this.singleKeyCode) {
                const values = Object.values(this.value.identity);
                return values[0].toString();
            } else {
                return '';
            }
        }
    }

    onClear() {
        this.debugInfo('onClear');
        this.lastInputText = '';
        this.checkComputedErrors();
        this.checkPopper();
    }

    async onSelectedItem(e: {identity: {[key:string]: string|number}, description: string, all?: Object, isValid?: boolean}): Promise<void> {
        this.debugInfo('onSelectedItem');
        this.isDropdownOpen.next(false);
        this.value = e;
        this.lastInputText = '';
        if (this.value != null) {
            await this.loadDescriptionValue();
        }        
        this.valueChange.emit(this.value)
        
        this.updateSearchInput();
        
        // Resetto gli errori se ho resettato l'autocomplete
        if (e == null) {
            this.internalErrorList = [];
        }

        // Resetto gli errori se ho resettato l'autocomplete
        if (e?.isValid == true || e?.isValid == null ) {
            this.internalErrorList = [];
        }
        this.checkComputedErrors();
        this.checkPopper();
    }

    async keyDownFn(event: KeyboardEvent): Promise<boolean> {
        this.debugInfo('keyDownFn');
        if (event.key === 'Tab') {

            // Se ho un elemento marcato nel dropdown
            if (this.inputRef?.dropdownPanel?.markedItem?.value) {
                const markedItem = this.inputRef.dropdownPanel.markedItem.value as { description: string, identity: {[key:string]: string|number}, all: any }

                // Se l'identity è diversa da quella selezionata
                if (JSON.stringify(this.value?.identity) !== JSON.stringify(markedItem.identity)) {
                    await this.onSelectedItem(markedItem);
                }

            }

            await this.blur();

            // if (this)
            // setTimeout(() => this.onFocus(null))
        } else if (event.key === 'Enter') {

            // Se ho un elemento marcato nel dropdown
            if (this.inputRef?.dropdownPanel?.markedItem?.value) {
                const markedItem = this.inputRef.dropdownPanel.markedItem.value as { description: string, identity: {[key:string]: string|number}, all: any }

                // Se l'identity è diversa da quella selezionata
                if (JSON.stringify(this.value?.identity) !== JSON.stringify(markedItem.identity)) {
                    await this.onSelectedItem(markedItem);
                }

            }

            await this.blur();

            // if (this)
            // setTimeout(() => this.onFocus(null))
        } else if (event.key === 'Del') {
            // TODO se si trova alla poszione zero con il cursore ed esiste un modello cancellare il modello
            // if (this.inputRef.)
        } else if (event.key === 'F6' && event.ctrlKey) {
            this.zoom();
        }
        return true;
    }

    trackByFn(item: { description: string, identity: any, all: any }) {
        if (item?.identity != null) {
            return JSON.stringify(item?.identity);
        }
        return null;
    }

    isSelectedItemDeactivated() {

    }

    zoom() {

    }

    isItemDeactivated(item) {

    }

    get singleKeyCode(): string|number|null {
        if (this.value?.identity) {
            const keys = Object.keys(this.value.identity);
            if (keys?.length === 1) {
                return this.value.identity[keys[0]];
            }
        }        
        return null;
    }

    loadItems() {

        // Quando termina la ricerca
        this.searchFinished.pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            this.isLoading = false;
            // this.cd.detectChanges()

            // se esiste un marked item
            // if (this.inputRef?.dropdownPanel?.markedItem) {
            //     // // resetto lastInputText
            //     // this.lastInputText = null;
            //     if (this.inputRef?.dropdownPanel?.markedItem?.value) {
            //         const value = (this.inputRef?.dropdownPanel?.markedItem?.value as { description: string, identity: {[key:string]: string|number}, all: any });
            //         if (value) {
            //             this.valueChange.emit(value);
            //         }
            //     }
            // }
        });

        this.itemInput$.pipe(
            untilDestroyed(this),
            distinctUntilChanged()
        ).subscribe((term) => {
            if (term != null) {
                this.lastInputText = term;
                // Gestisce la casistica di cancellazione senza pulire l'elemento selezionato
                if (term !== '') {
                    this.termValueChange.emit(term);
                    // this.cd.detectChanges()
                }
            }
        });

        if (this.isSearchable) {
            this.items$ = concat(
                of([]), // default items
                this.itemInput$.pipe(
                    untilDestroyed(this),
                    distinctUntilChanged(),
                    debounceTime(300),
                    tap((term) => {
                        if (term?.length > 0) {
                            this.isLoading = true
                        }                    
                    }),
                    switchMap(
                        (term: string) => this.termMapper(term)
                            // .pipe(tap(() => this.cd.detectChanges()))
                            // if (
                            //     term?.length > 0 &&
                            //     this.code?.getValue() &&
                            //     term === this.code?.getValue().toString() &&
                            //     this.decodeDescription.getValue() != null &&
                            //     !this.code.hasErrors
                            // ) {
                            //     this.itemLoading = false;
                            //     this.cd.detectChanges();
                            //     return of([
                            //         {
                            //             identity: this.code.getValue(),
                            //             description: this.getCodeAndDescription(),
                            //             all: [{
                            //                 IsActive: !this.isSelectedItemDeactivated()
                            //             }]
                            //         }
                            //     ]);
                            // } else if (term?.length >= this.autocompleteMinSearchLength) {
    
                            //     return this.externalPropertyViewModel.execAutocomplete(
                            //         term, this.language ? this.language.twoLetterISOLanguageName : null, this.showCodeInDescription
                            //     ).pipe(
                            //         map((items) => items.sort((x, y) => {
    
                            //             const lowerTerm = term.toLowerCase();
    
                            //             if (x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
                            //                 return x.description.localeCompare(y.description);
                            //             } else if (x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
                            //                 return -1;
                            //             } else if (!x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
                            //                 return 1;
                            //             } else {
                            //                 // (!x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
                            //                 if (x.description.toLowerCase().indexOf(lowerTerm) > -1 && y.description.toLowerCase().indexOf(lowerTerm) == 0) {
                            //                     return -1;
                            //                 } else if (x.description.toLowerCase().indexOf(lowerTerm) == 0 && y.description.toLowerCase().indexOf(lowerTerm) > -1) {
                            //                     return 1;
                            //                 } else {
                            //                     return x.description.localeCompare(y.description);
                            //                 }
                            //             }
                            //         })),
                            //         catchError(() => of([])), // empty list on error
                            //         tap(
                            //             () => {
                            //                 this.itemLoading = false;
                            //                 this.cd.detectChanges();
                            //                 this.searchFinished.next();
                            //             }
                            //         )
                            //     );
                            // } else {
                            //     this.itemLoading = false;
                            //     this.searchFinished.next();
                            //     this.cd.detectChanges();
                            //     return of([]);
                            // }
                    )
                )
            );
        } else {


            this.items$ = this.getItemsCallback(this.showCodeInDescription, this.basePlainPascalCaseFixedIdentity).pipe(
                map((items) => items.sort((x, y) => {

                    return x.description.localeCompare(y.description);

                    // if (x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
                    //     return x.description.localeCompare(y.description);
                    // } else if (x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
                    //     return -1;
                    // } else if (!x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
                    //     return 1;
                    // } else {
                    //     // (!x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
                    //     if (x.description.toLowerCase().indexOf(lowerTerm) > -1 && y.description.toLowerCase().indexOf(lowerTerm) == 0) {
                    //         return -1;
                    //     } else if (x.description.toLowerCase().indexOf(lowerTerm) == 0 && y.description.toLowerCase().indexOf(lowerTerm) > -1) {
                    //         return 1;
                    //     } else {
                    //         return x.description.localeCompare(y.description);
                    //     }
                    // }
                })),
                catchError(() => of([])), // empty list on error
                tap(
                    () => {
                        this.isLoading = false;
                        // this.cd.detectChanges();
                        this.searchFinished.next();
                    }
                )
            );

            // this.items$ = this.externalPropertyViewModel.execExternalList(this.showCodeInDescription)
            //     .pipe(
            //             map((items) => items.sort((x, y) => {

            //                 // const lowerTerm = term.toLowerCase();

            //                 // if (x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
            //                 //     return x.description.localeCompare(y.description);
            //                 // } else if (x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
            //                 //     return -1;
            //                 // } else if (!x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
            //                 //     return 1;
            //                 // } else {
            //                 //     // (!x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
            //                 //     if (x.description.toLowerCase().indexOf(lowerTerm) > -1 && y.description.toLowerCase().indexOf(lowerTerm) == 0) {
            //                 //         return -1;
            //                 //     } else if (x.description.toLowerCase().indexOf(lowerTerm) == 0 && y.description.toLowerCase().indexOf(lowerTerm) > -1) {
            //                 //         return 1;
            //                 //     } else {
            //                 //         return x.description.localeCompare(y.description);
            //                 //     }
            //                 // }
            //                 return x.description.localeCompare(y.description);
            //             })),
            //             catchError(() => of([])), // empty list on error
            //             tap(
            //                 () => {
            //                     this.isLoading = false;
            //                     this.cd.detectChanges();
            //                     this.searchFinished.next();
            //                 }
            //             )
            // )
        }        
    }
    
    clearPoppers(): void {
        PopperHelper.hide(this.popperInfo);
        PopperHelper.hide(this.popperError);
    }

    closeDropdown() {
        this.isDropdownOpen.next(false)
    }

    toggleDropdown() {
        this.isDropdownOpen.next(!this.isDropdownOpen.value)
    }

    openDropdown() {
        this.isDropdownOpen.next(true)
    }

    private bindDocumentClickListener(): void {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();
                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    private isOutsideClicked(event: Event): boolean {
        return !(this.el.nativeElement.isSameNode(event.target) || this.isDropDownClicked(event) || this.isSearchInputClicked(event) || //this.dropdownOpening ||
            this.isDropdownItem(event) || this.el.nativeElement.contains(event.target) || this.isActionsClicked(event)) ;
    }

    private isActionsClicked(event: Event): boolean {
        return this.el.nativeElement.querySelector('.autocomplete-actions')?.contains(event.target)
    }

    private isDropDownClicked(event: Event): boolean {
        if (this.inputRef.dropdownPanel?.scrollElementRef?.nativeElement) {
            return (this.inputRef?.dropdownPanel as any)?._dropdown.contains(event.target);
        } else {
            return false;
        }
    }

    private isSearchInputClicked(event: Event): boolean {
        if (this.inputRef.searchInput?.nativeElement) {
            return (this.inputRef.searchInput?.nativeElement as any)?.contains(event.target);
        } else {
            return false;
        }
    }

    private isDropdownItem(event): boolean {
        return (event?.target?.className?.includes && event?.target?.className?.includes(this.inputRef?.dropdownId)) ||
        (event?.target?.parentNode?.className?.includes && event?.target?.parentNode?.className?.includes(this.inputRef?.dropdownId));
    }

    private termMapper(term: string): Observable<{
        description: string;
        identity: {[key:string]: string|number};
        all: any;
    }[]> {
        if (
            term?.length > 0 &&
            this.singleKeyCode &&
            (term === this.singleKeyCode.toString() || term === this.getDescriptionFromCurrentValue()) &&
            // this.decodeDescription.getValue() != null &&
            this.errorList?.length === 0
        ) {
            this.isLoading = false;
            // this.cd.detectChanges();
            return of([
                {
                    identity: this.value?.identity,
                    description: this.value?.description,
                    all: this.value
                }
            ]);

        } else if (term?.length >= this.minSearchLength && term === this.getDescriptionFromCurrentValue()) {
            this.isLoading = false;
            // this.cd.detectChanges();
            return of([
                {
                    identity: this.value?.identity,
                    description: this.value?.description,
                    all: this.value
                }
            ]);
        } else if (term?.length >= this.minSearchLength) {

            this.isDropdownOpen.next(true);

            return this.getItemsFromTermCallback(term, this.showCodeInDescription, this.basePlainPascalCaseFixedIdentity).pipe(
                map((items) => items.sort((x, y) => {
                    const lowerTerm = term.toLowerCase();

                    if (x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
                        return x.description.localeCompare(y.description);
                    } else if (x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
                        return -1;
                    } else if (!x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
                        return 1;
                    } else {
                        // (!x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
                        if (x.description.toLowerCase().indexOf(lowerTerm) > -1 && y.description.toLowerCase().indexOf(lowerTerm) == 0) {
                            return -1;
                        } else if (x.description.toLowerCase().indexOf(lowerTerm) == 0 && y.description.toLowerCase().indexOf(lowerTerm) > -1) {
                            return 1;
                        } else {
                            return x.description.localeCompare(y.description);
                        }
                    }
                })),
                catchError(() => of([])), // empty list on error
                tap(
                    () => {
                        this.isLoading = false;
                        // this.cd.detectChanges();
                        this.searchFinished.next();
                    }
                )
            );
        } else {
            this.isLoading = false;
            this.searchFinished.next();
            // this.cd.detectChanges();
            return of([]);
        }
    }   

    private checkPopper() {
        if (this.popperError && (this.computedErrors == null || this.computedErrors.length === 0)) {
            PopperHelper.hide(this.popperError);
        }   
        if (this.popperInfo && this.computedErrors?.length > 0) {
            PopperHelper.hide(this.popperInfo);
        }
        if (this.popperInfo && (this.value?.description == null || this.value?.description?.length === 0)) {
            PopperHelper.hide(this.popperInfo);
        }
    }
}